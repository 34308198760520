<template>
  <div>
    <v-menu
      :close-on-content-click="false"
      :nudge-bottom="60"
      max-width="50%"
      transition="slide-y-transition"
      style="z-index: 999"
      content-class="custom-menu__elevation"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          fab
          text
          small
          dark
          :disabled="!(companies.length > 0)"
          v-bind="attrs"
          v-on="on"
        >
          <v-icon size="20px" color="white" text>
            mdi-domain
          </v-icon>
        </v-btn>
      </template>

      <v-card v-if="defaultCompany">
        <v-list class="my-0 py-0">
          <v-list-item two-line class='custom-list-item secondary my-0 py-0'>
            <span v-if="defaultCompany">
              <v-img v-if='defaultCompany.logo'
                     height="50" max-height="50" width="50" max-width="50" contain
                     :src="`${defaultCompany.logo}`"
              />
              <v-list-item-avatar v-else width='50' height='50' color='white'>
                <span class="primary--text text-h5"><strong>{{ defaultCompany.name.charAt(0).toUpperCase() }}</strong></span>
              </v-list-item-avatar>
            </span>

            <v-list-item-content v-if="profile" class="mx-3">
              <v-list-item-title class='white--text'>{{ profile.name }}</v-list-item-title>
              <v-list-item-subtitle class='white--text'>{{ profile.email }}</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>

          <v-divider></v-divider>

          <v-list class="px-2" v-if="filteredCompanies.length > 0">
            <v-list-item
              v-for="company in companies"
              :key="company.id"
              @click="selectCompany(company)"
              @mouseenter="showButton = company.id"
              @mouseleave="showButton = null"
            >
              <v-img
                contain
                v-if='company.logo'
                width="50" max-width="50"
                :src="`${company.logo}`"
              />
              <v-list-item-avatar v-else width='40px' height='40px' :color="company.logo ? 'white' : 'secondary'">
                <span  class="primary--text text-h6 white--text"><strong>{{ company.name.charAt(0).toUpperCase() }}</strong>
                </span>
              </v-list-item-avatar>
              <v-list-item-content class="mx-3">
                <v-list-item-title>{{ company.name }}</v-list-item-title>
              </v-list-item-content>
              <v-list-item-action>
                <v-btn
                  v-if="company.isDefault"
                  icon
                  disabled
                >
                  <v-icon color="primary">mdi-star</v-icon>
                </v-btn>
                <v-tooltip bottom v-else content-class="custom__tooltip">
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      v-bind="attrs"
                      v-on="on"
                      icon
                      :loading="loadings.setDefault"
                      @click.stop="setDefault(company)"
                      class="set-default-btn"
                      :class="{ 'visible': showButton === company.id }"
                    >
                      <v-icon outlined>mdi-star-outline</v-icon>

                    </v-btn>
                  </template>
                  <span>
                    {{ $t('dashboard.buttons.setDefault') }}
                  </span>
                </v-tooltip>
              </v-list-item-action>
            </v-list-item>
          </v-list>
        </v-list>
      </v-card>
    </v-menu>
  </div>
</template>

<script>
  import { mapActions, mapGetters } from "vuex"
  import { removeFiltersFromLocalStorage } from "@/utils"

  export default {
    name: 'SelectCompany',
    data() {
      return {
        profile: null,
        menuVisible: false,
        loadingCompanies: false,
        loadings: {
          setDefault: false,
        },
        showButton: null,
      };
    },
    computed: {
      ...mapGetters({
        companies: 'company/userCompanies',
      }),
      filteredCompanies() {
        return _.filter(this.companies, (c) => {
          return c.id !== this.defaultCompany.id
        })
      },
      defaultCompany: {
        get() {
          return this.$store.getters['company/defaultCompany']
        },
        set(val) {
          this.$store.commit('company/SET_DEFAULT_COMPANY', val)
        }
      }
    },
    async mounted() {
      await this.loadCompanies().finally(() => {
        const current = JSON.parse(localStorage.getItem('company:current'));
        localStorage.setItem('company:current', JSON.stringify(this.companies.find(c => c.identity === current.identity)))
        this.defaultCompany = this.companies.find(c => c.identity === current.identity)
      });

      this.profile = JSON.parse(localStorage.getItem('profile'));
    },
    methods: {
      ...mapActions({
        loadUserCompanies: 'company/fetchAvailableCompanies',
        clearAllFilters: 'dashboard/clearAllFilters'
      }),
      async setDefault(company) {
        this.loadings.setDefault = true;
        await this.$store.dispatch('company/setDefault', company.identity);
        await this.loadCompanies().finally(() => {
          this.loadings.setDefault = false;
          this.$store.commit('company/SET_USER_COMPANIES', _.map(this.companies, (c) => ({
            ...c,
            isDefault: company.id === c.id
          })));
        });
      },
      closeMenu() {
        this.menuVisible = false;
      },
      async reloadUserData() {
        // Update lateral filters
        await removeFiltersFromLocalStorage();
        await this.$store.dispatch('login/setFilters');

        // Update dashboard filters
        // await this.clearAllFilters()

        await this.$store.dispatch('login/loadInitData').finally(() => {
          window.location.reload();
        });
      },
      async loadCompanies() {
        if (this.companies.length === 0) {
          this.loadingCompanies = true;
          await this.loadUserCompanies().finally(() => {
            this.loadingCompanies = false;
          });
        }
      },
      selectCompany(company) {
        if (this.defaultCompany.id === company.id) return;

        this.defaultCompany = company;
        this.menuVisible = false;
        this.reloadUserData();
      }
    }
  };
</script>

<style scoped>
  .custom-menu {
    margin: 0 !important;
    left: 8px !important;
  }

  .custom-list-item {
    margin-bottom: 10px;
  }

  .custom-list-title {
    color: black;
  }

  .set-default-btn {
    opacity: 0;
    transition: opacity 0.3s;
  }

  .set-default-btn.visible {
    opacity: 1;
  }

  .custom__tooltip {
    background-color: #000 !important;
    color: #fff !important;
    font-size: 0.875rem;
    border-radius: 4px;
    padding: 8px 10px;
  }
</style>
