import i18n from '@/plugins/i18n';
import * as types from './mutations-type.js';
import StorageTypesService from "../../../../services/StorageTypesService";

export default {
    loadingON({commit,state}) {
        state.allStoragesTypes = [];
        commit(types.SET_LOADING_ON, true);
    },
    loadingOFF({commit}) {
        commit(types.SET_LOADING_OFF, false);
    },
    async saveStorageTypes({dispatch, commit, state}, [item, editOrSave, toast]) {
        dispatch('loadingON');
        try {
            await StorageTypesService.saveAdmin(item, editOrSave);
            toast.success(i18n.t('storageType.notification.save'), {
                queueable: true,
                color: '#37A98C'
            });
        } catch (error) {
            if (error.message.includes('406')) {
                toast.error(i18n.t('storageType.notification.inUse'), {
                    queueable: true,
                    color: 'gray'
                });
            } else {
                if (error.message !== 'cancelRequest')
                    toast.error(i18n.t('storageType.notification.saveError'), {
                        queueable: true
                    });
            }
        }
    },
    async fetchAllStorageTypes({dispatch, commit}, [toast]) {
        dispatch('loadingON');
        try {
            const {data} = await StorageTypesService.allStorageTypes();
            commit(types.SET_ALL_STORAGE_TYPE_LIST, data);
            dispatch('loadingOFF');
        } catch (error) {
            if (error.message !== 'cancelRequest')
                toast.error(i18n.t('storageType.notification.listError'), {
                    queueable: true
                });
        }
    },
}
