import i18n from '@/plugins/i18n';
import * as types from './mutations-type.js';
import StructuresService from "../../../services/StructuresService";
import InspectionsService from '@/services/InspectionsService'
import LocationsService from '@/services/Locations'
import StorageService from "@/services/StorageService";

export default {
    SET_FILTER_TYPE({commit}, payload) {
        commit('FILTER_TYPE', payload)
    },
    SET_FILTER_SOURCE({commit}, payload) {
        commit('FILTER_SOURCE', payload)
    },
    SET_DRAWER({commit}, payload) {
        commit('DRAWER', payload)
    },
    SET_SIDENAV({commit}, payload) {
        commit('SIDENAV', payload)
    },
    SET_LOADING({commit}, payload) {
        commit('LOADING', payload)
    },
    SET_FILTERS({commit}, payload) {
        commit('FILTERS', payload)
    },
    SET_DATA({commit}, payload) {
        commit('DATA', payload)
    },
    SET_STRUCTURE_FILTERS({commit}, payload) {
        commit('STRUCTURE_FILTERS', payload)
    },
    SET_OTHERS_FILTERS({commit}, payload) {
        commit('OTHERS_FILTERS', payload)
    },
    SET_OPTIONS_TABLE({commit}, payload) {
        commit('OPTIONS_TABLE', payload)
    },
    SET_FILTERS_CHIPS({commit}, payload) {
        commit('FILTERS_CHIPS', payload)
    },
    SET_COUNT_FILTERS({commit}, payload) {
        commit('COUNT_FILTERS', payload)
    },
    SET_LOCATIONS_FILTERS({commit}, payload) {
        commit('LOCATIONS_FILTERS', payload)
    },
    SET_STAGE_ID({commit}, payload) {
        commit('STAGE_ID', payload)
    },
    SET_OBJECTS_TO_FILTERS({commit}, payload) {
        commit('OBJECTS_TO_FILTERS', payload)
    },
    SET_PRODUCER_TO_FILTERS({commit}, payload) {
        commit('PRODUCER_TO_FILTERS', payload)
    },
    SET_DATE_FILTERS({commit}, payload) {
        commit('DATE_FILTERS', payload)
    },
    SET_CHART_DATA({commit}, payload) {
        commit('CHART_DATA', payload)
    },
    async SET_STRUCTURES_BY_IDS({dispatch, commit, state}, [profile, ids, toast]) {
        dispatch('SET_LOADING', true);
        try {
            const {data} = await StructuresService.structuresByIds(profile.company_id, ids);
            commit(types.STRUCTURES_BY_IDS, data);
            dispatch('SET_LOADING', false);
        } catch (error) {
            if (error.message !== 'cancelRequest')
                toast.error(i18n.t('structure.notification.listError'), {
                    queueable: true
                });

            dispatch('SET_LOADING', false);
        }
    },
    async SET_QUESTIONS_BY_TYPE({dispatch, commit, state}, [itypes, toast]) {
        dispatch('SET_LOADING', true);
        try {
            let {data} = await InspectionsService.getQuestionsByType(itypes);

            commit(types.QUESTIONS, data);
            dispatch('SET_LOADING', false);
        } catch (error) {
            if (error.message !== 'cancelRequest')
                toast.error(i18n.t('inspections.notification.errorQuestions'), {
                    queueable: true
                })

            dispatch('SET_LOADING', false);
        }
    },
    async SET_ANSWERS_BY_QUESTIONS({dispatch, commit, state}, [lang, questions, toast]) {
        dispatch('SET_LOADING', true);
        try {
            let {data} = await InspectionsService.getAnswersByQuestions(lang, questions);

            data = _.map(data, (q) => {
                return q.answers;
            })

            commit(types.ANSWERS, data);
            dispatch('SET_LOADING', false);
        } catch (error) {
            if (error.message !== 'cancelRequest')
                toast.error(i18n.t('inspections.notification.errorAnswers'), {
                    queueable: true
                })

            dispatch('SET_LOADING', false);
        }
    },

    async fetchPrimaryRange({dispatch, commit, state}) {
        try {
            const { data } = await LocationsService.getAreaRange();

            let filtersLocations = JSON.parse(localStorage.getItem('filtersLocations'))
            filtersLocations.filtersObjects.locations.area = [data.minArea, data.maxArea];
            localStorage.setItem('filtersLocations', JSON.stringify(filtersLocations));

            commit(types.LOCATION_RANGES, [data.minArea, data.maxArea]);
        } catch (error) {
        }
    },

    async fetchSecondaryRange({dispatch, commit, state}) {
        try {
            const { data } = await StorageService.getAreaRange();

            let filtersStorages = JSON.parse(localStorage.getItem('filtersStorages'))
            filtersStorages.filtersObjects.storages.area = [data.minArea, data.maxArea];
            localStorage.setItem('filtersStorages', JSON.stringify(filtersStorages));

            commit(types.STORAGE_RANGES, [data.minArea, data.maxArea]);
        } catch (error) {
        }
    },
}
