export default {
    loading: false,
    recordsByCompany: [],
    preRecord: {
        company: null,
        process: null,
        person_in_charge: null,
        originApp: null,
        sdi_origin: null,
        sdi_destination: null,
        record_date_time: new Date().toJSON(),
        recordStructureValuesOrganized: {},
        evidences: {},
    },
    recordsByCompanyTotalCount: 0,
    storageDeviceTypesActiveByCompany: [],
    recordsToExports: [],
    recordsToTraceabilityReport: [],
    prerecordsByCompanyFiltered: [],
    tabIndex: 0,
    structures: [],
    request: false,
    source: null,
}
