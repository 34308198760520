<template>
  <fragment>
    <template>
      <div class='text-left'>
        <v-menu
          v-model='menu'
          :close-on-content-click='false'
          :nudge-width='150'
          max-height="350px"
          offset-x
        >
          <template v-slot:activator='{ on, attrs }'>
            <v-btn class='mt-2 ml-3'
                   dark
                   v-bind='attrs'
                   v-on='on'
                   fab
                   x-small
                   elevation='0'
                   tile
                   color='secondary'
            >
              <v-icon class='pl-3' color='white'>mdi-layers-triple
              </v-icon>
              <v-badge
                class='pb-3 ml-3'
                color="error"
                :content="zoom"
              >
              </v-badge>
            </v-btn>
          </template>

          <v-card>
            <v-list class='pb-0 pt-0'>
              <v-list-item>
                <v-list-item-content class='pb-0 pt-0'>
                  <v-list-item-title>
                    {{ $t('general.buttons.layers').toUpperCase() }}
                  </v-list-item-title>
                </v-list-item-content>
                <v-list-item-avatar class='ml-5'>
                  <v-icon @click='filterAllMap(true)' color='secondary' class='ml-3'>
                    mdi-checkbox-multiple-marked-outline
                  </v-icon>
                </v-list-item-avatar>
                <v-list-item-avatar class='ml-0'>
                  <v-icon @click='filterAllMap(false)' color='error' class='mb-1'>
                    mdi-filter-variant-remove
                  </v-icon>
                </v-list-item-avatar>
              </v-list-item>
            </v-list>
            <v-divider></v-divider>
            <v-list
              dense
            >
              <div v-for='(layer, index) in layers' :key='index'>
                <v-list-item>
                  <v-list-item-action @click='layerMap(layer)' class='ml-2'>
                    <v-checkbox v-model='layer.checked'></v-checkbox>
                  </v-list-item-action>
                  <v-list-item-content>
                    <v-list-item-title>{{ $t('map.layers.'+layer.id).toUpperCase() }}
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </div>
            </v-list>
          </v-card>
        </v-menu>
      </div>
    </template>
  </fragment>
</template>
<script>
  import _ from 'lodash'

  export default {
    name: 'MapLayers',
    inject: ['mapbox', 'map', 'actions'],
    props: [
      'layers',
      'mapboxMap',
      'zoom',
    ],
    data() {
      return {
        menu: false
      }
    },

    methods: {
      filterAllMap(value) {
        _.forEach(this.layers, (layer) => {
          layer.checked = value
          this.layerMap(layer)
        })
        // this.$emit('layerMap', this.layers)
      },
      layerMap(layer) {
        if (this.mapboxMap.getLayer(layer.id)) {
          if (layer.checked) {
            this.mapboxMap.setLayoutProperty(layer.id, 'visibility', 'visible')
          } else {
            this.mapboxMap.setLayoutProperty(layer.id, 'visibility', 'none')
          }
        }
      },
    },
  }
</script>
<style scoped>
  .badge {
    background-color: #9d1717;
    color: white;
    padding: 4px 8px;
    border-radius: 4px;
    margin-left: 5px;
  }
</style>