import i18n from '@/plugins/i18n';
import _ from 'lodash';
import * as types from './mutations-type.js';
import CompanyService from "../../../../services/CompanyService";

export default {
  loadingON({commit}) {
    commit(types.SET_LOADING_ON, true);
  },
  loadingOFF({commit}) {
    commit(types.SET_LOADING_OFF, false);
  },
  async fetchCompany({dispatch, commit, state}, [profile, toast]) {
    dispatch('loadingON');
    try {
      const company = profile.company_id;
      const {data} = await CompanyService.companyById(company);
      commit(types.SET_COMPANY, [data]);
      dispatch('loadingOFF');
    } catch (error) {
      if (error.message !== 'cancelRequest')
        toast.error(i18n.t('company.notification.listError'), {
          queueable: true
        });
    }
  },
  async fetchAllCompanies({dispatch, commit, state}, toast) {
    dispatch('loadingON');
    try {
      const {data} = await CompanyService.allCompanies();
      commit(types.SET_ALL_COMPANY, data);
      dispatch('loadingOFF');
    } catch (error) {
      if (error.message !== 'cancelRequest')
        toast.error(i18n.t('company.notification.listError'), {
          queueable: true
        });
    }
  },
  async update({dispatch, commit, state}, [item, logo, toast]) {
    dispatch('loadingON');
    try {
      await CompanyService.update(item, logo);
      toast.success(i18n.t('company.notification.save'), {
        queueable: true,
        color: '#37A98C'
      });
    } catch (error) {
      if (error.message.includes('406')) {
        toast.error(i18n.t('company.notification.inUse'), {
          queueable: true,
          color: 'gray'
        });
      } else if (error.message !== 'cancelRequest') {
        toast.error(i18n.t('company.notification.saveError'), {
          queueable: true
        });
      }

    }
  },
  async delete({dispatch, commit, state}, [id, toast]) {
    try {
      await CompanyService.delete(id);
      toast.success(i18n.t('company.notification.deleteLogo'), {
        queueable: true,
        color: '#37A98C'
      });
    } catch (error) {
      if (error.message !== 'cancelRequest') {
        toast.error(i18n.t('company.notification.errorDeleteLogo'), {
          queueable: true
        });
      }

    }
  },
  async deleteCompany({commit, state}, [id, toast]) {
    try {
      await CompanyService.delete(id);
      toast.success(i18n.t('company.notification.deleted'), {
        queueable: true,
        color: '#37A98C'
      });
    } catch (error) {
      if (error.message.includes('406')) {
        toast.error(i18n.t('company.notification.inUse'), {
          queueable: true,
          color: 'gray'
        });
      } else {
        if (error.message !== 'cancelRequest')
          toast.error(i18n.t('company.notification.deletedError'), {
            queueable: true
          });
      }
    }
  },

  async setDefault({ commit }, companyIdentity) {
    const response = await CompanyService.setDefault(companyIdentity);

    if (response.status === 200) {
      this._vm.$toast.success(i18n.t('company.notification.setDefaultCompanySuccess'), {
        color: '#03AC8C',
        queueable: true
      });
    } else {
      this._vm.$toast.error(i18n.t('company.notification.setDefaultCompanyError'), {
        queueable: true
      });
    }
  },

  async fetchAvailableCompanies({ commit }) {
    return new Promise((resolve, reject) => {
      CompanyService.getAvailableCompanies()
        .then(response => {
          commit('SET_USER_COMPANIES', response.data.companies);

          const current = JSON.parse(localStorage.getItem('company:current'))
          if (!current)
            commit('SET_DEFAULT_COMPANY', _.find(response.data.companies, { isDefault: true }));

          resolve(true)
        })
        .catch((err) => {
          localStorage.clear();
          resolve(false)

          if (err.message !== 'cancelRequest')
            this._vm.$toast.error(i18n.t('company.notification.listError'), {
              queueable: true
            });
        })
    })
  },

  selectedCompanies({commit}, payload) {
    commit('SET_SELECTED', payload)
  },
}
