import _ from "lodash";
import LanguajeService from "../../../services/LanguajeService";

export default {
    GET_FILTER_TYPE:            state => state.type,
    GET_FILTER_SOURCE:          state => state.source,
    GET_DRAWER:                 state => state.drawer,
    GET_SIDENAV:                state => state.sidenav,
    GET_LOADING:                state => state.loading,
    GET_STATUS:                 state => state.status,
    GET_FILTERS:                state => state.filters,
    GET_PRODUCER_FILTERS:       state => state.producerFilters,
    GET_FILTERS_SORT:           state => state.filtersSort,
    GET_CHART_DATA:             state => state.chartData,
    GET_CHART_RANGES:           state => state.chartRanges,
    GET_LOCATION_RANGES:        state => state.locationRanges,
    GET_CONTRACTS_DATA:         state => state.contractsData,
    GET_CONTRACTS_RANGES:       state => state.contractsRanges,
    GET_STRUCTURE_FILTERS:      state => state.structureFilter,
    GET_OTHERS_FILTERS:         state => state.othersFilter,
    GET_OPTIONS_TABLE:          state => state.optionsTable,
    GET_FILTERS_CHIPS:          state => state.filtersChips,
    GET_COUNT_FILTERS:          state => state.cantFilters,
    GET_HEADERS_CONTRACTS:      state => state.filterHeaderContract,
    GET_HEADERS_DOCUMENTS:      state => state.filterHeaderDocuments,
    GET_HEADERS_INSPECTIONS:    state => state.filterHeaderInspections,
    GET_HEADERS_TRACEABILITY:   state => state.filterHeaderTraceability,
    GET_HEADERS_TRACEABILITYV2: state => state.filterHeaderTraceabilityV2,
    GET_HEADERS_RECORDS:        state => state.filterHeaderRecords,
    GET_HEADERS_PRE_RECORDS:    state => state.filterHeaderPreRecords,
    GET_LOCATIONS_FILTERS:      state => state.locations,
    GET_STAGE_ID:               state => state.filterRecord,
    GET_OBJECTS_TO_FILTERS:     state => state.filtersObjects,
    GET_DATE_FILTERS:           state => state.date,
    GET_DATA:                   state => state.data,
    GET_NOMENCLATOR_DATA:       state => state.nomenclator,
    GET_NOTIFICATION:           state => state.notification,
    GET_QUESTIONS:              state => state.questions,
    GET_SELECTED_QUESTIONS:     state => state.selectedQuestions,
    GET_ANSWERS:                state => state.answers,
    GET_SELECTED_ANSWERS:       state => state.selectedAnswers,
    GET_STRUCTURES_BY_IDS:      state => _.map(state.structuresByIds, (item) => {
        const name = LanguajeService.getKey3(item.language_key);
        return {
            ...item,
            structure_name: name ? name.charAt(0).toUpperCase() + name.slice(1).toLowerCase() : name
        };
    }),
    GET_MONTHS:       state => state.months,
}
