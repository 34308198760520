import {default as http} from './HttpService'

class PreRecordsService {
    constructor() {
    }

    recordById(id) {
        return http.client.get('/v1/pre-record/' + id);
    }

    appointments(filters) {
        return http.client.post('/v1/pre-record/filter/with-structure-value', filters);
    }

    // in use
    appointmentsByCompanyPaginated(pagination, source) {
        console.log(source)
        return http.client.post(`v1/pre-record/records-paginated`, pagination, {
            cancelToken: source.token
        });
    }
    // in use
    preRecordsByCompanyFiltered(filters) {
        return http.client.post(`v1/pre-record/records-filters`, filters);
    }

    // in use
    appointmentsByProcess(process_id) {
        return http.client.post(`v1/pre-record/filter/proccess/${process_id}`);
    }

    changeMobileActive(company, id, status) {
        let item = {"company_id": company, "pre_record_id": id, "status": status};
        return http.client.put(`v1/pre-record/change_mobile_active`, item);
    }

    filterSearch(data, source) {
        return http.client.post(`/v1/pre-record/string_filter`, data, {
            cancelToken: source.token
        });
    }

    // in use
    save(record, document, documents_file, editOrSave) {
        let fd = new FormData();
        fd.append('record_json', JSON.stringify(record));
        fd.append('document_json', JSON.stringify(document));
        if(documents_file.length > 0){
            _.forEach(documents_file, (file) => {
                fd.append('documents_file', file);
            });
        }
        if (editOrSave > -1) {
            return http.client.put('/v1/pre-record/update', fd, {headers: {'Content-Type': 'multipart/form-data'}});
        } else {
            return http.client.post(`v1/pre-record/create`, fd, {headers: {'Content-Type': 'multipart/form-data'}});
        }

    }

    delete(id) {
        return http.client.delete('/v1/pre-record/' + id);
    }
}

export default new PreRecordsService()
