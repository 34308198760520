import * as types from './mutations-type.js';
import {ALL_CONTRACTS_JSON, CONTRACTS_JSON_LIST, FACTORY_DATA, WALLETS_DATA} from "./mutations-type.js";

export default {
  [types.REGISTER_WEB3_INSTANCE](state, payload) {
    console.log('registerWeb3instance Mutation being executed')
    state.web3.account = payload
  },

  [types.REVERT_WEB3_INSTANCE](state) {
    console.log('revertWeb3instance Mutation being executed')
    state.web3.account = null
  },

  [types.WALLET_DATA](state, payload) {
    console.log('Wallet Data Mutation being executed')
    state.wallet.balance = payload
  },

  [types.IS_SYNC](state, payload) {
    console.log('Blockchain sync being executed')
    state.isSync = payload
  },

  [types.SYNC_INFO](state, payload) {
    state.syncInfo = payload
  },

  [types.WALLETS_DATA](state, payload) {
    console.log('Wallets Data Mutation being executed')
    state.wallets = payload
  },

  [types.FACTORY_OWNER](state, payload) {
    console.log('Is Factory Owner Mutation being executed', payload)
    state.isFactoryOwner = payload
  },

  [types.SET_PRODUCTS](state, payload) {
    state.products = payload
  },

  [types.DEFAULT_PRODUCTS](state) {
    state.products = []
  },

  [types.TOKEN_LIST](state, payload) {
    state.tokenList = payload
  },

  [types.TOKEN_DETAILS](state, payload) {
    state.contractHistory = payload
  },

  [types.DEFAULT_TOKEN_LIST](state) {
    state.tokenList = []
  },

  [types.CONTRACTS_LIST](state, payload) {
    state.contractsList = payload
  },

  [types.CONTRACTS_JSON_LIST](state, payload) {
    state.contractsJsonList = payload
  },

  [types.DEFAULT_CONTRACTS_LIST](state) {
    state.contractsList = []
    state.contractsJsonList = []
  },

  [types.SET_LOTS](state, payload) {
    state.lots = payload
  },

  [types.DEFAULT_LOTS](state) {
    state.lots = []
  },

  [types.DEFAULT_WALLET_DATA](state) {
    console.log('Default Wallet Data Mutation being executed')
    state.wallet.balance = null
  },

  [types.ALL_PRODUCTS](state, payload) {
    console.log('Fetch products Mutation being executed')
    state.products = payload
  },

  [types.FACTORY_DATA](state, payload) {
    console.log('Fetch factory data Mutation being executed')
    state.factory = payload
  },

  [types.TOKEN_DATA](state, payload) {
    console.log('Fetch token data Mutation being executed')
    state.token = payload.tokens
    state.amount = payload.amount
  },

  [types.WALLET_TRACKING_DETAILS](state, payload) {
    state.tracking = payload
  },

  [types.WALLET_TRACKING_TOTAL](state, payload) {
    state.amount = payload
  },

  [types.DEFAULT_TOKEN_DATA](state) {
    console.log('Default factory data Mutation being executed')
    state.tracking = []

    state.amount = {
      sent: null,
      received: null,
      settled: null
    }
  },

  [types.SET_LOADING_SEND](state, payload) {
    state.loadings.send = payload
  },

  [types.SET_LOADING_SETTLE](state, payload) {
    state.loadings.settle = payload
  },

  [types.SET_LOADING_START](state, payload) {
    state.loadings.start = payload
  },

  [types.SET_LOADING_STOP](state, payload) {
    state.loadings.pause = payload
  }
}
