import * as types from './mutations-type'

export default {
  [types.SET_ALL_UNITS] (state, data) {
    state.allUnits = data
  },
  [types.SET_ALL_GROUPUNITS_ON_UNITS] (state, data) {
    state.allGroupUnits = data
  },
  [types.SET_LOADING_ON](state, data) {
    state.loading = data;
  },
  [types.SET_LOADING_OFF](state, data) {
    state.loading = data;
  }
}
