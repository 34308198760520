import _ from "lodash";
import LanguajeService from "../../../../services/LanguajeService";
import sortList from "../../../../services/OrderListService";

export default {
    operators: state => sortList.orderListByUppercase(_.map(state.operators, (item) => {
        return {
            ...item,
            operator_name: LanguajeService.getKey3(item.languageKey),
            description_text: LanguajeService.getKey3(item.description),
            objectRelId: item.id
        };
    }), 'operator_name'),
    totalFromServer: state => state.totalFromServer,
    loading: state => state.loading
}
