import LanguajeService from "../../../../services/LanguajeService";
import sortList from "../../../../services/OrderListService";
import _ from 'lodash';

export default {
    documentDescriptions: state => sortList.orderListByUppercase(_.filter(_.map(state.documentDescriptions, (item) => {
        return {
            ...item,
            document_description_name: LanguajeService.getKey3(item.language_key)
        };
    }), (item) => {
        return item.language_key !== 'evidence'
    }), 'document_description_name')
}
