<template>
    <v-container fluid class="px-0 py-0">
        <v-img :src="image.s3_url" height="250" ></v-img>
        <v-list-item-subtitle v-if="testImages[keyP]">
            <h5><span class="secondary--text" style="font-family: 'Roboto', sans-serif;font-weight: normal">{{image.description}}</span></h5>
        </v-list-item-subtitle>
    </v-container>
</template>

<script>
    export default {
        name: "ImageComp",
        props: ['image','testImages','keyP'],
        data: () => ({

        })
    }
</script>

<style scoped>

</style>
