import * as types from './mutations-type'
import i18n from '@/plugins/i18n';
import N_UnitsService from "../../../../services/N_UnitsService";
import N_GroupUnitsService from "../../../../services/N_GroupUnitsService";

export default {
    loadingON({commit, state}) {
        state.allUnits = [];
        commit(types.SET_LOADING_ON, true);
    },
    loadingOFF({commit}) {
        commit(types.SET_LOADING_OFF, false);
    },
    setUnitsList({commit}, items){
        commit(types.SET_ALL_UNITS, items);
    },
    async fetchAllUnits({dispatch, commit}, toast) {
        dispatch('loadingON');
        try {
            const {data} = await N_UnitsService.units();
            commit(types.SET_ALL_UNITS, data);
            dispatch('loadingOFF');
        } catch (error) {
            if (error.message !== 'cancelRequest')
                toast.error(i18n.t('measurementUnit.notification.listError'), {
                    queueable: true
                });
        }
    },
    async fetchAllGroupUnits({dispatch, commit}, toast) {
        dispatch('loadingON');
        try {
            const {data} = await N_GroupUnitsService.units();
            commit(types.SET_ALL_GROUPUNITS_ON_UNITS, data);
            dispatch('loadingOFF');
            dispatch('fetchAllUnits', toast);
        } catch (error) {
            if (error.message !== 'cancelRequest')
                toast.error(i18n.t('measurementGroupUnit.notification.listError'), {
                    queueable: true
                });
        }
    },
    async saveUnit({dispatch, commit, state}, [item, editOrSave, toast]) {
        dispatch('loadingON');
        try {
            await N_UnitsService.save(item, editOrSave);
            toast.success(i18n.t('measurementUnit.notification.save'), {
                queueable: true,
                color: '#37A98C'
            });
        } catch (error) {
            if (error.message !== 'cancelRequest')
                toast.error(i18n.t('measurementUnit.notification.saveError'), {
                    queueable: true
                });
        }
    },
    async deleteUnit({commit, state}, [id, toast]) {
        try {
            await N_UnitsService.delete(id);
            toast.success(i18n.t('measurementUnit.notification.deleted'), {
                queueable: true,
                color: '#37A98C'
            });
        } catch (error) {
            if (error.message.includes('406')) {
                toast.error(i18n.t('measurementUnit.notification.inUse'), {
                    queueable: true,
                    color: 'gray'
                });
            } else {
                if (error.message !== 'cancelRequest')
                    toast.error(i18n.t('measurementUnit.notification.deletedError'), {
                        queueable: true
                    });
            }
        }
    }
}
