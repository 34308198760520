import * as types from './mutations-type';
import DashboardService from "@dashboard/services/DashboardService";
import i18n from "@/plugins/i18n"

const removeCompanyFilters = (companyId) => {
    for (let i = 0; i < localStorage.length; i++) {
        const key = localStorage.key(i);
        if (key.startsWith(`filters-${companyId}-`)) {
            localStorage.removeItem(key);
            i--;
        }
    }
}

export default {
    toggleEditMode({ commit }, value) {
        commit(types.SET_EDITING_MODE, value);
        commit(types.SET_WIDGET_DRAWER, value);
    },

    async saveDashboard({ commit }, payload) {
        const response = await DashboardService.saveDashboard(payload);
        return response.data;
    },

    async deleteDashboard({ commit }, layoutId) {
        const response = await DashboardService.deleteLayout(layoutId);

        if (response.status === 200) {
            this._vm.$toast.success(i18n.t('dashboard.notifications.deleteLayoutSuccess'), {
                color: '#03AC8C',
                queueable: true
            });
        } else {
            this._vm.$toast.error(i18n.t('dashboard.notifications.deleteLayoutError'), {
                queueable: true
            });
        }

        return response.status === 200;
    },

    async fetchLayoutConfig({ commit }, layoutId) {
        const response = await DashboardService.getLayoutConfig(layoutId);
        commit(types.SET_LAYOUT, {
            ...response.data,
            widgets: DashboardService.transformData(response.data.widgets)
        });
    },

    async fetchWidgetConfig({ }, [widgetId, filters]) {
        return new Promise((resolve, reject) => {
            DashboardService.getWidgetValues(widgetId, filters).then(res => {
                resolve(res.data)
            }).catch(async err => {
                console.log(err)
                if (err.response.status === 404) {
                    resolve({ reload: true, origin: 'fetchWidgetConfig' })
                }
                reject(err)
            });
        })
    },

    async sendDashboard({ commit }, payload) {
        const response = await DashboardService.sendDashboard(payload);

        if (response.status === 200) {
            this._vm.$toast.success(i18n.t('dashboard.notifications.sendLayoutSuccess'), {
                color: '#03AC8C',
                queueable: true
            });
        } else {
            this._vm.$toast.error(i18n.t('dashboard.notifications.sendLayoutError'), {
                queueable: true
            });
        }
    },

    async fetchUserLayouts({ commit }) {
        const response = await DashboardService.listUserLayouts();
        let layouts = response.data;

        if (layouts && layouts.length > 0) {
            layouts = _.map(layouts, (layout) => ({
                ...layout,
                widgets: DashboardService.transformData(layout.widgets)
            }))
        }
       commit(types.SET_LAYOUTS, layouts);
    },

    updateFilters({ commit }, payload) {
        commit('SET_FILTERS', payload);
    },

    loadFilters({ commit }, layoutId) {
        const profile = JSON.parse(localStorage.getItem('profile'))
        const filters = JSON.parse(localStorage.getItem(`filters-${profile.company_id}-${layoutId}`)) || JSON.parse(localStorage.getItem(`filters-${profile.company_id}-global`));
        if (filters) {
            commit('SET_FILTERS', filters);
        }
    },

    clearSpecificConfiguration({ commit }, layoutId) {
        const profile = JSON.parse(localStorage.getItem('profile'));
        if (localStorage.getItem(`filters-${profile.company_id}-${layoutId}`)) {
            localStorage.removeItem(`filters-${profile.company_id}-${layoutId}`);
        }
    },

    async uploadFile({ commit }, payload) {
        const response = await DashboardService.uploadFile(payload);
        return response.data;
    },

    clearAllFilters({state}) {
        const profile = JSON.parse(localStorage.getItem('profile'));
        removeCompanyFilters(profile.company_id);

        if (!state.layout.id) {
            localStorage.setItem(`filters-${profile.company_id}-global`, JSON.stringify({
                applyGlobally: true,
                dates: [],
                recordDates: [],
                processes: [],
                primaryLocations: [],
                secondaryLocations: [],
                isGlobal: true,
                stages: [],
                locationType: null,
                classificationTypes: []
            }));
        }
    },

    saveFilters({ state }, { layoutId, isGlobal }) {
        const filtersToSave = { ...state.filters, isGlobal };
        const profile = JSON.parse(localStorage.getItem('profile'));
        const companyId = profile.company_id;

        if (isGlobal) {
            removeCompanyFilters(companyId);
            localStorage.setItem(`filters-${companyId}-global`, JSON.stringify(filtersToSave));
        } else {
            localStorage.setItem(`filters-${companyId}-${layoutId}`, JSON.stringify(filtersToSave));

            if (localStorage.getItem(`filters-${companyId}-global`)) {
                localStorage.removeItem(`filters-${companyId}-global`);
            }
        }
    },

    async addUserPermissions({ commit }, payload) {
        const response = await DashboardService.addUserPermissions(payload);

        if (response.status === 200) {
            this._vm.$toast.success(i18n.t('dashboard.notifications.sharedLayoutSuccess'), {
                color: '#03AC8C',
                queueable: true
            });
        } else {
            this._vm.$toast.error(i18n.t('dashboard.notifications.sharedLayoutError'), {
                queueable: true
            });
        }
    },

    async getUserPermissions({ commit }, payload) {
        const response = await DashboardService.getUserPermissions(payload);

        if (response.status !== 200) {
            this._vm.$toast.error(i18n.t('dashboard.notifications.getUserPermissionsError'), {
                queueable: true
            });
        } else {
            commit('SET_USER_PERMISSIONS', response.data);
        }
    },

    async removeSharedDashboard({ commit }, dashboardId) {
        const response = await DashboardService.removeSharedDashboard(dashboardId);

        if (response.status === 200) {
            this._vm.$toast.success(i18n.t('dashboard.notifications.deleteLayoutSuccess'), {
                color: '#03AC8C',
                queueable: true
            });
        } else {
            this._vm.$toast.error(i18n.t('dashboard.notifications.deleteLayoutError'), {
                queueable: true
            });
        }
    },

    async setDefault({ commit }, dashboardId) {
        const response = await DashboardService.setDefault(dashboardId);

        if (response.status === 200) {
            this._vm.$toast.success(i18n.t('dashboard.notifications.setDefaultDashboardSuccess'), {
                color: '#03AC8C',
                queueable: true
            });
        } else {
            this._vm.$toast.error(i18n.t('dashboard.notifications.setDefaultDashboardError'), {
                queueable: true
            });
        }
    },

    async cloneDashboard({ commit }, dashboardId) {
        const response = await DashboardService.cloneDashboard(dashboardId);

        if (response.status === 200) {
            this._vm.$toast.success(i18n.t('dashboard.notifications.cloneDashboardSuccess'), {
                color: '#03AC8C',
                queueable: true
            });
        } else {
            this._vm.$toast.error(i18n.t('dashboard.notifications.cloneDashboardError'), {
                queueable: true
            });
        }
    }
};
