import _ from 'lodash';

class PermissionService {
    constructor() {
    }

    check(permissions) {
        let check = false;
        this.profile = localStorage.getItem('profile') ? JSON.parse(localStorage.getItem('profile')) : null;
        if (this.checkAdmin()) {
            check = true;
        } else {
            if (this.profile)
                _.forEach(permissions, (permissionAccess) => {
                    _.forEach(this.profile.roles, (rol) => {
                        _.forEach(permissionAccess.permisions, (permission) => {
                            if (_.find(rol.authorityList, {
                                'domain': permissionAccess.domain,
                                'permission': permission
                            })) {
                                check = true;
                            } else {
                                return false;
                            }
                        });
                    })

                });
        }
        return check;
    }

    checkAdmin() {
        let check = false;
        this.profile = localStorage.getItem('profile') ? JSON.parse(localStorage.getItem('profile')) : null;
        if (this.profile)
            if (_.find(this.profile.roles, {'manager': true})) {
                check = true;
            }
        return check;
    }

}

export default new PermissionService()
