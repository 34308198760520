import Compressor from 'compressorjs';

class CompactImages {

    constructor(){}

    downscaleImage(quality, file, callback) {
        if (!file) {
            return;
        }

        new Compressor(file, {
            quality: quality,
            success(result) {
                callback(result);
            }
        });
    }
}

export default new CompactImages();