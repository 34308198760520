import actions from './store/actions'
import getters from './store/getters'
import mutations from './store/mutations'
import state from './store/state'

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
