import _ from "lodash";
import LanguajeService from "../../../../services/LanguajeService";
import sortList from "../../../../services/OrderListService";

export default {
    totalFromServer: state => state.totalFromServer,
    products: state => sortList.orderListByUppercase(_.map(state.products, (item) => {
        return {
            ...item,
            product_name: LanguajeService.getKey3(item.language_key),
            description_text: LanguajeService.getKey3(item.description),
            category_name: LanguajeService.getKey3(item.category),
            objectRelId: item.id,
            isSelected: true
        };
    }), 'product_name'),
    loading: state => state.loading,
}
