import {default as http} from './HttpService'

class N_GroupUnitsService {
    constructor() {
    }

    units() {
        return http.client.get('/v1/measurement_unit_group');
    }

    save(item, editOrSave) {
        if (editOrSave > -1) {
            return http.client.put('/v1/measurement_unit_group', item);
        } else {
            return http.client.post('/v1/measurement_unit_group', item);
        }
    }

    delete(id) {
        return http.client.delete('/v1/measurement_unit_group/' + id);
    }
}

export default new N_GroupUnitsService()
