export default {
    authority: {
        name: "",
        company_id: null,
        password: "",
        email: "",
        document_key: "",
        document_private: "",
        revoke: [],
        grant: [],
        language: "",
        roles: [],
        process: [],
        locationsOwner: [],
        primaryLocationOwner: null
    },
    authorities: [],
    roles:[],
    authoritiesProcess: [],
    authoritiesLocations: [],
    authoritiesStoragesDevices: [],
    locations: [],
    loading: true,
}
