export const SET_LOADING_ON = 'SET_LOADING_ON';
export const SET_LOADING_OFF = 'SET_LOADING_OFF';
export const SET_ERROR_ON = 'SET_ERROR_ON';
export const SET_ERROR_OFF = 'SET_ERROR_OFF';
export const SET_SURVEY_BY_COMPANY_LIST = 'SET_SURVEY_BY_COMPANY_LIST';
export const SET_SURVEY_TEMPLATE_BY_COMPANY_LIST = 'SET_SURVEY_TEMPLATE_BY_COMPANY_LIST';
export const SET_ALL_TEMPLATES_TO_EXPORT = 'SET_ALL_TEMPLATES_TO_EXPORT';
export const SET_TEMPLATES_FOR_FILTERS = 'SET_TEMPLATES_FOR_FILTERS';
export const SET_SURVEY_BY_ID = 'SET_SURVEY_BY_ID';
export const SET_SECTION_BY_ID = 'SET_SECTION_BY_ID';
export const SET_QUESTION_DELETE = 'SET_QUESTION_DELETE';
