import * as types from './mutations-type.js';

export default {
    [types.SET_PRODUCT_LIST](state, data) {
        state.products = data.content;
        state.totalFromServer = data.totalElements;
    },
    [types.SET_PRODUCT_LIST_FOR_PRINT](state, data) {
        state.products = data;
    },
    [types.SET_LOADING_ON](state, data) {
        state.loading = data;
    },
    [types.SET_LOADING_OFF](state, data) {
        state.loading = data;
    },

}
