import TrackingService from '../../../services/TrackingService'
import * as types from '../../labels/store/mutations-type'
import i18n from '@/plugins/i18n';

export default {

  loadingON({commit}) {
    commit(types.SET_LOADING_ON, true);
  },
  loadingOFF({commit}) {
    commit(types.SET_LOADING_OFF, false);
  },
  async changePrint({dispatch, commit, state}, [labelData, toast]) {
    return new Promise((resolve, reject)=>{
      TrackingService.changePrint(labelData).then(res =>{
        resolve(res)
        toast.success(i18n.t('records.notification.saveLabel'), {
          queueable: true,
          color: 'secondary'
        });
      }).catch(error => {
        if (error.message !== 'cancelRequest')
          toast.error(i18n.t('records.notification.saveErrorLabel'), {
            queueable: true,
          })
      });
    });
  },

  async saveLabel({dispatch, commit, state}, [labelData, toast]) {
    return new Promise((resolve, reject)=>{
       TrackingService.saveLabel(labelData).then(res =>{
         resolve(res)
         toast.success(i18n.t('records.notification.saveLabel'), {
           queueable: true,
           color: 'secondary'
         });
       }).catch(error => {
         if (error.message !== 'cancelRequest')
           toast.error(i18n.t('records.notification.saveErrorLabel'), {
             queueable: true,
           })
       });
    });
  },
  qrlabel({ commit }) {
    commit(types.QRLABEL);
  },
  async findRecordTrackingById({ dispatch, commit, state }, [id, toast]) {
    dispatch("loadingON");
    try {
      const { data } = await TrackingService.recordByQrId(id);

      commit(types.SET_RECORD_TRACKING_DATA, data);
      dispatch("loadingOFF");
    } catch (error) {
      if (error.message !== "cancelRequest")
        toast.error(i18n.t("records.notification.listError"), {
          queueable: true,
        });
    }
  },
}
