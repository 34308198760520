<template>
  <v-menu
    v-model="menu"
    :close-on-content-click="false"
    :nudge-bottom="60"
    :nudge-right="0"
    left
    style="z-index: 999"
    transition="slide-y-transition"
    @input="readAll"
    content-class="custom-menu__elevation"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        fab
        text
        small
        v-bind="attrs"
        v-on="on"
        :disabled="!(notifications.length > 0)"
        dark
      >
        <v-fade-transition>
          <span class="custom-badge" v-if="isNotWatched" />
        </v-fade-transition>
        <v-icon size="20px" class="ma-1" color="white" text>
          mdi-bell
        </v-icon>
      </v-btn>
    </template>

    <v-card elevation="0">
      <v-card-title class="ma-0 pa-0">
        <v-toolbar flat elevation="0" color="secondary">
          <v-toolbar-title class="white--text">
            {{ $t("notifications.module.titles.notifications") }}
          </v-toolbar-title>
          <v-spacer />
          <v-toolbar-items>
            <v-btn
              color="secondary"
              elevation="0"
              fab
              small
              @click="fetchAll"
              :loading="loadingFetchAll"
            >
              <v-icon v-text="'mdi-refresh'" />
            </v-btn>
          </v-toolbar-items>
          <v-toolbar-items>
            <v-menu :nudge-bottom="60"
                    :nudge-right="200"
                    max-width="35%"
                    min-width="25%"
                    max-height="50%"
                    transition="slide-y-transition"
                    :close-on-content-click="false"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-btn v-bind="attrs"
                       v-on="on"
                       color="secondary"
                       elevation='0'
                       fab small
                       :disabled="loadingFetchAll"
                >
                  <v-icon v-text="'mdi-dots-horizontal'" />
                </v-btn>
              </template>
              <v-list dense flat elevation="0" tile>
                <v-list-item class="d-flex justify-space-between">
                  <v-list-item-title style="font-weight: normal; font-size: 14px">{{ $t('notifications.module.titles.markAllRead') }}</v-list-item-title>
                  <v-btn fab x-small color="grey lighten-4" elevation="0" @click="readAll()" :loading="loadingAll">
                    <template v-slot:loader>
                      <v-progress-circular indeterminate size="12" width="2" />
                    </template>
                    <v-icon color="secondary" small>
                      mdi-arrow-right
                    </v-icon>
                  </v-btn>
                </v-list-item>
              </v-list>
            </v-menu>

          </v-toolbar-items>
        </v-toolbar>
      </v-card-title>
      <v-card-text class="ma-0 pa-0" v-if="notifications.length > 0">
        <span v-for="(notification, index) in notifications">
          <BrainAgNotification
            v-if="notification.module === 'BRAIN_AG'"
            :index="index"
            :loading="loading[notification.id]"
            :notification="notification"
            @mark:read="markRead(notification)"
            :p-icon="setIconPriority(notification.priority).icon"
            :p-color="setIconPriority(notification.priority).color"
          />
          <RecordsNotification
            v-if="notification.module === 'RECORDS' && !isExport(notification)"
            :index="index"
            :loading="loading[notification.id]"
            :notification="notification"
            @mark:read="markRead(notification)"
            :p-icon="setIconPriority(notification.priority).icon"
            :p-color="setIconPriority(notification.priority).color"
          />
          <RecordValidationNotification
            v-if="notification.module === 'RECORD_VALIDATION'"
            :index="index"
            :loading="loading[notification.id]"
            :loadingSee="loadingSee[notification.id]"
            :notification="notification"
            @see:record="seeRecord(notification)"
            @mark:read="markRead(notification)"
            :p-icon="setIconPriority(notification.priority).icon"
            :p-color="setIconPriority(notification.priority).color"
          />
          <TraceabilityNotification
            v-if="notification.module === 'TRACEABILITY'"
            :index="index"
            :loading="loading[notification.id]"
            :notification="notification"
            @mark:read="markRead(notification)"
            :p-icon="setIconPriority(notification.priority).icon"
            :p-color="setIconPriority(notification.priority).color"
          />
          <TraceabilityErrorNotification
            v-if="notification.module === 'TRACEABILITY_ERROR'"
            :index="index"
            :loading="loading[notification.id]"
            :notification="notification"
            @mark:read="markRead(notification)"
            :p-icon="setIconPriority(notification.priority).icon"
            :p-color="setIconPriority(notification.priority).color"
          />
          <S3FileNotification
            v-if="isExport(notification)"
            :index="index"
            :loading="loading[notification.id]"
            :notification="notification"
            @mark:read="markRead(notification)"
            :p-icon="setIconPriority(notification.priority).icon"
            :p-color="setIconPriority(notification.priority).color"
          />
          <StockNotification
            v-if="notification.module === 'STOCK'"
            :index="index"
            :loading="loading[notification.id]"
            :notification="notification"
            @mark:read="markRead(notification)"
            :p-icon="setIconPriority(notification.priority).icon"
            :p-color="setIconPriority(notification.priority).color"
          />
          <UserLocationsNotification
            v-if="notification.module === 'USER_LOCATIONS'"
            :index="index"
            :loading="loading[notification.id]"
            :notification="notification"
            @mark:read="markRead(notification)"
            :p-icon="setIconPriority(notification.priority).icon"
            :p-color="setIconPriority(notification.priority).color"
          />
        </span>
      </v-card-text>
      <v-card-actions class="ma-0 pa-0" v-if="totalFromServer > 5">
        <v-btn block tile color="secondary" dark small @click="menu = !menu" :to="{name: 'notifications'}">
          <strong>+ {{ totalFromServer < 5 ? 0 : (totalFromServer - 5) }}</strong> &nbsp; {{ $t("notifications.module.titles.notifications").toLowerCase() }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-menu>
</template>

<script>
  import _ from 'lodash'
  import router from '@/router'
  import { mapActions, mapGetters } from 'vuex'
  import BrainAgNotification from "@/module/configuration/notifications/components/types/BrainAgNotification.vue";
  import RecordsNotification from "@/module/configuration/notifications/components/types/RecordsNotification.vue";
  import StockNotification from "@/module/configuration/notifications/components/types/StockNotification.vue";
  import TraceabilityNotification
    from "@/module/configuration/notifications/components/types/TraceabilityNotification.vue";
  import UserLocationsNotification
    from "@/module/configuration/notifications/components/types/UserLocationsNotification.vue";
  import RecordValidationNotification
    from "@/module/configuration/notifications/components/types/RecordValidationNotification.vue";
  import S3FileNotification from "@/module/configuration/notifications/components/types/S3FileNotification.vue";
  import TraceabilityErrorNotification
    from "@/module/configuration/notifications/components/types/TraceabilityErrorNotification.vue";

  export default {
    name: 'NotificationsComponent',
    components: {
      TraceabilityErrorNotification,
      RecordValidationNotification,
      UserLocationsNotification,
      TraceabilityNotification,
      StockNotification,
      RecordsNotification,
      BrainAgNotification,
      S3FileNotification
    },

    data:() => ({
      menu: false,
      loading: {},
      loadingSee: {},
      loadingAll: false,
      loadingFetchAll: false
    }),

    computed: {
      ...mapGetters({
        notifications: "notifications/notificationsMenu",
        totalFromServer: "notifications/totalFromServerMenu"
      }),
      isNotWatched() {
        return _.filter(this.notifications, { watched: false }).length > 0
      },
      isExport() {
        return (notification) => (notification.data && notification.data.type) && ['PDF_EXPORT', 'EXCEL_EXPORT'].includes(notification.data.type);
      },
    },

    mounted() {
      this.$root.$on('show:record', n => {
        this.seeRecord(n)
      })
    },

    methods: {
      ...mapActions({
        setReadStatus: "notifications/setReadStatus",
        fetchNotificationsMenuByUser: "notifications/fetchNotificationsMenuByUser",
        findLogRecordById: 'records/findLogByRecordId',
        findRecordById: 'records/findRecordById'
      }),
      setIconPriority(priority) {
        switch (priority) {
          case "LOW":
          case "NORMAL":
            return {
              icon: 'mdi-minus',
              color: 'primary'
            }
          case "MEDIUM":
            return {
              icon: 'mdi-equal',
              color: 'warning'
            }
          case "HIGH":
            return {
              icon: 'mdi-chevron-double-up',
              color: 'error'
            }
        }
      },
      async fetchAll() {
        this.loadingFetchAll = true
        await this.fetchNotificationsMenuByUser([this.$toast]).finally(() => {
          this.loadingFetchAll = false
        })
      },
      async readAll(e) {
        if (!e) {
          this.loadingAll = true

          const notifications = _.filter(this.notifications, {watched: false})

          let ids = []
          if (notifications && notifications.length > 0)
            ids = _.map(notifications, n => n.id)

          if (ids && ids.length > 0)
            await this.setReadStatus([ids, this.$toast, 'menu']).finally(() => {
              this.loadingAll = false
            }).finally(() => {
              this.$store.dispatch('notifications/fetchNotificationsMenuByUser', [this.$toast])
            })
        }
      },
      async markRead(item) {
        const id = _.cloneDeep(item.id)
        this.$set(this.loading, id, true)

        await this.setReadStatus([[id], this.$toast, 'menu']).finally(() => {
          this.$set(this.loading, id, false)
        })
      },
      async seeRecord(notification) {
        if (router.currentRoute.name !== 'records')
          await router.push({name: 'records'});

        this.$set(this.loadingSee, notification.id, true)

        const promises = []
        promises.push(this.findRecordById([notification.recordId, this.$toast]))
        promises.push(this.findLogRecordById([notification.recordId, this.$toast]))

        Promise.all(promises).finally(() => {
          this.$set(this.loadingSee, notification.id, false)
          this.$store.commit('records/SET_DIALOG_DETAILS', true)
        })
      },
    },

    destroyed() {
      this.$root.$off('show:record')
    }
  }
</script>

<style scoped>
  .custom-badge {
    height: 12px;
    width: 12px;
    background-color: red;
    border: 2px solid white;
    border-radius: 50%;
    display: inline-block;
    position: absolute;
    right: 8px;
    top: 2px;
  }

  .theme--light.v-list-item:not(.v-list-item--active):not(.v-list-item--disabled)
    i.cant {
    color: white !important;
  }
</style>
