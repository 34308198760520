export default {
    locations: null,
    locationsList: [],
    locationsListForFilters: [],
    locationsForFilters: [],
    totalFromServer: 0,
    versions: [],
    totalFromServerVersions: 0,
    totalFromServerForFilters: 0,
    activeLocationsList: [],
    personInCharge: [],
    locationByUserAccess: [],
    classifications: [],
    badgeClassifications: [],
    loading: true,
    mapConfig: {
        sources: [
            {
                id: 'LocationPrimarySource',
                config: {
                    type: 'geojson',
                    data: {type: 'FeatureCollection', features: []}
                }
            },
            {
                id: 'LocationSecondarySource',
                config: {
                    type: 'geojson',
                    data: {type: 'FeatureCollection', features: []}
                }
            }
        ],
        layers: [
            {
                id: 'LocationPrimaryLayer',
                typeLayers: 'fill-Location',
                source: 'LocationPrimarySource',
                paint: {}
            },
            {
                id: 'LocationPrimaryNamesLayer',
                typeLayers: 'symbol',
                source: 'LocationPrimarySource'
            },
            {
                id: 'LocationsBorderLayer',
                typeLayers: 'line',
                source: 'LocationPrimarySource'
            },
            {
                id: 'LocationSecondaryLayer',
                typeLayers: 'fill-Storage',
                source: 'LocationSecondarySource',
                paint: {}
            },
            {
                id: 'LocationSecondaryNamesLayer',
                typeLayers: 'symbol',
                source: 'LocationSecondarySource'
            },
            {
                id: 'LocationSecondaryBorderLayer',
                typeLayers: 'line',
                source: 'LocationSecondarySource'
            },
        ]
    },

    // ---------- EXTRA COLUMNS --------------
    columns: [],
    selectedColumns: [],

    // ---------- SELECTORS ------------
    sLocations: []
}
