import {default as http} from './HttpService'

class DocumentAuthorityService {
    constructor() {
    }

    documentAuthorities() {
        return http.client.get(`v1/company/document-authority`);
    }
    save(item, editOrSave) {
        if (editOrSave > -1) {
            return http.client.put('/v1/document-authority', item);
        } else {
            return http.client.post('/v1/document-authority', item);
        }
    }

    delete(id) {
        return http.client.delete('/v1/document-authority/' + id);
    }
}

export default new DocumentAuthorityService()
