export const SET_LOADING_ON = 'SET_LOADING_ON';
export const SET_LOADING_OFF = 'SET_LOADING_OFF';
export const SET_DIALOG_DETAILS = 'SET_DIALOG_DETAILS';
export const SET_CANT_TO_EXPORT = 'SET_CANT_TO_EXPORT';
export const SET_APPOINTMENTS_BY_COMPANY_PAGINATED = 'SET_APPOINTMENTS_BY_COMPANY_PAGINATED';
export const SET_APPOINTMENTS_BY_COMPANY_PAGINATED_WITHOUT_STAGE = 'SET_APPOINTMENTS_BY_COMPANY_PAGINATED_WITHOUT_STAGE';
export const SET_APPOINTMENTS_BY_COMPANY = 'appointmentsByCompany';
export const SET_APPOINTMENTS_BY_COMPANY_FILTERED = 'SET_APPOINTMENTS_BY_COMPANY_FILTERED';
export const SET_APPOINTMENTS_BY_PROCESS = 'SET_APPOINTMENTS_BY_PROCESS';
export const SET_APPOINTMENTS_BY_LOTE = 'SET_APPOINTMENTS_BY_LOTE';
export const SET_TAB_INDEX = 'SET_TAB_INDEX';
export const SET_STRUCTURES_LIST = 'SET_STRUCTURES_LIST';
export const SET_RECORD = 'SET_RECORD';
export const SET_EVIDENCES = 'SET_EVIDENCES';
export const RESTORE_EVIDENCES = 'RESTORE_EVIDENCES';
export const SET_RECORDS_FOR_PRINT = 'SET_RECORDS_FOR_PRINT';
export const SET_ORIGINAL_STATE_RECORD = 'SET_ORIGINAL_STATE_RECORD';
export const SET_ORIGINAL_STATE_FOR_PRINT_RECORDS = 'SET_ORIGINAL_STATE_FOR_PRINT_RECORDS';

export const SET_SOURCE = 'SET_SOURCE';
export const SET_REQUEST = 'SET_REQUEST';

export const SET_HEADERS = 'SET_HEADERS';

export const EXPORT = 'EXPORT';

export const SET_RECORD_LOG = 'SET_RECORD_LOG';
export const RESTORE_RECORD_LOG = 'RESTORE_RECORD_LOG';
export const CHECK_BURDENS = 'CHECK_BURDENS';
export const CHECK_BURDENS_DATA = 'CHECK_BURDENS_DATA';

