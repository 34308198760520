<template>
  <v-app-bar :clipped-left="true" app color="secondary" class="custom--toolbar">
    <v-toolbar-title>
      <div v-if="profile">
        <span class="ml-4 d-flex justify-start align-center align-content-center">
          <v-img v-if='profile.companyLogo && profile.companyLogo.s3_url'
                 width="80" max-width="auto" :aspect-ratio="16/9" contain
                 :src="`${profile.companyLogo.s3_url}`"
                 class="mr-4"
          />
          <h3 v-else :class="{ 'ml-4' : !profile.companyLogo }" class='custom-list-title'>{{ profile.company_name_key }}</h3>
        </span>
      </div>

    </v-toolbar-title>
    <v-spacer></v-spacer>
    <div class="navbar-styles flex-row-reverse neuter">
      <change-password ref="changePass"></change-password>
      <div class="mx-4 d-flex justify-center align-center">
        <v-menu
          :close-on-content-click="false"
          :nudge-bottom="55"
          max-width="50%"
          transition="slide-y-transition"
          style="z-index: 999"
          content-class="custom-menu__elevation"
        >
          <template v-slot:activator="{ on, attrs }">
            <span v-on="on" v-bind="attrs" class="white--text">
              <v-icon size="20px" class="ma-1 mouse-pointer" dark
                >mdi-cog</v-icon
              >
            </span>
          </template>
          <v-list elevation="0" class="mt-0 pt-0">
            <v-list-item style="background-color: #03ac8c">
              <v-icon
                light
                class="pr-2"
                style="color: #ffffff !important"
                dark
                right
                small
              >
                mdi-account-circle-outline
              </v-icon>
              <v-list-item-title class="white--text">{{
                $t("company.titles.configurations")
              }}</v-list-item-title>
            </v-list-item>
            <v-list-item :to="{ name: 'notifications' }">
              <v-icon class="pr-2" dark right small>mdi-bell-outline</v-icon>
              <v-list-item-title>{{
                $t("notifications.module.titles.notifications")
              }}</v-list-item-title>
            </v-list-item>
            <v-list-item @click="changePassDialog">
              <v-icon class="pr-2" dark right small>mdi-key-change</v-icon>
              <v-list-item-title>{{
                $t("auth.fields.changePass")
              }}</v-list-item-title>
            </v-list-item>
            <!--<v-list-item @click="logout()">
              <v-icon class="pr-2" dark right small>mdi-logout</v-icon>
              <v-list-item-title>{{
                $t("auth.fields.logout")
              }}</v-list-item-title>
            </v-list-item>-->
          </v-list>
        </v-menu>

        <SelectCompany />

        <NotificationsComponent />

        <v-btn
          fab
          text
          small
          dark
          @click="logout()"
        >
          <v-icon size="20px" color="white" text>
            mdi-logout
          </v-icon>
        </v-btn>
      </div>
      <v-divider class="mx-1" dark inset vertical></v-divider>
      <languaje-select
        :background="'neuter'"
        :dark="true"
        :dense="false"
        :flagH="14"
        :flagW="22"
        :flat="true"
        class="ml-3"
        style="width: 70px"
      ></languaje-select>
      <v-divider
        v-if="isIgPresent"
        class="mx-1"
        dark
        inset
        vertical
      ></v-divider>
      <v-img
        v-if="isIgPresent"
        class="logos-header pr-0"
        height="150px"
        :src="footerLogos"
      ></v-img>
    </div>
  </v-app-bar>
</template>
<script>
  import LanguajeSelect from "@/components/LanguajeSelect"
  import ChangePassword from "@/components/ChangePassword"
  import NotificationsComponent from "@/module/configuration/notifications/components/NotificationsComponent"
  import _ from "lodash"
  import SelectCompany from "@/components/layout/common/SelectCompany.vue"

  export default {
    name: "app-layout-navbar",
    components: { SelectCompany, NotificationsComponent, ChangePassword, LanguajeSelect },
    data: () => ({
      dialogChange: false,
      logo: require("@/assets/img/logo.png"),
      email: "",
      integrations: {
        IG: null,
      },
      profile: null,
      footerLogos: require("@/assets/img/Logos.png"),
      isIgPresent: false,
    }),
    created() {
      this.profile = localStorage.getItem("profile")
        ? JSON.parse(localStorage.getItem("profile"))
        : []
      this.integrations.IG = this.$store.getters['integration/IS_PRESENT']('IG');

      if (this.integrations.IG) {
        this.isIgPresent = true;
      }

      this.email = this.profile.email
    },
    computed: {},
    methods: {
      changePassDialog() {
        this.$refs.changePass.openDialog()
      },
      logout() {
        localStorage.clear()
        this.$router.push("/login")
        this.$router.go()
      },
    },
  }
</script>
<style lang="scss">
  @import "../../../src/assets/css/variables.scss";

  $btn-text-transform: "none";

  .navbar-styles {
    height: 100%;
    display: flex !important;
    justify-items: center !important;
    align-items: center !important;
    cursor: pointer !important;
  }

  .custom--toolbar {
    .v-toolbar__content,
    .v-toolbar__extension {
      padding: 0px !important;
    }
  }

  .logos-header {
    height: 100%;
    width: 300px;
    filter: grayscale(100%) brightness(1000%);
  }
</style>