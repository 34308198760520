import * as types from './mutations-type.js';

export default {
    [types.SET_AUTHORITY_LIST](state, data) {
        state.authorities = data;
    },
    [types.SET_AUTHORITY](state, data) {
        state.authority = data;
    },
    [types.RESTORE_AUTHORITY](state) {
        state.authority = {
            name: "",
            company_id: null,
            password: "",
            email: "",
            document_key: "",
            document_private: "",
            revoke: [],
            grant: [],
            language: "",
            roles: [],
            process: [],
            locationsOwner: [],
            primaryLocationOwner: null
        };
    },
    [types.SET_AUTHORITY_PROCESS_LIST](state, data) {
        state.authoritiesProcess = data;
    },
    [types.SET_AUTHORITY_LOCATIONS_LIST](state, data) {
        state.authoritiesLocations = data;
    },
    [types.SET_AUTHORITY_LOCATIONS](state, data) {
        state.locations = data;
    },
    [types.SET_AUTHORITY_STORE_DEVICE](state, data) {
        state.authoritiesStoragesDevices = data;
    },
    [types.SET_AUTHORITY_ROLE_LIST](state, data) {
        state.roles = data;
    },
    [types.SET_LOADING_ON](state, data) {
        state.loading = data;
    },
    [types.SET_LOADING_OFF](state, data) {
        state.loading = data;
    },
    [types.SET_AUTHORITY_PROCESS_LIST_EDIT](state, data) {
        state.authoritiesProcessEdit = data;
    },

}
