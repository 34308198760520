import {default as http} from './HttpService';

class AuthService {
    constructor() {
        this.path = '/v1';
    }


    login(data) {
        return http.client.post(`${this.path}/profile/auth`, data)
    }

    logout() {
        return http.client.get(`${this.path}/logout`)
    }

    refreshToken(refresh_token, token) {
        return http.client.get(`${this.path}/profile/refresh_token?refresh_token=${refresh_token}&token=${token}`)
    }

    getProfile() {
        return http.client.get(`${this.path}/profile/light`)
    }

    parseJwt(token) {
        const base64Url = token.split('.')[1];
        const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
        const jsonPayload = decodeURIComponent(atob(base64).split('').map(function (c) {
            return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
        }).join(''));

        return JSON.parse(jsonPayload);
    }

    isTokenExpired(exp) {
        const date = new Date(0);
        date.setUTCSeconds(exp);
        return (date.valueOf() - new Date().valueOf()) / 1000;
    }

    changePass(dataChange){
        return http.client.put(`${this.path}/person-in-charge/change_password`, dataChange);
    }
}

export default new AuthService()
