import { VBtn } from 'vuetify/lib/components/VBtn';
import { VChip } from 'vuetify/lib/components/VChip';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemAction } from 'vuetify/lib/components/VList';
import { VListItemAvatar } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemIcon } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VListItem,{key:_vm.index},[(_vm.pIcon)?_c(VListItemAvatar,[_c(VIcon,{attrs:{"color":_vm.pColor}},[_vm._v(" "+_vm._s(_vm.pIcon)+" ")])],1):_vm._e(),_c(VListItemIcon,{staticClass:"fixed-width"},[_c(VChip,{attrs:{"small":"","color":"error"}},[_vm._v(" "+_vm._s(_vm.$t('reports.titles.traceability').toUpperCase())+" ")])],1),_c(VListItemContent,{staticClass:"d-flex justify-start"},[_c('p',{staticClass:"ma-0 pa-0 my-1 black--text",staticStyle:{"font-size":"14px"}},[_vm._v(" "+_vm._s(_vm.$t('reports.titles.notGeneratedReport'))+" ")]),_c('br'),_c('p',{staticClass:"ma-0 pa-0 my-1",staticStyle:{"font-size":"14px"}},[_vm._v(" "+_vm._s(_vm.$t('reports.fields.lot'))+": "),_c('strong',[_vm._v(_vm._s(_vm.notification.data.lot))]),_c('br'),_vm._v(" "+_vm._s(_vm.$t('reports.fields.product'))+": "),_c('strong',[_vm._v(_vm._s(_vm.notification.data.product))])]),_c('br'),_c(VListItemTitle,{staticClass:"d-block"},[_c('p',{staticClass:"ma-0 pa-0 my-1",staticStyle:{"font-size":"12px","color":"#0f0d0d","text-align":"start"}},[_vm._v(_vm._s(_vm.getDateFormat(_vm.notification.dateTime)))])])],1),(_vm.withAction)?_c(VListItemAction,[(!_vm.notification.watched)?_c(VBtn,{attrs:{"fab":"","x-small":"","color":"grey lighten-4","elevation":"0","loading":_vm.loading},on:{"click":function($event){return _vm.$emit('mark:read')}},scopedSlots:_vm._u([{key:"loader",fn:function(){return [_c(VProgressCircular,{attrs:{"indeterminate":"","size":"12","width":"2"}})]},proxy:true}],null,false,3385518082)},[_c(VIcon,{attrs:{"color":"secondary","small":""}},[_vm._v(" mdi-check ")])],1):_vm._e()],1):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }