<template>
    <v-card flat>
        <v-card-text class="pa-0 text-left">
            <v-row class="ml-4" v-if="procedure[keyP].name && procedure[keyP].name !== ''">
                <v-col style="color: #232323; font-weight: bold">
                   {{procedure[keyP].name}}
                </v-col>
            </v-row>
            <v-row class="ml-4" v-if="procedure[keyP].data && procedure[keyP].data !== ''">
                <v-col style="color: #232323; font-weight: bold">
                    {{getDateFormat(procedure[keyP].data)}}
                </v-col>
            </v-row>
            <v-row class="ml-4" v-if="procedure[keyP].detail1 && procedure[keyP].detail1 !== ''">
                <v-col style="color: #232323; font-weight: bold">
                     {{procedure[keyP].detail1}}
                </v-col>
            </v-row>
            <v-row class="ml-4" v-if="procedure[keyP].detail2 && procedure[keyP].detail2 !== ''">
                <v-col style="color: #232323; font-weight: bold">
                    {{procedure[keyP].detail2}}
                </v-col>
            </v-row>
        </v-card-text>
    </v-card>
</template>

<script>
    import moment from 'moment';
    export default {
        name: "Procedures",
        props: ['procedure','keyP'],
        methods:{
            getDateFormat(dateString) {
                return moment(new Date(dateString)).format('DD/MM/YYYY');
            },
        }
    }
</script>

<style scoped>

</style>
