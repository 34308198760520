export const SET_LOADING = 'SET_LOADING';
export const SET_LOADING_SELLERS = 'SET_LOADING_SELLERS';
export const SET_LOADING_CONTRACTS = 'SET_LOADING_CONTRACTS';
export const SET_DATA_STOCK = 'SET_DATA_STOCK';
export const SET_RECALCULATE_STOCK_INFO = 'SET_RECALCULATE_STOCK_INFO';
export const SET_DATA_CONTRACTS = 'SET_DATA_CONTRACTS';
export const SET_CONTRACTS_NAMES = 'SET_CONTRACTS_NAMES';
export const SET_DATA_SELLERS = 'SET_DATA_SELLERS';
export const SET_SELLERS_NAMES = 'SET_SELLERS_NAMES';

// charts
export const SET_DATASETS = 'SET_DATASETS';
export const SET_CHART = 'SET_CHART';
export const SET_ITEM_IN_SHEET = 'SET_ITEM_IN_SHEET';

// sheet
export const SET_SHEET_CONTRACT = 'SET_SHEET_CONTRACT';
export const SET_SHEET_SELLER = 'SET_SHEET_SELLER';

// volumes & quebra ranges
export const SET_QUEBRA = 'SET_QUEBRA';
export const SET_VOLUMES_NEGOTIATE = 'SET_VOLUMES_NEGOTIATE';
export const SET_VOLUMES_BENEFIT = 'SET_VOLUMES_BENEFIT';

export const SET_RECORDS = 'SET_RECORDS';
export const SET_INSPECTIONS = 'SET_INSPECTIONS';
export const SET_DOCUMENTS = 'SET_DOCUMENTS';
export const SET_BIORGANIC = 'SET_BIORGANIC';
export const SET_TRACEABILITY = 'SET_TRACEABILITY';
export const SET_QR_LIST = 'SET_QR_LIST';
export const SET_TRACEABILITYV2 = 'SET_TRACEABILITYV2';

export const SET_RECORDS_BY_IDS = 'SET_RECORDS_BY_IDS';
export const SET_RECORDS_BY_IDS_ORIGINAL_STATE = 'SET_RECORDS_BY_IDS_ORIGINAL_STATE';
export const SET_SOURCE = 'SET_SOURCE';
export const SET_REQUEST = 'SET_REQUEST';

// original states
export const SET_RECORDS_ORIGINAL_STATE = 'SET_RECORDS_ORIGINAL_STATE';
export const SET_INSPECTIONS_ORIGINAL_STATE = 'SET_INSPECTIONS_ORIGINAL_STATE';
export const SET_DOCUMENTS_ORIGINAL_STATE = 'SET_DOCUMENTS_ORIGINAL_STATE';
export const SET_BIORGANIC_ORIGINAL_STATE = 'SET_BIORGANIC_ORIGINAL_STATE';
export const SET_TRACEABILITY_ORIGINAL_STATE = 'SET_TRACEABILITY_ORIGINAL_STATE';
export const SET_TRACEABILITYV2_ORIGINAL_STATE = 'SET_TRACEABILITYV2_ORIGINAL_STATE';

export const SET_HEADERS_TRACEABILITY = 'SET_HEADERS_TRACEABILITY';