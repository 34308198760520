import { create } from "ipfs-http-client";

const ipfs = create({
    host: "127.0.0.1",
    port: 5002,
    protocol: "http"
  })

// PINATA CREDENTIALS TEST
const API_KEY = "c054d2a1afbd79486bc7"
const API_SECRET = "c2b61c74c839096e559b68fb447a47d5d1d4393a915578dc7d41bb47212c7930"
const JWT = "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VySW5mb3JtYXRpb24iOnsiaWQiOiI3OGQ3NjhlMi1hMmRiLTQ1ZDgtYmJiMy1kODI2ZTM5NmYxOWUiLCJlbWFpbCI6InNlYW5sdWlzNDdAZ21haWwuY29tIiwiZW1haWxfdmVyaWZpZWQiOnRydWUsInBpbl9wb2xpY3kiOnsicmVnaW9ucyI6W3siaWQiOiJGUkExIiwiZGVzaXJlZFJlcGxpY2F0aW9uQ291bnQiOjF9LHsiaWQiOiJOWUMxIiwiZGVzaXJlZFJlcGxpY2F0aW9uQ291bnQiOjF9XSwidmVyc2lvbiI6MX0sIm1mYV9lbmFibGVkIjpmYWxzZSwic3RhdHVzIjoiQUNUSVZFIn0sImF1dGhlbnRpY2F0aW9uVHlwZSI6InNjb3BlZEtleSIsInNjb3BlZEtleUtleSI6ImMwNTRkMmExYWZiZDc5NDg2YmM3Iiwic2NvcGVkS2V5U2VjcmV0IjoiYzJiNjFjNzRjODM5MDk2ZTU1OWI2OGZiNDQ3YTQ3ZDVkMWQ0MzkzYTkxNTU3OGRjN2Q0MWJiNDcyMTJjNzkzMCIsImlhdCI6MTY2NDgwNjc1OH0.6SyeN8l-a4e5ZFv94YbejFaFQRn9kttIcagIdXwTFIg"

//////////////////////////////
///          IPFS         ///
/////////////////////////////


// Pinata 

const addFilePinata = async () => {
  let axios = require('axios');
  const data = JSON.stringify({
    "pinataOptions": {
      "cidVersion": 0
    },
    "pinataMetadata": {
      "name": "lote xxx-123"
    },
    "pinataContent": {
      "somekey": "somevalue"
    }
  });

  const config = {
    method: 'post',
    url: 'https://api.pinata.cloud/pinning/pinJSONToIPFS',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${JWT}`
    },
    data : data
  };

  const res = await axios(config);
  console.log(res.data);
}


// IPFS Filesystem 

const addFileIPFS = async () => {
  const aJsonString = JSON.stringify({
    name: "my name is Sean1"
  })

  const result = await ipfs.add({
    path: 'xxx-123.txt',
    content: aJsonString
  }, {
    pin: true
  })

  const cid = result.cid.toString()

  console.log(cid)

  for await (const chunk of ipfs.cat(cid)) {
    const string = new TextDecoder().decode(chunk);
    console.log(string)
  }
}

////////////////////////////////////////
///     MFS (Mutable File System)   ///
///////////////////////////////////////

const addFileMFS = async () => {
  const aJsonString = JSON.stringify({ /** DATA **/ })

  await ipfs.files.write('/json/metadata/example.json', aJsonString, { create: true })
}


const makeDir = async (dir) => {
    await ipfs.files.mkdir(`/json/${dir}`, { parents: true })
}


export {
    addFileIPFS,
    addFilePinata,
    addFileMFS,
    makeDir
}