import _ from "lodash";

/**
 * Checks the type of each element in the given array and extracts the specified property from objects.
 *
 * @param {Array} array - The array to check and extract properties from.
 * @param {string} desiredType - The desired type to check against.
 * @param {string} property - The property to extract from objects.
 * @return {Array} An array containing the extracted properties or null if the element is not of the desired type or null.
 */
export const checkTypeAndExtractProperty = (array, desiredType, property) => {
  const result = [];

  array.forEach(element => {
    if (typeof element === desiredType) {
      result.push(element);
    } else if (typeof element === 'object' && element !== null) {
      result.push(element[property]);
    } else {
      result.push(null);
    }
  });

  return result;
}

export const checkSameType = (array, desiredType) => {
  if (array.length === 0) {
    return true;
  }

  return array.every(element => typeof element === desiredType);
}

export const getByProperty = (input, property) => {
  const array = Array.isArray(input) ? input : [input];
  const allAreObjectsWithProperty = array.every(item =>
    typeof item === 'object' && item !== null && Object.hasOwnProperty.call(item, property)
  );

  if (allAreObjectsWithProperty) {
    const result = _.map(array, property);
    return Array.isArray(input) ? result : result[0];
  } else {
    return Array.isArray(input) ? array : input;
  }
}

export const removeFiltersFromLocalStorage = async () => {
  console.log(localStorage.length)
  for (let i = 0; i < localStorage.length; i++) {
    let key = localStorage.key(i);
    if (key.startsWith("filters") && /^[A-Z]/.test(key.substring(7))) {
      localStorage.removeItem(key);
      i--;
    }
  }
}