import * as types from './mutations-type.js';

export default {
  [types.SET_PRODUCERS_LIST](state, data) {
    state.producers = data.content;
    state.totalFromServer = data.totalElements;
  },
  [types.SET_All_PRODUCERS_LIST](state, data) {
    state.producers = data;
  },
  [types.SET_LOADING](state, payload) {
    state.loading = payload;
  },
}
