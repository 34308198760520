import * as types from './mutations-type'
import LanguajeService from "../../../services/LanguajeService";
import i18n from '@/plugins/i18n';
import _ from 'lodash';
import MapService from "../../../services/MapService";
import AuthService from "../../../services/AuthService";

export default {
    loadingON({commit, state}) {
        commit(types.SET_LOADING_ON, true);
    },
    loadingOFF({commit}) {
        commit(types.SET_LOADING_OFF, false);
    },
    setLayout({commit}, layout) {
        commit(types.SET_LAYOUT, layout)
    },
    async getLanguajeKeys({commit}, [toast]) {
        await LanguajeService.languajeKeys()
            .then((resp) => {
                commit(types.GET_LANGUAJE, resp.data);
            })
            .catch(() => {
                toast.error(i18n.t('general.notification.languajeError'), 'ERROR', {
                    queueable: true
                });
            });
    },
    async getLanguageStructure({commit}, [toast]) {
        await LanguajeService.languageStructure()
            .then((resp) => {
                commit(types.LANGUAJE_STRUCTURE, resp.data);
            })
            .catch(() => {
                toast.error(i18n.t('general.notification.languajeError'), 'ERROR', {
                    queueable: true
                });
            });
    },
    async fetchListCountry({commit}) {
        try {
            const {data} = await MapService.countryList();

            for (let key in data) {
                if (key.startsWith('us-')) {
                    delete data[key];
                }
            }

            commit(types.SET_COUNTRY_LIST, data);
        } catch (error) {
            console.error(error)
        }
    },
    async changePass({dispatch, commit}, [dataChange, toast]) {
        dispatch('loadingON');
        try {
            const {data} = await AuthService.changePass(dataChange);
            if (!!data) {
                toast.success(i18n.t('general.notification.changePass'), {
                    queueable: true,
                    color: '#37A98C'
                });
            }
            commit(types.CHANGED, !!data);
            dispatch('loadingOFF');
        } catch (error) {
            if (error.message !== 'cancelRequest')
                toast.error(i18n.t('general.notification.changePassError'), 'ERROR', {
                    queueable: true
                });
        }
    },
    async getInitData({dispatch, commit}, [toast, reload]) {
        const profile = JSON.parse(localStorage.getItem('profile'));

        // Fetch and set integrations for a company
        commit('integration/SET_INTEGRATIONS', profile.integrationsCompany, {root: true})

        dispatch('loadingON');
        console.log('Init Data');
        try {
            const promises = [];
            // add here a list of promises;
            // Only for VOITER COMPANY
            if(profile.company_owner === 'VOITER') {
                promises.push(this.dispatch('reports/fetchContractsNames', [profile, toast]));
                promises.push(this.dispatch('reports/fetchSellersNames', [profile, toast]));
                promises.push(this.dispatch('reports/fetchVolumesN', [profile, toast]));
                promises.push(this.dispatch('reports/fetchVolumesB', [profile, toast]));
                promises.push(this.dispatch('reports/fetchQuebra', [profile, toast]));
            }

            // Fetch Range Score for Inspections
            promises.push(this.dispatch('inspections/fetchRangeScore', [profile.company_id]))
            // Fetch Language Structure
            promises.push(this.dispatch('general/getLanguageStructure', [toast]));
            // Fetch Processes
            promises.push(this.dispatch('proccess/fetchListProccess', [profile, toast]));
            // Fetch Processes with access allowed to the user
            promises.push(this.dispatch('authority/fetchListAuthorityProcess', [profile.id, toast]));
            // Fetch Active Stages
            promises.push(this.dispatch('storage_type/fetchStorageTypesActiveByCompany', [profile, toast]));
            // Fetch All Stages
            promises.push(this.dispatch('storage_type/fetchAllStorageTypesByCompany', [profile.company_id, toast]));
            // Fetch Primary Locations (Locations)
            // promises.push(this.dispatch('locations/fetchListLocations',[profile.company_id, toast]));
            // Fetch Secondary Locations (Storages)
            // promises.push(this.dispatch('storage/fetchListAllStorageByCompany', [profile, toast]));
            // Fetch Document Types
            // promises.push(this.dispatch('document_type/fetchListDocumentTypes', toast));
            // Fetch Document Types By Company
            promises.push(this.dispatch('document_type/fetchListDocumentTypesByCompany', [profile.company_id, toast]));
            // Storage Device by User
            promises.push(this.dispatch('authority/getAuthoritiesStorageDevices', [{
                userId: profile.id,
                companyId: profile.company_id,
                isActive: true
            }, toast]));
            // Locations by User
            promises.push(this.dispatch('authority/getLocationsByAuthority', [{
                userId: profile.id,
                companyId: profile.company_id,
                isActive: true
            }, toast]));
            // Fetch Documents
            promises.push(this.dispatch('documents/fetchListDocuments', [profile, toast]));
            // Fetch default group details (Group Structure)
            promises.push(this.dispatch('groupDetails/fetchGroupStructuresDefault', [toast]));
            // Fetch group details (Group Structure) by company
            promises.push(this.dispatch('groupDetails/fetchGroupStructuresByCompany', [profile, toast]));
            // Fetch details values to Filter by company
            promises.push(this.dispatch('records/fetchListStructures', [profile, toast]));
            // Fetch Users (Person In Charge) by company
            promises.push(this.dispatch('authority/fetchListAuthority', [profile, toast]));
            // Fetch Notifications by user
            promises.push(this.dispatch('notifications/fetchNotificationsMenuByUser', [toast]));
            // Config by User
            promises.push(await this.dispatch('authority/loadConfigUser', [toast]));
            // Fetch StorageDevice classification types
            promises.push(await this.dispatch('storage/fetchClassificationTypes'));
            promises.push(await this.dispatch('locations/fetchClassificationTypes'));
            await Promise.all(promises).finally(() => {
                    if (!reload) {
                        commit(types.GET_INIT_DATA);
                    }
                    dispatch('loadingOFF');
                }
            );

        } catch (error) {
            console.log(error)
            if (error.message === "tokenExpire") {
                localStorage.clear()
                store.dispatch('general/setLayout', 'Login');
                this._vm.$router.push("/login")
                this._vm.$router.go()
            }
        }
    },
}
