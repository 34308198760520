export default {
    storagesByCompanyAndActive: [],
    storagesList: [],
    storagesListForFilters: [],
    storagesTable: [],
    storagesForFilters: [],
    totalFromServer: 0,
    totalFromServerTable: 0,
    totalFromServerForFilters: 0,
    versions: [],
    totalFromServerVersions: 0,
    allStoragesByCompany: [],
    allStorages: [],
    storagesByUserAccess: [],
    classifications: [],
    badgeClassifications: [],
    loading: false,
    mapConfig: {
        sources: [
            {
                id: 'LocationsSource',
                config: {
                    type: 'geojson',
                    data: {type: 'FeatureCollection', features: []}
                }
            },
            {
                id: 'StoragesSource',
                config: {
                    type: 'geojson',
                    data: {type: 'FeatureCollection', features: []}
                }
            }
        ],
        layers: [
            {
                id: 'LocationsLayer',
                typeLayers: 'fill-Location',
                source: 'LocationsSource',
                paint: {}
            },
            {
                id: 'LocationsNamesLayer',
                typeLayers: 'symbol',
                source: 'LocationsSource'
            },
            {
                id: 'LocationsBorderLayer',
                typeLayers: 'line',
                source: 'LocationsSource'
            },
            {
                id: 'StoragesLayer',
                typeLayers: 'fill-Storage',
                source: 'StoragesSource',
                paint: {}
            },
            {
                id: 'StoragesNamesLayer',
                typeLayers: 'symbol',
                source: 'StoragesSource'
            },
            {
                id: 'StoragesBorderLayer',
                typeLayers: 'line',
                source: 'StoragesSource'
            },
        ]
    },

    // ------------ BRAIN AG -----------------

    queryHistory: [],
    latestQueries: {
        cpf: [],
        cnpj: [],
        car: []
    },

    // ---------- EXTRA COLUMNS --------------
    columns: [],
    selectedColumns: [],

    documentTypes: [],

    // ---------- SELECTORS ------------
    sStorageDevices: []
}
