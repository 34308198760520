<template>

</template>

<script>
  import { notificationsMixin } from '@/mixins/notification.mixin'
  import { mapActions, mapGetters } from 'vuex'

  export default {
    name: 'DataWS',
    mixins: [notificationsMixin],

    data: () => ({
      connection: null
    }),

    created() {
      this.subscribeToNotifications()
    },

    computed: {
      ...mapGetters({
        notifications: 'notifications/notifications'
      })
    },

    methods: {
      ...mapActions({
        fetchBrainAgHistory: "storage/fetchBrainAgHistory"
      }),
      create_UUID(sid) {
        return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
          let r = (sid + Math.random() * 16) % 16 | 0
          sid = Math.floor(sid / 16)
          return (c == 'x' ? r : (r & 0x3 | 0x8)).toString(16)
        })
      },
      subscribeToNotifications() {
        /*const eventSource = new EventSource(`http://localhost:5500/v1/notifications/${this.userid}`)

        let notification = null

        eventSource.addEventListener(this.userid, (event) => {
          notification = JSON.parse(event.data)
          if(typeof notification === "object")
            this.$store.commit('notifications/SET_NOTIFICATIONS', [notification, ...this.notifications])

          switch (notification.module) {
            case "BRAIN_AG":
              this.fetchBrainAgHistory([notification.data.storageDevice.id, this.$toast])
              return
          }
        })*/

        window.setInterval(async () => {
          await this.$store.dispatch('notifications/fetchNotificationsMenuByUser', [this.$toast])
        }, 50000);
      },
    },
  }
</script>

<style scoped>

</style>