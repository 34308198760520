<template>
  <div>
    <div id='vue-popup-content' v-if="template==='qr'">
      <div  v-if='content' class="my-1 text-h6 d-flex justify-start align-content-center align-center" >
        <v-icon left small class='mr-1' color='error'>
          mdi-map-marker
        </v-icon>
        <p class="ml-0 ma-0 pa-0 text-subtitle-2 text-start" style="font-family: 'Times New Roman', sans-serif; font-weight: 400;font-size: 14px">
          <span >{{ $t('popupContent.qr.address') }}:</span> <span style="font-weight: bold">{{ isNotExist(content.properties.address) }}</span></p>
      </div>
      <div  v-if='content' class="my-1 text-h6 d-flex justify-start align-content-center align-center" >
        <v-icon left small class='mr-1' color='primary'>
          mdi-latitude
        </v-icon>
        <p class="ml-0 ma-0 pa-0 text-subtitle-2 text-start" style="font-family: 'Times New Roman', sans-serif; font-weight: 400;font-size: 12px">
          <span >{{ $t('popupContent.qr.lat') }}:</span> <span style="font-weight: bold">{{ isNotExist(content.properties.lat) }}</span></p>
      </div>
      <div  v-if='content' class="my-1 text-h6 d-flex justify-start align-content-center align-center" >
        <v-icon left small class='mr-1' color='secondary'>
          mdi-longitude
        </v-icon>
        <p class="ml-0 ma-0 pa-0 text-subtitle-2 text-start" style="font-family: 'Times New Roman', sans-serif; font-weight: 400;font-size: 12px">
          <span >{{ $t('popupContent.qr.long') }}:</span> <span style="font-weight: bold">{{ isNotExist(content.properties.long) }}</span></p>
      </div>
    </div>
    <div id='vue-popup-content' v-if="template==='layers'">
      <div  v-if='content' class="my-1 text-h6 d-flex justify-start align-content-center align-center" >
        <v-icon left small class='mr-1' color='error'>
          mdi-map-marker
        </v-icon>
        <p class="ml-0 ma-0 pa-0 text-subtitle-2 text-start" style="font-family: 'Times New Roman', sans-serif; font-weight: 400;font-size: 14px">
          <span >{{ $t('popupContent.layers.address') }}:</span> <span style="font-weight: bold">{{ isNotExist(content.properties.locationsNames) }}</span></p>
      </div>
      <div  v-if='content' class="my-1 text-h6 d-flex justify-start align-content-center align-center" >
        <v-icon left small class='mr-1' color='primary'>
          mdi-latitude
        </v-icon>
        <p class="ml-0 ma-0 pa-0 text-subtitle-2 text-start" style="font-family: 'Times New Roman', sans-serif; font-weight: 400;font-size: 12px">
          <span >{{ $t('popupContent.layers.lat') }}:</span> <span style="font-weight: bold">{{ isNotExist(content.properties.lat) }}</span></p>
      </div>
      <div  v-if='content' class="my-1 text-h6 d-flex justify-start align-content-center align-center" >
        <v-icon left small class='mr-1' color='secondary'>
          mdi-longitude
        </v-icon>
        <p class="ml-0 ma-0 pa-0 text-subtitle-2 text-start" style="font-family: 'Times New Roman', sans-serif; font-weight: 400;font-size: 12px">
          <span >{{ $t('popupContent.layers.long') }}:</span> <span style="font-weight: bold">{{ isNotExist(content.properties.long) }}</span></p>
      </div>
    </div>
    <div id='vue-popup-content' v-if="template==='simple'">
      <h4>{{ $t('popupContent.simple.title') }}</h4>
      <h5>
        {{ $t('popupContent.simple.subTitle') }}
        <small v-if='content'>{{ content.properties.nomeFazenda }}</small>
      </h5>
      <h5>
        {{ $t('popupContent.simple.area') }}
        <small v-if='content'> {{ content.properties.areaTotal }}</small>
      </h5>
      <h5>
        {{ $t('popupContent.simple.state') }}
        <small v-if='content'> {{ content.properties.state }}</small>
      </h5>
    </div>
    <div v-else-if="template==='monitoring'">
      <div>
        <h4>{{ $t('popupContent.monitoring.title') }}</h4>
        <h5>
          {{ $t('popupContent.monitoring.subTitle') }}
          <small v-if='content'>{{ content.properties.nomeFazenda }}</small>
        </h5>
        <h5>
          {{ $t('popupContent.monitoring.state') }}
          <small v-if='content'> {{ content.properties.state }}</small>
        </h5>
      </div>
      <br>
      <div v-if='tasksData.length !== 0'>
        <v-simple-table dense>
          <template v-slot:default>
            <thead>
            <tr>
              <th class='text-left'>{{ $t('popupContent.monitoring.table.title') }}</th>
              <th class='text-left'>{{ $t('popupContent.monitoring.table.areaPlan') }}</th>
              <th class='text-left'>{{ $t('popupContent.monitoring.table.areaWorked') }}</th>
              <th class='text-left'>{{ $t('popupContent.monitoring.table.process') }} (%)</th>
            </tr>
            </thead>
            <tbody>
            <tr :key='task.title' v-for='task in tasksData'>
              <td>{{ task.title }}</td>
              <td>{{ task.planArea }}({{ task.areaMeasurementUnit.code }})</td>
              <td>{{ task.realArea }}({{ task.areaMeasurementUnit.code }})</td>
              <td>
                <v-chip :color='getColor(task.progress)' dark x-small>{{ task.progress }}%</v-chip>
              </td>
            </tr>
            <tr>
              <td class='bold'><strong>{{ $t('popupContent.monitoring.table.total') }}</strong></td>
              <td class='bold'>{{ content.properties.tasksTotalPlanArea }}</td>
              <td class='bold'>{{ content.properties.tasksTotalRealArea }}</td>
              <td>
                <v-chip :color='getColor(content.properties.tasksTotalPercent)' dark x-small>
                  {{ content.properties.tasksTotalPercent }}%
                </v-chip>
              </td>
            </tr>
            </tbody>
          </template>
        </v-simple-table>
      </div>
    </div>
  </div>
</template>
<script>
  export default {
    name: 'PopupContent',
    props: {
      template: {
        type: String,
        default: 'simple',
      },
      content: {
        type: Object,
        default: null,
      },
    },
    data() {
      return {
        tasksData: [],
      }
    },
    watch: {
      content: 'getTasksData',
    },
    methods: {
      parse(data) {
        return JSON.parse(data)
      },
      getTasksData() {
        if (this.template === 'monitoring') {
          this.tasksData = this.content ? this.parse(this.content.properties.tasks) : []
        }
      },
      getColor(progress) {
        if (progress < 40) {
          return 'red'
        } else if (progress < 85) {
          return 'orange'
        } else {
          return 'green'
        }
      },
      isNotExist(field) {
        if (!field || field.trim === '')
          return '-'

        return field
      },
    },
  }
</script>
<style>
  .mapboxgl-popup h4 {
    font-size: 16px;
    margin: 0;
    font-family: "Open Sans", Arial, Helvetica, Sans-Serif;
    font-weight: 300;
  }

  .mapboxgl-popup h5 {
    font-size: 14px;
    margin: 0;
    font-family: "Open Sans", Arial, Helvetica, Sans-Serif;
    font-weight: 300;
  }

  small {
    font-weight: 400;
    line-height: 1;
    color: #777;
    font-size: 85%;
  }

  .mapboxgl-popup-close-button {
    position: absolute;
    right: 0;
    top: 0;
    border: 0;
    border-radius: 0 3px 0 0;
    cursor: pointer;
    background-color: transparent;
    width: 20px;
  }

  .mapboxgl-popup {
    max-width: 100% !important;
  }
</style>
