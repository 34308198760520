import * as types from './mutations-type.js';

export default {
    [types.SET_ALL_STORAGE_TYPE_LIST](state, data) {
        state.allStoragesTypes = data;
    },
    [types.SET_LOADING_ON](state, data) {
        state.loading = data;
    },
    [types.SET_LOADING_OFF](state, data) {
        state.loading = data;
    }

}
