import { default as http } from './HttpService'

class LogsService {
  constructor() {
  }

  recordById(id) {
    return http.recordLog.get('/api/v1/logs/byRecordId', {
      params: {
        id: id
      }
    });
  }

}

export default new LogsService()
