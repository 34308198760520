export default {
    isEditingMode: false,
    dashboards: [],
    currentLayout: null,
    widgets: [],
    layouts: [],
    layout: {
        id: null,
        name: null,
        selected: false,
        widgets: [],
        isSaved: false,
        user: {
            permissions: [],
            personInChargeId: null
        }
    },
    userPermissions: [],
    drawer: false,
    generalFormat: {
        region: 'en-us',
        afterPoint: 2,
        updated: false
    },
    filters: {
        applyGlobally: false,
        dates: [],
        recordDates: [],
        processes: [],
        primaryLocations: [],
        secondaryLocations: [],
        isGlobal: false,
        stages: [],
        locationType: null,
        classificationTypes: []
    }
}
