import _ from "lodash";
import LanguajeService from "../../../../services/LanguajeService";

export default {
    profile: async () => {
      return await JSON.parse(localStorage.getItem('profile'))
    },
    authority: state => ({
        ...state.authority,
        roles: _.map(state.authority.roles, (itemRol) => {
            return {
                ...itemRol,
                name: LanguajeService.getKey3(itemRol.language_key)
            };
        })
    }),
    authorities: state => _.orderBy(_.map(state.authorities, (item) => {
        return {
            ...item,
            roles: _.map(item.roles, (itemRol) => {
                return {
                    ...itemRol,
                    name: LanguajeService.getKey3(itemRol.language_key)
                };
            })
        };
    }), ['name']),
    authoritiesForLocations: (state, getters) => _.orderBy(_.filter(_.map(state.authorities, (item) => {
        return {
            ...item,
            roles: _.map(item.roles, (itemRol) => {
                return {
                    ...itemRol,
                    name: LanguajeService.getKey3(itemRol.language_key)
                };
            })
        };
    }), (a) => {
        return a.id !== getters.profile.id
    }), ['name']),
    authoritiesLocations: state => _.orderBy(state.authoritiesLocations, 'name'),
    authoritiesStoragesDevices: state => _.orderBy(_.map(state.authoritiesStoragesDevices, (item) => {
        return {
            ...item,
            measurement_unit_name_translate: LanguajeService.getKey3(item.measurementUnit),
            storageDeviceType: {
                ...item.storageDeviceType,
                storage_type_name:  item.storageDeviceType ? LanguajeService.getKey3(item.storageDeviceType) : '-'
            }
        };
    }), ['measurement_unit_name_translate']),
    locations: state => state.locations,
    roles: state => _.map(state.roles, (item) => {
        return {
            ...item,
            name: LanguajeService.getKey3(item.language_key)
        };
    }),
    // authoritiesProcess: state => state.authoritiesProcess,
    authoritiesProcess: state => _.orderBy(_.map(state.authoritiesProcess, (item) => {
        return {
            ...item,
            proccess_name: LanguajeService.getKey3(item.language_key),
            sdt_to_show_name: LanguajeService.getKey3(item.sdt_to_show.language_key)
        };
    }),['proccess_name']),
    authoritiesProcessEdit: state => state.authoritiesProcessEdit,
    loading: state => state.loading,
}
