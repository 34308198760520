<template>
    <v-snackbar
            :timeout="-1"
            :value="notification"
            bottom
            :color="data.color"
            class="mr-2"
            absolute
            multi-line
    >
        <div>
            <div class="d-flex justify-start align-center">
                <v-icon :color="data.color + ' lighten-5'" size="30px">
                    {{ data.icon }}
                </v-icon>
                <div class="d-flex flex-column justify-space-between pl-4 pt-3">
                    <strong>{{ data.message }}</strong>
                    <div class="d-flex justify-space-between align-center">
                        <p class="pr-2">{{ $t('notifications.nomenclator.success') }}: <strong>{{ data.import }}</strong></p>
                        <p class="pr-2">{{ $t('notifications.nomenclator.errors') }}: <strong>{{ data.error }}</strong></p>
                        <p>{{ $t('notifications.nomenclator.total') }}: <strong>{{ data.total }}</strong></p>
                    </div>
                </div>
                <v-btn
                    class="ml-2"
                    text
                    @click="notification = !notification"
                >
                    <v-icon dense size="20" :color="data.color + ' lighten-5'">
                        mdi-close
                    </v-icon>
                </v-btn>
            </div>
            <v-expansion-panels v-if="(data.rowsFailed && Object.keys(data.rowsFailed).length !== 0)" flat light>
                <v-expansion-panel >
                    <v-expansion-panel-header>
                        {{ $t('general.titles.details') }}
                    </v-expansion-panel-header>
                    <v-expansion-panel-content eager v-for="(value, key) in data.rowsFailed" :key="key">
                        <p>{{ $t('notifications.nomenclator.row') }}: <strong>#{{ key }}</strong> - {{ $t(`notifications.nomenclator.errorType.${value}`) }}</p>
                    </v-expansion-panel-content>
                </v-expansion-panel>
            </v-expansion-panels>
        </div>
    </v-snackbar>
</template>

<script>
    export default {
        name: "AlertComponent",
        props: {
            data: {
                type: Object,
                required: false,
            }
        },
        data () {
            return {
                activator: false
            }
        },
        computed: {
            notification: {
                get() {
                    return this.$store.getters['ui/GET_NOTIFICATION'];
                },
                set(val) {
                    this.$store.commit('ui/NOTIFICATION', val);
                }
            },
            nomenclatorAlert: {
                get() {
                    return this.$store.getters['ui/GET_NOMENCLATOR_DATA'];
                },
                set(val) {
                    this.$store.commit('ui/NOMENCLATOR_DATA', val);
                }
            }
        },
    }
</script>

<style scoped>

</style>