import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemGroup } from 'vuetify/lib/components/VList';
import { VListItemSubtitle } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VMenu } from 'vuetify/lib/components/VMenu';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('fragment',[[_c('div',{staticClass:"text-left"},[_c(VMenu,{attrs:{"close-on-content-click":false,"nudge-width":110,"offset-x":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c(VBtn,_vm._g(_vm._b({staticClass:"mt-2 ml-3",attrs:{"fab":"","x-small":"","elevation":"0","tile":""}},'v-btn',attrs,false),on),[_c(VIcon,{attrs:{"color":"black","small":""}},[_vm._v("mdi-map ")])],1)]}}]),model:{value:(_vm.menu),callback:function ($$v) {_vm.menu=$$v},expression:"menu"}},[_c(VCard,[_c(VList,{staticClass:"pb-0 pt-0"},[_c(VListItem,[_c(VListItemContent,{staticClass:"pb-0 pt-0"},[_c(VListItemSubtitle,[_vm._v(" "+_vm._s(_vm.$t('map.titles.styleTile').toUpperCase()))])],1)],1),_c(VDivider),_c(VListItemGroup,{model:{value:(_vm.model),callback:function ($$v) {_vm.model=$$v},expression:"model"}},_vm._l((_vm.items),function(item,i){return _c(VListItem,{key:i,on:{"click":function($event){return _vm.switchLayer(item.value)}}},[_c(VListItemTitle,{staticClass:"pt-0 pb-0",domProps:{"textContent":_vm._s(item.text)}})],1)}),1)],1)],1)],1)],1)]],2)
}
var staticRenderFns = []

export { render, staticRenderFns }