import { client } from '@/services/HttpService'
import AuthService from "@/services/AuthService";
import store from "@/store";
import axios, {Cancel} from "axios";

const http = axios.create({
  baseURL: process.env.VUE_APP_ABRAPA_INTEGRATION,
  timeout: 1000000
});

const request = (request) => {
  let token = localStorage.getItem('token');
  const refresh_token = localStorage.getItem('refresh_token');
  if (token) {
    const time_token = AuthService.parseJwt(token);
    const time_refresh = AuthService.parseJwt(refresh_token);
    const timeLeft_token = AuthService.isTokenExpired(time_token.exp);
    const timeLeft_refresh = AuthService.isTokenExpired(time_refresh.exp);
    if (timeLeft_token < 900 && timeLeft_refresh > 120 && !store.getters['login/refresh']) {
      store.state.login.refresh = true;
      store.dispatch('login/refresh', [refresh_token, token]);
    } else if (timeLeft_refresh <= 120 || timeLeft_token < 120) {
      throw new axios.Cancel('tokenExpire');
    }
    if (!request.url.includes("/v1/profile/refresh_token"))
      request.headers['x-auth-token'] = `${token}`;
  } else {
    if (!location.href.includes('/login')) {
      localStorage.clear()
      location.reload()
    }
  }

  return request;
};

const errorRequest = (error) => Promise.reject(error)

const response = (response) => response

const errorResponse = (error) => {
  if (error instanceof Cancel) {
    if (error.message === 'cancelRequest') {
      return Promise.reject(error);
    } else if (error.message === 'tokenExpire') {
      localStorage.clear()
      location.reload()
    }
  } else {
    if (error.message !== 'Network Error') {
      const {status} = error.response;
      if ([401].includes(status) && store.getters['login/isLoggedIn']) {
        localStorage.clear()
        location.reload()
      } else {
        return Promise.reject(error);
      }
    } else {
      Promise.reject(error);
    }
  }
  return Promise.reject(error);
};

http.interceptors.request.use(request, errorRequest);
http.interceptors.response.use(response, errorResponse);

const createProduct = (payload) => {
  let fd = new FormData()
  fd.append('jsonProduct', JSON.stringify(payload.product))
  fd.append('jsonStage', payload.stage)

  return http.post('/product', fd, {
    headers: { 'Content-Type': 'multipart/form-data' },
  })
}

const createOrder = (payload) => {
  let fd = new FormData()
  fd.append('json_unified', JSON.stringify(payload))

  return http.post('/varejo/unified-orders', fd, {
    headers: { 'Content-Type': 'multipart/form-data' },
  })
}

const fetchStores = () => {
  return client.get('/v1/qr-code/get_redirect_qr_all')
}

const fetchUnits = () => {
  return client.get('/v1/measurement_unit')
}

export default { createProduct, createOrder, fetchStores, fetchUnits }
