export default {
    signatures: [],
    signature: {
        name: '',
        description: '',
        file: null,
        base64: null
    },
    loading: false
}
