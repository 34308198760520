import {default as http} from './HttpService'

class StructuresService {
    constructor() {
    }

    structuresByIds(company, ids) {
        return http.client.post(`v1/structure/structure-values`, {
            structuresIds: ids,
            companyId: company
        });
    }
    searchProductsByLot(company, lot) {
        return http.client.post(`v1/structure/products/structure-values`, {
            companyId: company,
            lotId: lot
        });
    }
    structures() {
        return http.client.get(`v1/structure`);
    }
    structuresLot(filters) {
        return http.client.post(`v1/structure/lotes`, filters);
    }
    structuresByCode() {
        return http.client.get(`v1/structure/code`);
    }
    structuresByCompany() {
        return http.client.get(`v1/company/structures`);
    }
    save(item, editOrSave) {
        if (editOrSave > -1) {
            return http.client.put('/v1/structure', item);
        } else {
            return http.client.post('/v1/structure', item);
        }
    }

    delete(id) {
        return http.client.delete('/v1/structure/' + id);
    }
}

export default new StructuresService()
