import Vue from 'vue';
import Vuex from 'vuex';
// import Modules
import login from '../module/auth/store';
import tracking from '../module/tracking/store';
import final from '../module/final/store';
import authority from '../module/configuration/authority/store';
import roles from '../module/configuration/roles/store';

// Entries
import storage_type from '../module/configuration/storageTypes/store'
import storage from '../module/configuration/storage/store'
import locations from '../module/configuration/location/store'
import units from '../module/configuration/units/store'
import groupunits from '../module/configuration/group-units/store'
import proccess from '../module/configuration/process/store'
import details from '../module/configuration/structures/store'
import groupDetails from '../module/configuration/structures/groupStore'
import company from '../module/configuration/company/store'
import product from '../module/configuration/product/store'
import equipment from '../module/configuration/equipment/store'
import operator from '../module/configuration/operator/store'
import input from '../module/configuration/input/store'
import season from '../module/configuration/season/store'
import notifications from "../module/configuration/notifications/store";
import integration from "../module/configuration/integration/store";
import producer from "../module/configuration/producer/store";

//Records v2
import records from "../module/records/store";

//Records
import labels from "../module/labels/store";

//PreRecords
import prerecords from "../module/prerecords/store";

//Survey
import survey from "../module/configuration/survey/store";

//Inspections
import inspections from "../module/inspections/store";
import signature from "../components/vue-signature"

//Tokens
import web3 from "../module/tokens/store";

//Store
import general from './modules/general';
import ui from '../components/ui';

//Documents
import documents from '../module/documents/store'
import document_type from '../module/configuration/documentTypes/store'
import document_description from '../module/configuration/documentDescription/store'
import document_authority from '../module/configuration/documentAuthority/store'

// Admin
import adminSDT from '../module/agt-admin/storageTypes/store'

// Reports
import reports from '../module/reports/store'

Vue.use(Vuex);

const store = new Vuex.Store({
    modules: {
        namespaced: true,
        login,
        tracking,
        final,
        general,
        ui,
        labels,
        records,
        prerecords,
        inspections,
        web3,
        signature,
        survey,
        storage_type,
        document_type,
        document_description,
        document_authority,
        documents,
        storage,
        units,
        groupunits,
        locations,
        authority,
        roles,
        proccess,
        groupDetails,
        details,
        company,
        product,
        equipment,
        operator,
        input,
        season,
        // Admin
        adminSDT,
        reports,
        notifications,
        integration,
        producer
    }
});

export default store
