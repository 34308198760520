import _ from 'lodash'
import LanguajeService from '../../../../services/LanguajeService'

export default {
  seasons: state =>
    _.map(state.seasons, item => {
      return {
        ...item,
        season_name: LanguajeService.getKey3(item.languageKey),
        product_name: LanguajeService.getKey3(item.product.language_key),
        storage_device_names: item.storage_device_names,
        storage_device_names_list: item.storage_device_names_list.toString(),
        objectRelId: item.id,
      }
    }).sort((a, b) => {
      let nameA = a['season_name']
      let nameB = b['season_name']
      if (!isNaN(nameA.split(' ')[0]) && !isNaN(nameB.split(' ')[0])) {
        return parseInt(nameA.split(' ')[0]) - parseInt(nameB.split(' ')[0])
      }
      return nameA.localeCompare(nameB)
    }),
  seasonsList: state =>
    _.map(state.seasons, item => {
      return {
        ...item,
        season_name: LanguajeService.getKey3(item.languageKey),
        product_name: LanguajeService.getKey3(item.product.language_key),
        storage_device_names: item.storage_device_names,
        storage_device_names_list: item.storage_device_names_list.toString(),
        objectRelId: item.id,
      }
    }),
  totalFromServer: state => state.totalFromServer,
  loading: state => state.loading,
}
