import * as types from './mutations-type.js';

export default {
    [types.SET_STRUCTURE_LIST](state, data) {
        state.details = data;
    },
    [types.SET_STRUCTURE_LIST_CODE](state, data) {
        state.detailsCode = data;
    },
    [types.SET_LOTS](state, data) {
        state.lots = data;
    },
    [types.SET_PRODUCTS](state, data) {
        state.products = data;
    },
    [types.SET_LOADING_ON](state, data) {
        state.loading = data;
    },
    [types.SET_LOADING_OFF](state, data) {
        state.loading = data;
    },

}
