import {default as http} from './HttpService'

class CompanyService {
    constructor() {
    }

    downloadLogo() {
        return http.client.get(`v1/company/logo`, {
            responseType: 'blob',
            headers: {
                Accept: 'application/zip',
            },
        });
    }

    companyById() {
        return http.client.get(`v1/company/get_company`);
    }

    allCompanies() {
        return http.client.get(`v1/company`);
    }

    update(item, logo) {
        let fd = new FormData()
        fd.append('company_json', JSON.stringify(item))
        fd.append('company_logo', logo)

        return http.client.put('/v1/company', fd, {
            headers: { 'Content-Type': 'multipart/form-data' },
        });
    }

    setConfig(data) {
        return http.client.post('/v1/company_configurations', data);
    }

    setDefault(companyIdentity) {
        return http.client.put('/v1/person-in-charge/set-default-company', {
            companyIdentity
        });
    }

    getAvailableCompanies() {
        return http.client.get('/v1/company/available-companies');
    }

    delete() {
        return http.client.delete(`/v1/company/logo`)
    }


}

export default new CompanyService()
