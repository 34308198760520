import * as types from './mutations-type.js';

export default {
    [types.SIGNATURES](state, data) {
        state.sigantures = data;
    },
    [types.SIGNATURE](state, data) {
        state.signature = data;
    },
    [types.LOADING](state, data) {
        state.loading = data;
    },

    [types.SIGNATURE_ORIGINAL_STATE](state) {
        state.signature = {
            name: '',
            description: '',
            file: null,
            base64: null
        };
    },
}
