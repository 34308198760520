import _ from "lodash";
import LanguajeService from "../../../../services/LanguajeService";
import sortList from "../../../../services/OrderListService";
export default {
    inputs: state => sortList.orderListByUppercase(_.map(state.inputs, (item) => {
        return {
            ...item,
            input_name: LanguajeService.getKey3(item.languageKey),
            description_text:  LanguajeService.getKey3(item.description),
            objectRelId: item.id
        };
    }), 'input_name'),
    totalFromServer: state => state.totalFromServer,
    loading: state => state.loading,
}
