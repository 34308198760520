import LanguajeService from "../../../services/LanguajeService";
import _ from "lodash";
import moment from "moment";

export default {
  documents: (state) =>
    _.map(state.documents, (item) => {
      return {
        ...item,
        type_name: LanguajeService.getKey3(item.type),
        description_name: LanguajeService.getKey3(item.description),
      };
    }),
    versions: (state) =>
    _.map(state.versions, (item) => {
      return {
        ...item,
        type_name: LanguajeService.getKey3(item.type),
        description_name: LanguajeService.getKey3(item.description),
      };
    }),
  filterDocuments: (state) =>
    _.map(state.filterDocuments, (item) => {
      return {
        ...item,
        type_name: LanguajeService.getKey3(item.type),
        description_name: LanguajeService.getKey3(item.description),
      };
    }),
  filterDocumentsTotalCount: (state) => state.filterDocumentsTotalCount,
  // Document not expiration to traceability report
  documentsTraceabilityReport: (state) =>
    _.map(
      _.filter(state.documents, (doc) => {
        const today = moment(new Date(), "YYYY-MM-DD").format("YYYY-MM-DD");
        return moment(doc.expiration_date).isSameOrAfter(today);
      }),
      (item) => {
        return {
          ...item,
          type_name: LanguajeService.getKey3(item.type),
          description_name: LanguajeService.getKey3(item.description),
          authority_name: item.authority_name,
        };
      }
    ),
  document: (state) => {
    return {
      ...state.document,
      description: LanguajeService.getKey3(state.description),
      storage_type_name: LanguajeService.getKey3(state.document.language_key),
    };
  },
  storages: (state) => state.storages,
  loading: (state) => state.loading,
  evidences: (state) => state.evidences,
};
