<template>
    <v-card flat>
        <v-card-title>
            {{cert[keyP].name}}
        </v-card-title>
        <v-card-text class="px-5 pa-0 text-left">
            {{cert[keyP].description}}
        </v-card-text>
    </v-card>
</template>

<script>
    export default {
        name: "Certificate",
        props: ['cert','keyP'],
        methods:{
        }
    }
</script>

<style scoped>

</style>
