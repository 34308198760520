import LanguajeService from "../../../../services/LanguajeService";
import sortList from "../../../../services/OrderListService";
import _ from 'lodash';

export default {
    documentTypes: state => sortList.orderListByUppercase(_.map(state.documentTypes, (item) => {
        return {
            ...item,
            document_type_name: LanguajeService.getKey3(item.language_key)
        };
    }), 'document_type_name')
}
