import { default as http } from "./HttpService";

const getContractsByCompany = () => {
  return http.client.get(`/v1/contract-token`)
}

const createContract = (payload) => {
  return http.client.post('/v1/contract-token', payload)
}

const updateContractStatus = (payload) => {
  return http.client.put('/v1/contract-token/change-status', payload)
}

const handleAction = (payload) => {
  return http.client.post(`v1/contract-token/handle-action`, payload)
}

const getStockTokens = () => {
  return http.client.get('/v1/contract-token/get-contract-stock')
}

const getActiveStockTokens = () => {
  return http.client.get('/v1/contract-token/get-contract-stock-active')
}

const getNFTsByContract = (payload) => {
  return http.client.get('/v1/contract-token/get-available', {
    params: {
      action: payload.action,
      contract_id: payload.contractID,
      product: payload.product,
      wallet: window.userAddress
    }
  })
}

const getLogsByAction = (action, contractId) => {
  return http.client.get('/v1/contract-token/get-nft-logs', {
    params: { action, contractId }
  })
}

const getWalletsLogs = (wallet, action, contract) => {
  return http.client.get('/v1/contract-token/get-logs-by-wallet-and-action', {
    params: { wallet, action, contract }
  })
}

const getWalletTotalLogs = (wallet, contract) => {
  return http.client.get('/v1/contract-token/get-totals-by-wallet', {
    params: { wallet, contract }
  })
}

const getProductsByCompany = (companyId) => {
  return http.client.get('/v1/contract-token/products-contract', {
    params: { companyId }
  })
}

const grantRolesToWallet = (role) => {
  return http.client.put(`/v1/wallet-tokens/add-roles`, role)
}

const revokeRolesToWallet = (role) => {
  return http.client.put(`/v1/wallet-tokens/remove-roles`, role)
}

const getWalletsByCompany = () => {
    return http.client.get('/v1/wallet-tokens')
}

const createWallet = (payload) => {
  return http.client.post(`/v1/wallet-tokens`, payload)
}

const updateWallet = (payload) => {
  return http.client.put('/v1/wallet-tokens', payload)
}

const deleteWallet = (payload) => {
  return http.client.delete(`/v1/wallet-tokens/${payload.id}`, payload)
}

const generateReference = (cant) => {
  return http.client.get('/v1/contract-token/generate-ref', {
    params: { cant }
  })
}

const generateSettleReport= (payload) => {
  return http.client.post('/v1/contract-token/report/compensation', {
    language: payload.language,
    orientation: "PORTRAIT",
    logId: payload.logId
  }, {
    responseType: 'blob'
  })
}

const checkSettleReport= (payload) => {
  return http.client.post('/v1/contract-token/report/compensation/check', {
    language: payload.language,
    orientation: "PORTRAIT",
    logId: payload.logId
  })
}

export {
  getContractsByCompany,
  createContract,
  updateContractStatus,
  handleAction,
  getStockTokens,
  getActiveStockTokens,
  getNFTsByContract,
  getLogsByAction,
  getWalletsLogs,
  getWalletTotalLogs,
  getProductsByCompany,
  grantRolesToWallet,
  revokeRolesToWallet,
  getWalletsByCompany,
  createWallet,
  updateWallet,
  deleteWallet,
  generateReference,
  generateSettleReport,
  checkSettleReport
}
