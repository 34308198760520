import i18n from '@/plugins/i18n';
import * as types from './mutations-type.js';
import PreRecordsService from "../../../services/PreRecordsService";
import StorageTypesService from "../../../services/StorageTypesService";
import StructuresService from "../../../services/StructuresService";
import Locations from "@/services/Locations";
import AppointmentsService from "../../../services/AppointmentsService";

export default {
    loadingON({commit, state}) {
        commit(types.SET_LOADING_ON, true);
    },
    loadingOFF({commit}) {
        commit(types.SET_LOADING_OFF, false);
    },
    async findPreRecordById({dispatch, commit, state}, [id, toast]) {
        dispatch('loadingON');
        try {
            const {data} = await PreRecordsService.recordById(id);
            commit(types.SET_PRE_RECORD, data);
            dispatch('loadingOFF');
        } catch (error) {
            if (error.message !== 'cancelRequest')
                toast.error(i18n.t('pre-records.notification.listError'), {
                    queueable: true
                });
        }
    },
    async fetchStorageTypesActiveByCompany({dispatch, commit, state}, [profile, toast]) {
        try {
            const company = profile.company_id;
            const {data} = await StorageTypesService.storageTypesActiveByCompany(company);
            commit(types.SET_STORAGE_TYPE_FOR_RECORD_LIST, data);
        } catch (error) {
            if (error.message !== 'cancelRequest')
                toast.error(i18n.t('storageType.notification.listError'), {
                    queueable: true
                });
        }
    },
    async fetchListAppointmentsByCompanyPaginated({dispatch, commit, state}, [paginationAndFilter, source, toast]) {
        state.recordsByCompany = [];
        try {
            const {data} = await PreRecordsService.appointmentsByCompanyPaginated(paginationAndFilter, source);
            commit(types.SET_APPOINTMENTS_BY_COMPANY_PAGINATED, data);
        } catch (error) {
            if (error.code !== 'ERR_CANCELED')
                toast.error(i18n.t('pre-records.notification.listError'), {
                    queueable: true
                });
        }
    },
    async fetchListPreRecordsByCompanyFiltered({dispatch, commit, state}, [pageableDTO, toast]) {
        dispatch('loadingON');
        try {
            const {data} = await PreRecordsService.preRecordsByCompanyFiltered(pageableDTO);
            commit(types.SET_APPOINTMENTS_BY_COMPANY_FILTERED, data);
            dispatch('loadingOFF');
        } catch (error) {
            if (error.message !== 'cancelRequest')
                toast.error(i18n.t('pre-records.notification.listError'), {
                    queueable: true
                });
        }
    },
    async saveRecord({dispatch, commit, state}, [record, document, documents, editOrSave, toast]) {
        dispatch('loadingON');
        try {
            await PreRecordsService.save(record, document, documents, editOrSave);
            toast.success(i18n.t('pre-records.notification.save'), {
                queueable: true,
                color: '#37A98C'
            });
        } catch (error) {
            if (error.message.includes('406')) {
                toast.error(i18n.t('pre-records.notification.inUse'), {
                    queueable: true,
                    color: 'gray'
                });
            } else {
                if (error.message !== 'cancelRequest')
                    toast.error(i18n.t('pre-records.notification.saveError'), {
                        queueable: true
                    });
            }
        } finally {
            dispatch('loadingOFF');
        }
    },
    async deleteRecord({commit, state}, [id, toast]) {
        try {
            await PreRecordsService.delete(id);
            toast.success(i18n.t('pre-records.notification.deleted'), {
                queueable: true,
                color: '#37A98C'
            });
        } catch (error) {
            if (error.message.includes('406')) {
                toast.error(i18n.t('pre-records.notification.inUse'), {
                    queueable: true,
                    color: 'gray'
                });
            } else {
                if (error.message !== 'cancelRequest')
                    toast.error(i18n.t('pre-records.notification.deletedError'), {
                        queueable: true
                    });
            }
        }
    },
    async fetchListStructures({commit, state}, [profile, toast]) {
        try {
            const company = profile.company_id;
            const {data} = await StructuresService.structuresValues(company);
            commit(types.SET_STRUCTURES_LIST, data);
        } catch (error) {
            if (error.message !== 'cancelRequest')
                toast.error(i18n.t('pre-records.notification.listError'), {
                    queueable: true
                });
        }
    },
    async changeMobileActive({dispatch, commit, state}, [company, id, status, toast]) {
        dispatch('loadingON');
        try {
            await PreRecordsService.changeMobileActive(company, id, status);
        } catch (error) {
            if (error.message.includes('406')) {
                toast.error(i18n.t('pre-records.notification.inUse'), {
                    queueable: true,
                    color: 'gray'
                });
            } else {
                if (error.message !== 'cancelRequest')
                    toast.error(i18n.t('pre-records.notification.saveError'), {
                        queueable: true
                    });
            }
        }
    },
    async fetchListPreRecordsBySearch({dispatch, commit, state}, [filter, source, toast]) {
        state.recordsByCompany = [];
        try {
            const {data} = await PreRecordsService.filterSearch(filter, source);
            commit(types.SET_APPOINTMENTS_BY_COMPANY_PAGINATED, data);
            commit(types.SET_REQUEST, false);
            commit(types.SET_SOURCE, null);
        } catch (error) {
            commit(types.SET_REQUEST, true);
            commit(types.SET_SOURCE, null);
            if (error.message !== 'cancelRequest')
                toast.error(i18n.t('pre-records.notification.listError'), {
                    queueable: true
                });
        }
    },
}
