import i18n from '@/plugins/i18n';
import * as types from './mutations-type.js';
import DocumentAuthorityService from "../../../../services/DocumentAuthorityService";

export default {
    async fetchListDocumentAuthority({commit, state}, [profile, toast]) {
        state.documentAuthorities = [];
        try {
            const company = profile.company_id;
            const {data} = await DocumentAuthorityService.documentAuthorities(company);
            commit(types.SET_DOCUMENT_AUTHORITY_LIST, data);
        } catch (error) {
            if (error.message !== 'cancelRequest')
                toast.error(i18n.t('documentAuthority.notification.listError'), {
                    queueable: true
                });
        }
    },
    async saveDocumentAuthority({commit, state}, [item, editOrSave, toast]) {
        try {
            await DocumentAuthorityService.save(item, editOrSave);
        } catch (error) {
            if (error.message !== 'cancelRequest')
                toast.error(i18n.t('documentAuthority.notification.saveError'), {
                    queueable: true
                });
        }
    },
}
