import axios from 'axios'
import { countryApiURI } from '@/services/HttpService'

const CancelToken = axios.CancelToken
let cancelTokens = {};

export default class MapsServices {
  static fetchAllCountries() {
    if (cancelTokens.fetchAllCountries) cancelTokens.fetchAllCountries();

    return countryApiURI.get(`/countries?lang=pt-BR`, {
      cancelToken: new CancelToken(function executor(c) {
        cancelTokens.fetchAllCountries = c;
      })
    });
  }

  static fetchStatesByCountry (code) {
    if (cancelTokens.fetchStatesByCountry) cancelTokens.fetchStatesByCountry();

    return countryApiURI.get(`/country/${code}/states`, {
      cancelToken: new CancelToken(function executor(c) {
        cancelTokens.fetchStatesByCountry = c;
      })
    })
  }

  static fetchCitiesOfState (country, state) {
    if (cancelTokens.fetchCitiesOfState) cancelTokens.fetchCitiesOfState();

    return countryApiURI.get(`/country/${country}/states/${state}/cities`, {
      cancelToken: new CancelToken(function executor (c) {
        cancelTokens.fetchCitiesOfState = c;
      })
    })
  }
}