import {default as http} from './HttpService'
import _ from "lodash";
import BaseService from "@/services/BaseService";

class StorageService extends BaseService {
  constructor() {
    super()
  }

  storages() {
    return http.client.get(`v1/company/storage-devices`);
  }

  authorizeOrNot(data) {
    return http.client.put(`v1/storage-device/change_status`, data);
  }

  storageVersions(filters) {
    return http.client.post(`v1/storage-device/versions_pageable`, filters);
  }

  storagesFiltered(filters, key) {
    return http.client.post(`v1/storage-device/filter_pageable/extra_columns`, filters);
    // TODO: Improve
    // return this.requestWithCancellation('post', `v1/storage-device/filter_pageable/extra_columns`, filters, key);
  }

  getById(id) {
    return http.client.get(`v1/storage-device/${id}`);
  }

  getBrcarbonData(id) {
    return http.client.get(`v1/hypert/br-carbon-data/${id}`);
  }

  downloadHyperTReport(data) {
    return http.client.post(`v1/hypert/report_v2`, data, {
      responseType: 'blob'
    });
  }

  allStoragesByCompany() {
    return http.client.get(`v1/company/all-storage-devices`);
  }

  allStorages() {
    return http.client.get(`v1/storage-device`);
  }

  changeActiveValue(id_store, status_active) {
    let item = {"storageDeviceId": id_store, "active_value": status_active};
    return http.client.put(`v1/storage-device/change_active_value`, item);
  }

  exportCSV(data) {
    return http.client.post(`v1/storage-device/export/excel`, data, {
      responseType: 'blob'
    });
  }

  save(item, editOrSave, document_logo, logo_file, documents_json, documents_file, badges_file, geobufData) {
    let fd = new FormData()

    fd.append('storageDevice_json', JSON.stringify(item))

    if (logo_file) {
      fd.append('document_logo', JSON.stringify(document_logo))
      fd.append('logo_file', logo_file)
    }

    if(documents_file && documents_file.length > 0) {
      fd.append('document_json', JSON.stringify(documents_json))

      if (documents_file.length > 0) {
        _.forEach(documents_file, (file) => {
          fd.append('documents_file', file);
        });
      }
    }
    if(badges_file && badges_file.length > 0) {
      _.forEach(badges_file, (badgeFile) => {
        fd.append('badges_file', badgeFile);
      });
    }
    if(geobufData){
      const blob = new Blob([geobufData]);
      fd.append('geoData', blob, 'data.pbf');
    }

    if (editOrSave !== null) {
      return http.client.put(`v1/storage-device`, fd, {
        headers: {'Content-Type': 'multipart/form-data'},
      });
    } else {
      return http.client.post(`v1/storage-device`, fd, {
        headers: {'Content-Type': 'multipart/form-data'},
      });
    }
  }

  delete(id) {
    return http.client.delete('/v1/storage-device/' + id);
  }

  checkGln(gln) {
    let item = {"gln": gln}
    return http.client.post('/v1/storage-device/check_gln', item)
  }

  getAccessStoragesByUser(data) {
    return http.client.post('v1/person-in-charge/storage_device_access', data)
  }

  // -------------- BRAIN AG ------------------

  getBrainAgHistory(id) {
    return http.client.get(`v1/brain-ag/history-by-storage-id/${id}`)
  }

  getLatestQueries(id) {
    return http.client.get(`v1/brain-ag/see-latest/${id}`)
  }

  sendBrainAgQuery(data) {
    return http.client.post('v1/brain-ag', {
      documentType: data.type,
      document: data.criteria,
      datasets: data.datasets,
      storageDeviceId: data.storageDeviceId
    })
  }

  getAreaRange() {
    return http.client.get('v1/storage-device/get_area_range')
  }

  fetchTemplate(payload) {
    return http.client.get(`v1/storage-device/template/${payload.language}`, {
      responseType: 'blob',
    })
  }

  import(options) {
    let fd = new FormData()
    fd.append('language', options.language)
    fd.append('file', options.file)

    return http.client.post(`v1/storage-device/excel`, fd, { headers: { 'Content-Type': 'multipart/form-data' } })
  }

  getExtraColumns() {
    return http.client.get('v1/person-in-charge/storage_device/get_extra_columns_configuration')
  }

  setExtraColumns(payload) {
    return http.client.post('v1/person-in-charge/storage_device/save_extra_columns_configuration', payload)
  }

  getClassificationTypes() {
    return http.client.get('v1/location-type/type/secondary')
  }

  getBadgeClassifications() {
    return http.client.get('v1/badge/classifications/secondary')
  }

  verifyCar(payload) {
    return http.client.post('v1/storage-device/check_car', payload)
  }
}

export default new StorageService()
