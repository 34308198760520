<template>
  <v-form ref="lOriginForm" v-model="valid">
    <v-row>
      <v-col cols="6">
        <v-autocomplete
          :ref="locationRef"
          multiple
          :items="displayedLocations"
          :no-data-text="$t('general.fields.noDataAvailable')"
          v-bind="autocompleteProps"
          v-model="locations"
          item-text="name"
          item-value="locationId"
          @update:search-input="handleLocationsSearchChange($event)"
          @change="fetchStorages(null, locations, 'origin')"
          @click:clear="clearLocations('origin', 'origin')"
          :loading="loadings.origin"
          :rules="arraySelectionRules({ min: 0, max: 1000, required: false })"
          :disabled="!(locationData.length > 0) || (loadings.origin || loadings.destination)"
        >
          <template v-slot:label>
            {{ locationLabel }}
          </template>
          <template v-slot:prepend-item>
            <v-list-item @click="() => toggleSelection('locations', 'origin')">
              <v-list-item-action>
                <v-icon>{{ computeSelectionIcon('locations') }}</v-icon>
              </v-list-item-action>
              <v-list-item-content>
                {{ locations.length === locationData.length ? $t('general.titles.deselectAll') : $t('general.titles.selectAll') }}
              </v-list-item-content>
            </v-list-item>
          </template>
          <template v-slot:item="{ item, attrs }">
            <div style="max-width: 180px">
              <v-checkbox
                :input-value="attrs.inputValue"
                color="secondary"
                class="pt-2 pb-2 mt-0"
                :hide-details="true"
              >
                <template v-slot:label>
                  <read-more
                    :classes="'mb-0'"
                    :text="item.name"
                    :max-chars="40"
                    :font-size="14"
                  />
                </template>
              </v-checkbox>
            </div>
          </template>
          <template v-slot:append-item>
            <AFooterPaginatorField
              :items-length="filteredLocations.length"
              :items-per-page="pagination.locations.itemsPerPage"
              :itemPerPageText="false"
              :current-page="pagination.locations.currentPage"
              :valid="true"
              @update:current-page="(e) => updatePagination('locations', { page: e.page, itemsPerPage: e.itemsPerPage })"
              @update:items-per-page="(e) => updatePagination('locations', { page: e.page, itemsPerPage: e.itemsPerPage })"
            />
          </template>
          <template v-slot:selection="{ item, index }">
            <v-chip v-if="index === 0" small>
              <span>{{ item.name | truncate(5) }}</span>
            </v-chip>
            <span style='font-size: 10px !important;' v-if="index === 1" class="grey--text caption"
            >(+{{ locations.length - 1 }}
                        {{ $t('general.titles.others') }})</span
            >
          </template>
        </v-autocomplete>
      </v-col>
      <v-col cols="6">
        <v-autocomplete
          :ref="storageRef"
          multiple
          :items="displayedStorages"
          :no-data-text="$t('general.fields.noDataAvailable')"
          v-bind="autocompleteProps"
          v-model="storageDevices"
          item-text="control_label"
          item-value="storageDeviceId"
          @update:search-input="handleStoragesSearchChange($event)"
          @change="$emit('update:storages:origin', storageDevices)"
          @click:clear="clearLocations('destination', 'origin')"
          :disabled="!(locationData.length > 0 && locations.length > 0) || (loadings.origin || loadings.destination)"
          :loading="loadings.destination"
          :rules="arraySelectionRules({ min: 0, max: 1000, required: false })"
        >
          <template v-slot:label>
            {{ storageLabel }}
          </template>
          <template v-slot:prepend-item>
            <v-list-item @click="() => toggleSelection('storages', 'origin')">
              <v-list-item-action>
                <v-icon>{{ computeSelectionIcon('storages') }}</v-icon>
              </v-list-item-action>
              <v-list-item-content>
                {{ storageDevices.length === storageData.length ? $t('general.titles.deselectAll') : $t('general.titles.selectAll') }}
              </v-list-item-content>
            </v-list-item>
          </template>
          <template v-slot:item="{ item, attrs }">
            <div style="max-width: 180px">
              <v-checkbox
                :input-value="attrs.inputValue"
                color="secondary"
                class="pt-2 pb-2 mt-0"
                :hide-details="true"
              >
                <template v-slot:label>
                  <read-more
                    :classes="'mb-0'"
                    :text="item.control_label"
                    :max-chars="40"
                    :font-size="14"
                  />
                </template>
              </v-checkbox>
            </div>
          </template>
          <template v-slot:append-item>
            <AFooterPaginatorField
              :items-length="filteredStorages.length"
              :items-per-page="pagination.storages.itemsPerPage"
              :itemPerPageText="false"
              :current-page="pagination.storages.currentPage"
              :valid="true"
              @update:current-page="(e) => updatePagination('storages', { page: e.page, itemsPerPage: e.itemsPerPage })"
              @update:items-per-page="(e) => updatePagination('storages', { page: e.page, itemsPerPage: e.itemsPerPage })"
            />
          </template>
          <template v-slot:selection="{ item, index }">
            <v-chip v-if="index === 0" small>
              <span>{{ item.control_label | truncate(5) }}</span>
            </v-chip>
            <span style='font-size: 10px !important;' v-if="index === 1" class="grey--text caption"
            >(+{{ storageDevices.length - 1 }}
                        {{ $t('general.titles.others') }})</span
            >
          </template>
        </v-autocomplete>
      </v-col>
    </v-row>
  </v-form>

</template>

<script>
  import ReadMore from "@/components/ReadMoreComponent.vue"
  import AFooterPaginatorField from "@/components/common/AFooterPaginatorField.vue";
  import {CommonLocationFilterMixin} from "@/components/filters/mixins/common.mixin";

  export default {
    components: {AFooterPaginatorField, ReadMore },
    mixins: [CommonLocationFilterMixin],

    async mounted() {
      this.$root.$on("set:location:origin", async (payload, fetchStorages = true) => {
        console.log('fetching >>> set:location:origin', payload)

        this.$set(this, "locations", payload.locations)
        if (fetchStorages) {
          await this.fetchStorages(null, this.locations, 'origin')
        }
        this.$set(this, "storageDevices", payload.storageDevice)
      })
    },

    beforeDestroy() {
      console.log('beforeDestroy >>> set:location:origin')
      this.$root.$off("set:location:origin")
    }
  }
</script>

<style scoped></style>
