<template>
  <v-container fluid class="px-0 pt-0 pb-0">
        <div>
          <map-box
            :displayDrawControlsDefault="false"
            :drawControl="false"
            :drawControls="controls"
            :geolocateControl="false"
            :mapConfiguration="mapConfig"
            :navigationControl="false"
            :oneLocation="true"
            :scaleControl="false"
            :searchControl="false"
            :fullScreenControl="true"
            ref="mapLocations"
            :confStyle="'satellite-v9'"
            v-on:loadComplete="initialize"
            :mapHeight="45"
          />
        </div>
  </v-container>
</template>

<script>
  import MapBox from '@/components/vueMapbox/MapBox.vue'
  import { mapActions, mapGetters } from 'vuex'
  import { convertLatitude, convertLongitude, isValidLatLong } from '../../../../utils/convert-to-DMS-format'

  export default {
    name: "QrLabel",
    components: {
      MapBox,
    },
    data: () => ({

      // draw controls
      controls: {},
      popUpData: {
        address: null,
        lat: null,
        long: null
      },
    }),
    created() {
      this.qrlabel();
    },
    computed: {
      recordSelected: {
        get() {
          return this.$store.getters["labels/recordTrackindData"];
        },
        set(val) {
          this.$store.commit("labels/SET_RECORD_TRACKING_DATA", val);
        },
      },
      ...mapGetters({
        mapConfig: "general/mapConfigFinal",
      }),
    },
    methods: {
      ...mapActions({
        qrlabel: "labels/qrlabel",
      }),
      initialize() {
        let allFeatures = {
          type: "FeatureCollection",
          features: [],
        };
        let location1 = null;
        console.log()
        if (
          this.recordSelected.record.sdOrigin.storageDeviceVersion.location !== "null" &&
          this.IsJsonString(this.recordSelected.record.sdOrigin.storageDeviceVersion.location)
        ) {
          location1 = JSON.parse(
            this.recordSelected.record.sdOrigin.storageDeviceVersion.location
          );
          if (location1) {
            if (location1.features && location1.features[0] && location1.features[0].geometry.type === 'Point') {
              location1 = location1.features[0]
            } else {
              const center = this.$refs.mapLocations.getCenter(location1)
              location1 = {
                type: 'Feature',
                geometry: {
                  type: 'Point',
                  coordinates: center,
                },
                properties: {},
              }
            }
            location1.properties.name =
              this.recordSelected.record.sdOrigin.storageDeviceVersion.control_label
            location1.properties.address1 =
              this.recordSelected.record.sdOrigin.storageDeviceVersion.address1
            location1.properties.city =
              this.recordSelected.record.sdOrigin.storageDeviceVersion.city
            location1.properties.state =
              this.recordSelected.record.sdOrigin.storageDeviceVersion.state
            location1.properties.country =
              this.recordSelected.record.sdOrigin.storageDeviceVersion.country
            location1.properties.title = 'records.fields.origin'
            if (isValidLatLong(location1.geometry.coordinates[1], true) && isValidLatLong(location1.geometry.coordinates[0], false)) {
              location1.properties.lat = convertLatitude(location1.geometry.coordinates[1]);
              location1.properties.long = convertLongitude(location1.geometry.coordinates[0]);
            } else {
              location1.properties.lat = null
              location1.properties.long = null
            }
            location1.properties.address = this.setPopUpData(this.recordSelected);
            allFeatures.features.push(location1)
          }

        }
        if (allFeatures && allFeatures.features.length > 0) {
          this.$refs.mapLocations.updateSource(
            "TrackingSourceAdmin",
            allFeatures
          );
          this.$refs.mapLocations.onMouseClickSelectTrackingLayer("points");
          this.$refs.mapLocations.setMapZoom(14);
          this.$refs.mapLocations.zoomToFitBounds(allFeatures, 100);
          this.$refs.mapLocations.setMapZoom(18);
          this.$refs.mapLocations.onMouseHoverPopUp(["points"], "pointer", "qr");
          this.$refs.mapLocations.onMouseLeavePopUp(["points"]);
        }
      },
      /**
       * @return {boolean}
       */
      IsJsonString(json) {
        try {
          JSON.parse(json);
        } catch (e) {
          return false;
        }
        return true;
      },
      setPopUpData(recordSelected) {
        let origin = recordSelected.record.sdOrigin.storageDeviceVersion
        return this.checkIfExist(
            origin.address1,
            true,
            ', ',
          ) +
          this.checkIfExist(
            origin.city,
            true,
            ', ',
          ) +
          this.checkIfExist(
            origin.state,
            true,
            ', ',
          ) +
          this.checkIfExist(origin.country, false)
      },
      checkIfExist(target, continueTrue, separator) {
        target = target ? target : ''
        continueTrue = continueTrue && target !== '' ? separator : ''
        return target + continueTrue
      },
    },
  };
</script>

<style scoped>

</style>