export default {
    inspection: {
        id: null,
        name: "",
        data: new Date().toJSON(),
        company: null,
        isTemplate: true,
        questionSectionList: [],
        locations: []
    },
    score: [0,100],
    question: {},
    uploadProgress: null,
    loading: true,
    inspectionList: [],
    recordsByInspectionsTotalCount: 0,
    inspectionToExports: [],
    error: false,
    cantToExport: 0,
    blob: null,
}
