<template>
  <v-data-footer
    :items-per-page-text="itemPerPageText ? $t('general.table.itemsPerPageText') : ''"
    :page-text="'{0}-{1} ' + $t('general.table.pageText') + ' {2}'"
    v-if="itemsLength > itemsPerPage"
    :options="paginationOptions"
    :pagination="paginationInfo"
    @update:options="handleUpdateOptions"
    :items-per-page-options="itemPerPageText ? itemsPerPageOptions : []"
    :disable-pagination="!valid"
  ></v-data-footer>
</template>

<script>
export default {
  name: "AFooterPaginatorField",

  props: {
    itemsLength: {
      type: Number,
      required: true,
    },
    valid: {
      type: Boolean,
      required: true,
    },
    currentPage: {
      type: Number,
      required: true,
    },
    itemsPerPage: {
      type: Number,
      required: true,
    },
    itemPerPageText: {
      type: Boolean,
      default: true,
    },
  },

  data() {
    return {
      itemsPerPageOptions: [5, 10, 20, 50], // Puedes ajustar esto como desees
    };
  },

  computed: {
    paginationOptions() {
      return {
        page: this.currentPage,
        itemsPerPage: this.itemsPerPage,
        itemsPerPageOptions: this.itemsPerPageOptions,
        showFirstLastPage: true,
      };
    },
    paginationInfo() {
      const pageStart = this.currentPage - 1;
      const pageStop = Math.min(this.currentPage * this.itemsPerPage, this.itemsLength);
      return {
        page: this.currentPage,
        itemsPerPage: this.itemsPerPage,
        itemsLength: this.itemsLength,
        pageStart: this.itemsLength > 0 ? pageStart : 0,
        pageStop: this.itemsLength > 0 ? pageStop : 0,
      };
    },
  },

  methods: {
    handleUpdateOptions(options) {
      this.$emit('update:current-page', {page: options.page, itemsPerPage: options.itemsPerPage});
      if (options.itemsPerPage) {
        this.$emit('update:items-per-page', {page: options.page, itemsPerPage: options.itemsPerPage});
      }
    }
  },
}
</script>

<style scoped>
.v-data-footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 16px;
}
</style>
