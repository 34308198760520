import { VBtn } from 'vuetify/lib/components/VBtn';
import { VChip } from 'vuetify/lib/components/VChip';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemAction } from 'vuetify/lib/components/VList';
import { VListItemAvatar } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemIcon } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VListItem,{key:_vm.index},[(_vm.pIcon)?_c(VListItemAvatar,[_c(VIcon,{attrs:{"color":_vm.pColor}},[_vm._v(" "+_vm._s(_vm.pIcon)+" ")])],1):_vm._e(),_c(VListItemIcon,{staticClass:"fixed-width"},[_c(VChip,{attrs:{"small":"","disabled":""}},[_vm._v(" "+_vm._s(_vm.getTranslationModule(_vm.notification.module))+" ")])],1),_c(VListItemContent,{staticClass:"d-flex justify-start"},[_c('p',{staticClass:"ma-0 pa-0 my-1 black--text",staticStyle:{"font-size":"14px"}},[_vm._v(" "+_vm._s(_vm.$t('general.notification.exportSuccess'))+" ")]),_c('br'),_c(VListItemTitle,{staticClass:"d-block"},[_c('p',{staticClass:"ma-0 pa-0 my-1",staticStyle:{"font-size":"12px","color":"#0f0d0d","text-align":"start"}},[_vm._v(_vm._s(_vm.getDateFormat(_vm.notification.dateTime)))])])],1),(_vm.withAction)?_c(VListItemAction,{staticClass:"d-inline justify-space-between align-center align-content-center"},[_c(VBtn,{staticClass:"mx-1",attrs:{"fab":"","x-small":"","color":"grey lighten-4","elevation":"0","loading":_vm.loadings.download || _vm.loading},on:{"click":function($event){return _vm.downloadFileFromS3(_vm.notification)}},scopedSlots:_vm._u([{key:"loader",fn:function(){return [_c(VProgressCircular,{attrs:{"indeterminate":"","size":"12","width":"2"}})]},proxy:true}],null,false,3385518082)},[_c(VIcon,{attrs:{"color":"secondary","small":""}},[_vm._v(" mdi-download-multiple ")])],1),(!_vm.notification.watched)?_c(VBtn,{attrs:{"fab":"","x-small":"","color":"grey lighten-4","elevation":"0","loading":_vm.loadings.read || _vm.loading},on:{"click":function($event){return _vm.$emit('mark:read')}},scopedSlots:_vm._u([{key:"loader",fn:function(){return [_c(VProgressCircular,{attrs:{"indeterminate":"","size":"12","width":"2"}})]},proxy:true}],null,false,3385518082)},[_c(VIcon,{attrs:{"color":"secondary","small":""}},[_vm._v(" mdi-check ")])],1):_vm._e()],1):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }