import _ from "lodash";
import {SYNC_INFO} from "@/module/tokens/store/mutations-type";

export default {
  GET_WEB3: state => state.web3,
  GET_WALLET: state => state.wallet,
  GET_WALLETS: state => state.wallets,
  GET_IS_SYNC: state => state.isSync,
  GET_SYNC_INFO: state => state.syncInfo,
  GET_FACTORY_OWNER: state => state.isFactoryOwner,
  GET_PRODUCTS: state => state.products,
  GET_ALL_PRODUCTS: state => state.products,
  GET_FACTORY: state => state.factory,
  GET_TOKEN_LIST: state => state.tokenList,
  GET_CONTRACTS_LIST: state => state.contractsList,
  GET_CONTRACTS_JSON_LIST: state => state.contractsJsonList,
  GET_TOKEN_SEND: state => _.map(state.token.send, (s) => {
    return {
      send_by: s.to, quantidade: s.amount, data: s.timestamp
    }
  }),
  GET_TOKEN_RECEIVER: state => _.map(state.token.receiver, (r) => {
    return {
      received_for: r.from, quantidade: r.amount, data: r.timestamp
    }
  }),
  GET_TOKEN_SETTLE: state => _.map(state.token.settle, (t) => {
    return {
      quantidade: t.amount, data: t.timestamp, user: t.user
    }
  }),
  GET_TOKEN_AMOUNT: state => state.amount,
  GET_WALLET_TRACKING: state => state.tracking,
  GET_ROLES: state => state.roles,
  GET_HEADER: state => state.headers,
  GET_LOADINGS: state => state.loadings,
  GET_TOTAL_MINTED: () => (lots) => {
    let total = 0;
    if (lots && lots.length > 0) {
      for (let i = 0; i < lots.length; i++) {
        const lot = lots[i];
        total += parseInt(lot.amount);
      }
    }
    return total;
  },
  GET_CONTRACT_HISTORY: state => state.contractHistory,
  GET_LOTS: state => state.lots
}
