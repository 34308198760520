import * as types from './mutations-type.js';

export default {
    [types.SET_COMPANY](state, data) {
        state.companyById = data;
    },
    [types.SET_DEFAULT_COMPANY](state, data) {
        state.defaultCompany = data;
        localStorage.setItem('company:current', JSON.stringify(data));
    },
    [types.SET_ALL_COMPANY](state, data) {
        state.allCompanies = data;
    },
    [types.SET_USER_COMPANIES](state, data) {
        state.userCompanies = data;
    },
    [types.SET_SELECTED](state, data) {
        state.selected = data;
    },
    [types.SET_LOADING_ON](state, data) {
        state.loading = data;
    },
    [types.SET_LOADING_OFF](state, data) {
        state.loading = data;
    }

}
