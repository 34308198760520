import Cookies from 'js-cookie';

export const notificationsMixin = {
  props: {
    webSocketUrl: {
      type: String
    },
    webSocketReconnectMaxCount: {
      type: Number,
      default: 5
    },
    WSResEncode: {
      type: Function,
      default: function (data) {
        return JSON.stringify(data);
      }
    },
    WSResDecode: {
      type: Function,
      default: function (data) {
        return JSON.parse(data);
      }
    },
    userid: {
      type: String,
      required: true
    }
  },

  data: () => ({
    webSocket: null,
    webSocketReconnectCount: 0,
    webSocketIsReconnect: true,
    webSocketWarningText: "Msg Warning",
    webSocketIsOpen: false,
    webSocketPingTimer: null,
    webSocketPingTime: 10000
  }),

  methods: {
    setUid(value) {
      Cookies.set("UID", value, {
        expires: 365,
        path: "/"
      });
    },
    getUid() {
      let uid = Cookies.get("UID", {
        path: "/"
      });
      if (this.isEmpty(uid)) {
        return null;
      }
      return uid;
    },
    delUid() {
      Cookies.remove("UID", {
        path: "/"
      });
    },
    setSid(value) {
      Cookies.set("SID", value, {
        expires: 365,
        path: "/"
      });
    },
    getSid() {
      let sid = Cookies.get("SID", {
        path: "/"
      });
      if (this.isEmpty(sid)) {
        return null;
      }
      return sid;
    },
    delSid() {
      Cookies.remove("SID", {
        path: "/"
      });
    },
    setLocalStorage(name, value) {
      localStorage.setItem(name, value);
    },
    getLocalStorage(name) {
      return localStorage.getItem(name);
    },
    webSocketClose() {
      this.webSocketIsOpen = false;
      this.webSocket = null;
      if (
        this.webSocketIsReconnect &&
        this.webSocketReconnectCount === 0
      ) {
        this.webSocketReconnect();
      }
    },
    webSocketPing() {
      this.webSocketPingTimer = setTimeout(() => {
        if (!this.webSocketIsOpen) {
          return false;
        }
        const payload = {
          type: 0
        };
        this.webSocketSend(payload);
        clearTimeout(this.webSocketPingTimer);
        this.webSocketPing();
      }, this.webSocketPingTime);
    },
    webSocketSend(payload) {
      payload.uid = parseInt(this.getUid());
      payload.sid = this.getSid();
      let buffer = this.WSResEncode(payload);
      this.webSocket.send(buffer);
    },
    webSocketInit() {
      this.webSocketWarningText = "Init WebSocket";
      this.webSocketIsReconnect = true;
      this.webSocket = new WebSocket(this.webSocketUrl);

      this.webSocket.onopen = this.webSocketHandleOpen;
      this.webSocket.onerror = this.webSocketHandleError;
      this.webSocket.onmessage = this.webSocketHandleMessage;
      this.webSocket.onclose = this.webSocketHandleClose;

      console.log(this.webSocket)
    },
    // WebSocket Reconnection
    webSocketReconnect() {
      if (this.webSocketIsOpen) {
        return false;
      }
      this.webSocketReconnectCount += 1;
      // Determine whether the maximum number of reconnections has been reached
      if (
        this.webSocketReconnectCount >= this.webSocketReconnectMaxCount
      ) {
        this.webSocketWarningText = "Reconnection times exceeded";
        return false;
      }
      // Initialization
      this.webSocketInit();
      // Every 5 Try once per second to check if the connection is successful until the maximum number of reconnections is exceeded
      let timer = setTimeout(() => {
        this.webSocketReconnect();
        clearTimeout(timer);
      }, 5000);
    },
    // WebSocket After opening successfully
    webSocketHandleOpen() {
      console.log("Connection open");
      this.webSocketIsOpen = true;
      // Clear the number of reconnections
      this.webSocketReconnectCount = 0;
      // Send login information
      const payload = {
        type: 1
      };
      this.webSocketSend(payload);
      // Turn on timed heartbeat
      this.webSocketPing();
    },
    // WebSocket Closure
    webSocketHandleClose() {
      console.log("Disconnect");
      // Turn off heartbeat
      this.webSocketClose();
    },
    // WebSocket When an error occurs
    webSocketHandleError(err) {
      console.log("Connection error：", err);
      // Turn off heartbeat
      this.webSocketClose();
    },
    // When a message is received
    webSocketHandleMessage(event) {
      // Response body message
      const data = event.data;
      this.WSResDecode(data, response => {
        console.log("response:", response);
        let type = response.type || 0;
        switch (type) {
          case -2:
            break;
          case -1:
            this.wsOut();
            break;
          case 1:
            break;
          case 2:
            break;
          case 5:
            break;
          case 3:
            break;
          case 4:
            break;
        }
      });
    },
    wsOut() {
      this.webSocketWarningText = "Log in from another place, please log in again";
      // Modify the reconnection state
      this.webSocketIsReconnect = false;
      if (this.webSocket) {
        console.log("关闭websocket");
        // Close websocket
        this.webSocket.close();
      }
    },
  }
}