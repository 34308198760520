import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCheckbox } from 'vuetify/lib/components/VCheckbox';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemAction } from 'vuetify/lib/components/VList';
import { VListItemAvatar } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VMenu } from 'vuetify/lib/components/VMenu';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('fragment',[[_c('div',{staticClass:"text-left"},[_c(VMenu,{attrs:{"close-on-content-click":false,"nudge-width":150,"offset-x":"","max-height":"350px"},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c(VBtn,_vm._g(_vm._b({staticClass:"mt-2 ml-3",attrs:{"dark":"","fab":"","x-small":"","elevation":"0","tile":"","color":_vm.cantFilters>0 ?'secondary':'white'}},'v-btn',attrs,false),on),[_c(VIcon,{attrs:{"color":_vm.cantFilters>0 ?'white':'black'}},[_vm._v("mdi-filter-variant ")])],1)]}}]),model:{value:(_vm.menu),callback:function ($$v) {_vm.menu=$$v},expression:"menu"}},[_c(VCard,[_c(VList,{staticClass:"pb-0 pt-0"},[_c(VListItem,[_c(VListItemContent,{staticClass:"pb-0 pt-0"},[_c(VListItemTitle,[_vm._v(" "+_vm._s(_vm.$t('general.buttons.filter'))+" ")])],1),_c(VListItemAvatar,{staticClass:"ml-5"},[_c(VIcon,{staticClass:"ml-3",attrs:{"color":"secondary"},on:{"click":function($event){return _vm.filterAllMap(true)}}},[_vm._v(" mdi-checkbox-multiple-marked-outline ")])],1),_c(VListItemAvatar,{staticClass:"ml-0"},[_c(VIcon,{staticClass:"mb-1",attrs:{"color":"error"},on:{"click":function($event){return _vm.filterAllMap(false)}}},[_vm._v(" mdi-filter-variant-remove ")])],1)],1)],1),_c(VDivider),_c(VList,{attrs:{"dense":""}},_vm._l((_vm.stages),function(stage,index){return _c('div',{key:index},[_c(VListItem,[_c(VListItemAction,{staticClass:"ml-2",on:{"click":function($event){return _vm.filterMap()}}},[_c(VCheckbox,{model:{value:(stage.checked),callback:function ($$v) {_vm.$set(stage, "checked", $$v)},expression:"stage.checked"}})],1),_c(VListItemContent,[_c(VListItemTitle,[_vm._v(_vm._s(stage.storage_type_name)+" ")])],1)],1)],1)}),0)],1)],1)],1)]],2)
}
var staticRenderFns = []

export { render, staticRenderFns }