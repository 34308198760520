import {default as http} from './HttpService'

class GroupStructuresService {
    constructor() {
    }

    allGroupStructures() {
        return http.client.get(`v1/structure-group`);
    }
    groupStructuresByCompany() {
        return http.client.get(`v1/company/group-structures`);
    }
    groupStructuresDefault() {
        return http.client.get(`v1/company/default-groups`);
    }
    save(item, editOrSave) {
        if (editOrSave > -1) {
            return http.client.put('/v1/structure-group', item);
        } else {
            return http.client.post('/v1/structure-group', item);
        }
    }

    delete(id) {
        return http.client.delete('/v1/structure-group/' + id);
    }
}

export default new GroupStructuresService()
