import * as types from './mutations-type.js';

export default {
    [types.FINAL](state, data) {
        this.dispatch('general/setLayout', 'Final');
    },
    [types.SET_LOADING_ON](state, data) {
        state.loading = data;
    },
    [types.SET_LOADING_OFF](state, data) {
        state.loading = data;
    },
    [types.SET_RECORD_TRACKING_DATA](state, data) {
        state.recordTrackindData = data;
    },
}
