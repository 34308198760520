class OrderListService {
    constructor() {
    }

    orderNumbersAndLettersList(list) {
        let newList = [...list];
        return newList.sort((a, b) => {
            let nameA = a['name']
            let nameB = b['name']
            if (!isNaN(nameA.split(' ')[0]) && !isNaN(nameB.split(' ')[0])) {
                return (
                    parseInt(nameA.split(' ')[0]) - parseInt(nameB.split(' ')[0])
                )
            }
            return nameA.localeCompare(nameB)
        })
    }

    orderListByUppercase(list, value) {
        let newList = [...list];
        return newList.sort((a, b) => a && b && a[value] && b[value] ? a[value].trim().localeCompare(b[value].trim(), 'en-US-u-kf-upper') : []);
    }

    orderListByUppercaseRole(list) {
        let newList = [...list];
        return newList.sort((a, b) => a && b ? a.trim().localeCompare(b.trim(), 'en-US-u-kf-upper') : []);
    }

    orderListByLowercase(list, value) {
        let newList = [...list];
        return newList.sort((a, b) => a[value].localeCompare(b[value]));
    }

    orderListRemoveAccent(list, value) {
        const removeAccents = (str) => {
            return str.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
        }
        let newList = [...list];
        newList.forEach(element => {
            element[value] = removeAccents(element[value]);
        })
        newList = newList.sort((a, b) => a[value].localeCompare(b[value]));
        newList.forEach(item => {
            item[value] = list[list.map(element.id).indexOf(item.id)][value]
        })
        return newList
    }

    customSort(a, b) {
        for (let i = 0; i < Math.min(a.length, b.length); i++) {
            if (a[i] !== b[i]) {
                const classA = this.charClass(a[i]);
                const classB = this.charClass(b[i]);

                // Si los caracteres son de diferentes clases, ordena por clase
                if (classA !== classB) return classA - classB;

                // Si ambos caracteres son números, compara sus valores
                if (classA === 3) return parseInt(a[i]) - parseInt(b[i]);

                // Si son del mismo tipo, compara lexicográficamente
                return a[i].localeCompare(b[i]);
            }
        }
        // Si una cadena es un prefijo de la otra, ordena por longitud
        return a.length - b.length;
    }

    charClass(ch) {
        if (ch === ' ') return 1;
        if (/[0-9]/.test(ch)) return 3;
        if (/[a-zA-Z]/.test(ch)) return 4;
        return 2;  // Para cualquier otro carácter
    }
}

export default new OrderListService()
