export default {
    SET_SIGNATURES({commit}, payload) {
        commit('SIGNATURES', payload)
    },
    SET_SIGNATURE({commit}, payload) {
        commit('SIGNATURE', payload)
    },
    SET_LOADING({commit}, payload) {
        commit('LOADING', payload)
    },
}
