import * as types from './mutations-type.js';

export default {
  [types.SET_NOTIFICATIONS_MENU](state, data) {
    state.notificationsMenu = data.notifications;
    state.totalFromServerMenu = data.total;
  },

  [types.SET_NOTIFICATIONS](state, payload) {
    state.notifications = payload.content;
    state.totalFromServer = payload.totalElements;
    state.watched = payload.totalWatched;
    state.unwatched = payload.totalNotWatched;
  },

  [types.RESTORE_NOTIFICATIONS](state, data) {
    state.notifications = data;
  }
}
