import * as types from './mutations-type.js';

export default {
    [types.TRACKING_QR](state, data) {
        this.dispatch('general/setLayout', 'Tracking');
    },
    [types.SET_TRACKING_DATA](state, data) {
        state.trackingData = data;
    },
    [types.SET_LOADING_ON](state, data) {
        state.loading = data;
    },
    [types.SET_LOADING_OFF](state, data) {
        state.loading = data;
    },
}
