export const SET_LOCATIONS_LIST = 'SET_LOCATIONS_LIST';
export const SET_S_LOCATIONS = 'SET_S_LOCATIONS';
export const SET_LOCATIONS_LIST_FOR_FILTERS = 'SET_LOCATIONS_LIST_FOR_FILTERS';
export const RESTORE_LOCATIONS_LIST = 'RESTORE_LOCATIONS_LIST';
export const SET_ACTIVE_LOCATIONS_LIST = 'SET_ACTIVE_LOCATIONS_LIST';
export const RESTORE_LOCATIONS_FOR_FILTERS = 'RESTORE_LOCATIONS_FOR_FILTERS';
export const SET_PERSONS_IN_CHARGE = 'SET_PERSONS_IN_CHARGE';
export const RESTORE_PERSONS_IN_CHARGE = 'RESTORE_PERSONS_IN_CHARGE';
export const SET_LOCATION_BY_USER_ACCESS = 'SET_LOCATION_BY_USER_ACCESS';
export const RESTORE_LOCATION_BY_USER_ACCESS = 'RESTORE_LOCATION_BY_USER_ACCESS';
export const SET_LOADING_ON = 'SET_LOADING_ON';
export const SET_LOADING_OFF = 'SET_LOADING_OFF';
export const SET_MAP_CONFIG = 'SET_MAP_CONFIG';
export const SET_VERSIONS_LIST = 'SET_VERSIONS_LIST';
export const RESTORE_VERSIONS_DATA = 'RESTORE_VERSIONS_DATA';
export const SET_LOCATIONS_FOR_FILTERS = 'SET_LOCATIONS_FOR_FILTERS';
export const SET_SELECTED_COLUMNS = 'SET_SELECTED_COLUMNS';
export const SET_CLASSIFICATIONS = 'SET_CLASSIFICATIONS';
export const SET_BADGE_CLASSIFICATIONS = 'SET_BADGE_CLASSIFICATIONS';
