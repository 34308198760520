import {default as http} from './HttpService'
import _ from 'lodash';

class N_UnitsServiceService {
    constructor() {
    }

    units() {
        return http.client.get('/v1/measurement_unit');
    }

    convert(payload) {
        return http.client.post('/v1/units-converter', payload);
    }

    save(item, editOrSave) {
        if (editOrSave > -1) {
            return http.client.put('/v1/measurement_unit', item);
        } else {
            return http.client.post('/v1/measurement_unit', item);
        }
    }

    delete(id) {
        return http.client.delete('/v1/measurement_unit/' + id);
    }

    unitConvertSameGroup(unitQuantity, unitFrom, unitTo, singleGroupUnits) {
        let from = null;
        let to = null;
        if (unitFrom !== null && typeof unitFrom !== 'object') {
            from = _.find(singleGroupUnits, (o) => {
                return o.name === unitFrom || o.code === unitFrom;
            });
            if (!from) {
                return null;
            }
        } else {
            from = unitFrom;
        }
        if (unitTo !== null && typeof unitTo !== 'object') {
            to = _.find(singleGroupUnits, (o) => {
                return o.name === unitTo || o.code === unitTo;
            });
            if (!to) {
                return null;
            }
        } else {
            to = unitTo;
        }
        let calculatedArea = 0;
        if (from.base_measurement_unit === null || from.base_measurement_unit === -1) {
            calculatedArea = unitQuantity / to.factor;
        } else if (to.base_measurement_unit === null || from.base_measurement_unit === -1) {
            calculatedArea = unitQuantity * from.factor
        } else {
            calculatedArea = (unitQuantity * from.factor) / to.factor
        }
        return calculatedArea.toFixed(2);
    }
}

export default new N_UnitsServiceService()
