import { default as http } from './HttpService';
import axios from 'axios';

class BaseService {
  constructor() {
    this.cancelTokens = {};
  }

  cancelPreviousRequest(methodName) {
    if (this.cancelTokens[methodName]) {
      this.cancelTokens[methodName]();
      delete this.cancelTokens[methodName];
    }
  }

  requestWithCancellation(method, url, data, methodName, config = {}) {
    this.cancelPreviousRequest(methodName);

    const cancelToken = new axios.CancelToken(c => this.cancelTokens[methodName] = c);
    const requestConfig = {...config, cancelToken};

    switch (method) {
      case 'get':
        return http.client.get(url, requestConfig);
      case 'post':
        return http.client.post(url, data, requestConfig);
      case 'put':
        return http.client.put(url, data, requestConfig);
      case 'delete':
        return http.client.delete(url, requestConfig);
      default:
        return Promise.reject(new Error('Method not supported'));
    }
  }
}

export default BaseService;
