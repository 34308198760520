export const SET_LOADING_ON = 'SET_LOADING_ON';
export const SET_LOADING_OFF = 'SET_LOADING_OFF';
export const SET_APPOINTMENTS_BY_COMPANY_PAGINATED = 'SET_APPOINTMENTS_BY_COMPANY_PAGINATED';
export const SET_APPOINTMENTS_BY_COMPANY_FILTERED = 'SET_APPOINTMENTS_BY_COMPANY_FILTERED';
export const SET_STORAGE_TYPE_FOR_RECORD_LIST = 'SET_STORAGE_TYPE_FOR_RECORD_LIST';
export const EXPORT = 'EXPORT';
export const SET_APPOINTMENTS_BY_PROCESS = 'SET_APPOINTMENTS_BY_PROCESS';
export const SET_APPOINTMENTS_BY_LOTE = 'SET_APPOINTMENTS_BY_LOTE';
export const SET_TAB_INDEX = 'SET_TAB_INDEX';
export const SET_STRUCTURES_LIST = 'SET_STRUCTURES_LIST';
export const SET_PRE_RECORD = 'SET_PRE_RECORD';
export const SET_ORIGINAL_STATE_PRE_RECORD = 'SET_ORIGINAL_STATE_PRE_RECORD';

export const SET_SOURCE = 'SET_SOURCE';
export const SET_REQUEST = 'SET_REQUEST';