import * as types from "./mutations-type.js";
import AppointmentsService from "../../../services/AppointmentsService";
import TrackingService from "../../../services/TrackingService";

export default {
  loadingON({ commit, state }) {
    state.trackingData = [];
    commit(types.SET_LOADING_ON, true);
  },
  loadingOFF({ commit }) {
    commit(types.SET_LOADING_OFF, false);
  },

  final({ commit }) {
    commit(types.FINAL);
  },
  async findRecordTrackingById({ dispatch, commit, state }, [id, toast]) {
    dispatch("loadingON");
    try {
      const { data } = await TrackingService.recordByQrId(id);

      commit(types.SET_RECORD_TRACKING_DATA, data);
      dispatch("loadingOFF");
    } catch (error) {
      if (error.message !== "cancelRequest")
        toast.error(i18n.t("records.notification.listError"), {
          queueable: true,
        });
    }
  },
};
