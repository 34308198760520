export const SET_STORAGE_BY_COMPANY_ACTIVE_LIST = 'SET_STORAGE_BY_COMPANY_ACTIVE_LIST';
export const SET_STORAGE_LIST = 'SET_STORAGE_LIST';
export const SET_S_STORAGE_DEVICES = 'SET_S_STORAGE_DEVICES';
export const SET_STORAGE_LIST_FOR_FILTERS = 'SET_STORAGE_LIST_FOR_FILTERS';
export const RESTORE_STORAGE_LIST = 'RESTORE_STORAGE_LIST';
export const SET_STORAGE_TABLE = 'SET_STORAGE_TABLE';
export const RESTORE_STORAGE_TABLE = 'RESTORE_STORAGE_TABLE';
export const SET_ALL_STORAGE_BY_COMPANY_LIST = 'SET_ALL_STORAGE_BY_COMPANY_LIST';
export const SET_ALL_STORAGE_LIST = 'SET_ALL_STORAGE_LIST';
export const SET_STORAGES_BY_USER_ACCESS = 'SET_STORAGES_BY_USER_ACCESS';
export const RESTORE_STORAGES_BY_USER_ACCESS = 'RESTORE_STORAGES_BY_USER_ACCESS';
export const SET_LOADING = 'SET_LOADING';
export const SET_MAP_CONFIG = 'SET_MAP_CONFIG';
export const SET_STORAGES_FOR_FILTERS = 'SET_STORAGES_FOR_FILTERS';
export const SET_SELECTED_COLUMNS = 'SET_SELECTED_COLUMNS';
export const SET_CLASSIFICATIONS = 'SET_CLASSIFICATIONS';
export const SET_BADGE_CLASSIFICATIONS = 'SET_BADGE_CLASSIFICATIONS';

// ---------------- BRAIN AG -----------------------------
export const SET_BRAINAG_HISTORY = 'SET_BRAINAG_HISTORY';
export const RESTORE_BRAINAG_HISTORY = 'RESTORE_BRAINAG_HISTORY';
export const SET_BRAINAG_LATEST_QUERIES = 'SET_BRAINAG_LATEST_QUERIES';
export const RESTORE_BRAINAG_LATEST_QUERIES = 'RESTORE_BRAINAG_LATEST_QUERIES';
export const SET_VERSIONS_LIST = 'SET_VERSIONS_LIST';
export const RESTORE_VERSIONS_DATA = 'RESTORE_VERSIONS_DATA';

export const SET_DOCUMENT_TYPES = 'SET_DOCUMENT_TYPES';

