import LanguajeService from "../../../../services/LanguajeService";

export default {
  allGroupUnits: state => _.map(state.allGroupUnits, (item) => {
    return {
      ...item,
      measurementUnitGroupName: LanguajeService.getKey3(item.language_key),
    };
  })
}
