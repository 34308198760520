import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VCard,{attrs:{"flat":""}},[_c(VCardText,{staticClass:"pa-0 text-left"},[_c(VCardTitle,[_vm._v(_vm._s(_vm.$t(_vm.location.title)))]),(_vm.location.name && _vm.location.name !== '')?_c(VRow,{staticClass:"ml-4"},[_c(VCol,{staticStyle:{"color":"#232323","font-weight":"bold"}},[_vm._v(" "+_vm._s(_vm.location.name)+" ")])],1):_vm._e(),(_vm.location.address1 && _vm.location.address1 !== '')?_c(VRow,{staticClass:"ml-4"},[_c(VCol,{staticStyle:{"color":"#232323","font-weight":"bold"}},[_vm._v(" "+_vm._s(_vm.location.address1)+" ")])],1):_vm._e(),(_vm.location.city && _vm.location.city !== '')?_c(VRow,{staticClass:"ml-4"},[_c(VCol,{staticStyle:{"color":"#232323","font-weight":"bold"}},[_vm._v(" "+_vm._s(_vm.location.city)+" ")])],1):_vm._e(),(_vm.location.state && _vm.location.state !== '')?_c(VRow,{staticClass:"ml-4"},[_c(VCol,{staticStyle:{"color":"#232323","font-weight":"bold"}},[_vm._v(" "+_vm._s(_vm.location.state)+" ")])],1):_vm._e(),(_vm.location.country && _vm.location.country !== '')?_c(VRow,{staticClass:"ml-4"},[_c(VCol,{staticStyle:{"color":"#232323","font-weight":"bold"}},[_vm._v(" "+_vm._s(_vm.location.country)+" ")])],1):_vm._e()],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }