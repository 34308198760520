<template>
    <fragment>
        <template>
            <div class="text-left">
                <v-menu
                        v-model="menu"
                        :close-on-content-click="false"
                        :nudge-width="150"
                        offset-x
                        max-height="350px"
                >
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn class="mt-2 ml-3"
                               dark
                               v-bind="attrs"
                               v-on="on"
                               fab
                               x-small
                               elevation="0"
                               tile
                               :color="cantFilters>0 ?'secondary':'white'"
                        >
                            <v-icon :color="cantFilters>0 ?'white':'black'">mdi-filter-variant
                            </v-icon>
                        </v-btn>
                    </template>

                    <v-card>
                        <v-list class="pb-0 pt-0">
                            <v-list-item>
                                <v-list-item-content class="pb-0 pt-0">
                                    <v-list-item-title>
                                        {{$t('general.buttons.filter')}}
                                    </v-list-item-title>
                                </v-list-item-content>
                                <v-list-item-avatar class="ml-5">
                                    <v-icon @click="filterAllMap(true)" color="secondary" class="ml-3">
                                        mdi-checkbox-multiple-marked-outline
                                    </v-icon>
                                </v-list-item-avatar>
                                <v-list-item-avatar class="ml-0">
                                    <v-icon @click="filterAllMap(false)" color="error" class="mb-1">
                                        mdi-filter-variant-remove
                                    </v-icon>
                                </v-list-item-avatar>
                            </v-list-item>
                        </v-list>
                        <v-divider></v-divider>
                        <v-list
                                dense
                        >
                            <div v-for="(stage, index) in stages" :key="index">
                                <v-list-item>
                                    <v-list-item-action @click="filterMap()" class="ml-2">
                                        <v-checkbox v-model="stage.checked"></v-checkbox>
                                    </v-list-item-action>
                                    <v-list-item-content>
                                        <v-list-item-title>{{stage.storage_type_name}}
                                        </v-list-item-title>
                                    </v-list-item-content>
                                </v-list-item>
                            </div>
                        </v-list>
                    </v-card>
                </v-menu>
            </div>
        </template>
    </fragment>
</template>
<script>
    import _ from 'lodash';

    export default {
        name: "MapFilter",
        inject: ["mapbox", "map", "actions"],
        props: [
            'stages'
        ],
        data() {
            return {
                menu: false
            };
        },

        created() {
        },

        methods: {
            cantFilters() {
                return this.stages ? _.filter(this.stages, stage => {
                    if (stage.checked === true) {
                        return stage
                    }
                }).length : 0;
            },
            filterAllMap(value) {
                _.forEach(this.stages, (stage) => {
                    stage.checked = value;
                });
                this.$emit("filterMap", this.stages);
            },
            filterMap() {
                this.$emit("filterMap", this.stages);
            }
        }
    };
</script>