import _ from "lodash";
import sortList from "@/services/OrderListService";

export default {
  GET_PRODUCERS: state => sortList.orderListByUppercase(_.map(state.producers, (item) => ({
    ...item,
    storageDevices: _.map(item.storageDevices, (s) => ({
      ...s,
      name: s.primaryLocation.name +' | '+ s.control_label
    })),
  })), 'name'),
  totalFromServer: state => state.totalFromServer,
  loading: state => state.loading
}
