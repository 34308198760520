import _ from "lodash";
import LanguajeService from "../../../../services/LanguajeService";
import sortList from "../../../../services/OrderListService";
export default {
    equipments: state => sortList.orderListByUppercase(_.map(state.equipments, (item) => {
        return {
            ...item,
            equipment_name: LanguajeService.getKey3(item.language_key),
            description_text: LanguajeService.getKey3(item.description),
            objectRelId: item.id
        };
    }), 'equipment_name'),
    totalFromServer: state => state.totalFromServer,
    loading: state => state.loading,
    exportUrl: state => state.exportUrl,
}
