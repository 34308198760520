<template>
  <fragment>
    <template>
      <div class="text-left">
        <v-menu v-model="menu"
                :close-on-content-click="false"
                :nudge-width="110"
                offset-x
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn class="mt-2 ml-3"
                   v-bind="attrs"
                   v-on="on"
                   fab
                   x-small
                   elevation="0"
                   tile
            >
              <v-icon color='black' small>mdi-map
              </v-icon>
            </v-btn>
          </template>

          <v-card>
            <v-list class="pb-0 pt-0">
              <v-list-item>
                <v-list-item-content class="pb-0 pt-0">
                  <v-list-item-subtitle> {{ $t('map.titles.styleTile').toUpperCase() }}</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
              <v-divider></v-divider>
              <v-list-item-group v-model="model">
                <v-list-item
                    v-for="(item, i) in items"
                    :key="i"
                    @click="switchLayer(item.value)"
                >
                  <v-list-item-title class="pt-0 pb-0" v-text="item.text"></v-list-item-title>
                </v-list-item>
              </v-list-item-group>
            </v-list>
          </v-card>
        </v-menu>
      </div>
    </template>
  </fragment>
</template>
<script>
import i18n from '@/plugins/i18n';
import _ from 'lodash';

export default {
  name: "MapStyleSelect",
  inject: ["mapbox", "map", "actions"],
  props: [
    'mapVueRef',
    'mapStyle'
  ],
  data() {
    return {
      fav: true,
      menu: false,
      message: false,
      hints: true,
      model: 0,
      style: 'streets-v11'
    };
  },
  computed: {
    items: () => [
      {text: i18n.t('map.styles.satellite'), value: 'satellite-v9'},
      {text: i18n.t('map.styles.streets'), value: 'streets-v11'}
    ],
  },

  created() {
    this.model = _.findIndex(this.items, ['value', this.mapStyle]);
    this.style = this.mapStyle;
  },

  methods: {
    switchLayer(layer) {
      this.menu = false;
      this.map.setStyle('mapbox://styles/mapbox/' + layer);
    }
  }
};
</script>

<style scoped>
.button__style-map {
  background-color: #ffffff;
  padding: 4px 6px;
  border-radius: .2em;
  position: absolute;
}

.button__style-map:hover {
  background-color: #eeeeee !important;
}
</style>