export const FILTER_TYPE                        = 'FILTER_TYPE';
export const FILTER_SOURCE                      = 'FILTER_SOURCE';
export const DRAWER                             = 'DRAWER';
export const SIDENAV                            = 'SIDENAV';
export const LOADING                            = 'LOADING';
export const FILTERS                            = 'FILTERS';
export const CHART_DATA                         = 'CHART_DATA';
export const DATA                               = 'DATA';
export const CHART_RANGES                       = 'CHART_RANGES';
export const LOCATION_RANGES                    = 'LOCATION_RANGES';
export const STORAGE_RANGES                     = 'STORAGE_RANGES';
export const CONTRACTS_DATA                     = 'CONTRACTS_DATA';
export const CONTRACTS_RANGES                   = 'CONTRACTS_RANGES';
export const SET_FILTERS_SORT                   = 'SET_FILTERS_SORT';
export const SET_LOCAL_STORAGE_FOR_SELLERS      = 'SET_LOCAL_STORAGE_FOR_SELLERS';
export const SET_LOCAL_STORAGE_FOR_CONTRACTS    = 'SET_LOCAL_STORAGE_FOR_CONTRACTS';
export const SET_LOCAL_STORAGE_FOR_DOCUMENTS    = 'SET_LOCAL_STORAGE_FOR_DOCUMENTS';
export const SET_LOCAL_STORAGE_FOR_INSPECTIONS  = 'SET_LOCAL_STORAGE_FOR_INSPECTIONS';
export const SET_LOCAL_STORAGE_FOR_RECORDS      = 'SET_LOCAL_STORAGE_FOR_RECORDS';
export const SET_LOCAL_STORAGE_FOR_PRE_RECORDS  = 'SET_LOCAL_STORAGE_FOR_PRE_RECORDS';
export const SET_LOCAL_STORAGE_FOR_LOCATIONS    = 'SET_LOCAL_STORAGE_FOR_LOCATIONS';
export const SET_LOCAL_STORAGE_FOR_STORAGES     = 'SET_LOCAL_STORAGE_FOR_STORAGES';
export const SET_LOCAL_STORAGE_FOR_PRODUCER     = 'SET_LOCAL_STORAGE_FOR_PRODUCER';
export const PRODUCER_FILTERS_ORIGINAL_STATE    = 'PRODUCER_FILTERS_ORIGINAL_STATE';
export const FILTERS_ORIGINAL_STATE             = 'FILTERS_ORIGINAL_STATE';
export const STRUCTURE_FILTERS                  = 'STRUCTURE_FILTERS';
export const STRUCTURES_BY_IDS                  = 'STRUCTURES_BY_IDS';
export const OTHERS_FILTERS                     = 'OTHERS_FILTERS';
export const FILTERS_OBJECTS_ORIGINAL_STATE     = 'FILTERS_OBJECTS_ORIGINAL_STATE';
export const OPTIONS_TABLE                      = 'OPTIONS_TABLE';
export const FILTERS_CHIPS                      = 'FILTERS_CHIPS';
export const COUNT_FILTERS                      = 'COUNT_FILTERS';
export const LOCATIONS_FILTERS                  = 'LOCATIONS_FILTERS';
export const STAGE_ID                           = 'STAGE_ID';
export const OBJECTS_TO_FILTERS                 = 'OBJECTS_TO_FILTERS';
export const PRODUCER_TO_FILTERS                = 'PRODUCER_TO_FILTERS';
export const DATE_FILTERS                       = 'DATE_FILTERS';
export const CLEAR_LOCAL_STORAGE                = 'CLEAR_LOCAL_STORAGE';
export const CLEAR_LOCAL_FILTERS_FOR_DOCUMENT   = 'CLEAR_LOCAL_FILTERS_FOR_DOCUMENT';
export const CLEAR_LOCAL_FILTERS_FOR_PRODUCER   = 'CLEAR_LOCAL_FILTERS_FOR_PRODUCER';
export const NOMENCLATOR_DATA                   = 'NOMENCLATOR_DATA';
export const NOMENCLATOR_DATA_ORIGINAL_STATE    = 'NOMENCLATOR_DATA_ORIGINAL_STATE';
export const NOTIFICATION                       = 'NOTIFICATION';
export const QUESTIONS                          = 'QUESTIONS';
export const SELECTED_QUESTIONS                 = 'SELECTED_QUESTIONS';
export const ANSWERS                            = 'ANSWERS';
export const SELECTED_ANSWERS                   = 'SELECTED_ANSWERS';
export const CLEAR_LOCAL_FILTERS                = 'CLEAR_LOCAL_FILTERS';