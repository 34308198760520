import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VChip } from 'vuetify/lib/components/VChip';
import { VDialog } from 'vuetify/lib/components/VDialog';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('p',{class:_vm.classes,style:({ 'max-width': '100%', fontSize: _vm.fontSize + 'px', 'overflow-wrap': 'break-word' })},[_vm._v(" "+_vm._s(_vm.$t(_vm.formattedString))+" "),_c('span',{directives:[{name:"show",rawName:"v-show",value:(_vm.text.length > _vm.maxChars),expression:"text.length > maxChars"}]},[_c(VChip,{directives:[{name:"show",rawName:"v-show",value:(!_vm.isReadMore && _vm.layout === 'text'),expression:"!isReadMore && layout === 'text'"}],attrs:{"x-small":"","id":"readmore"},on:{"click":function($event){$event.stopPropagation();return _vm.triggerReadMore($event, true)}}},[_vm._v(" "+_vm._s(_vm.$t(_vm.moreStr))+" ")]),_c(VChip,{directives:[{name:"show",rawName:"v-show",value:(_vm.isReadMore && _vm.layout === 'text'),expression:"isReadMore && layout === 'text'"}],attrs:{"x-small":"","id":"readmore"},on:{"click":function($event){$event.stopPropagation();return _vm.triggerReadMore($event, false)}}},[_vm._v(" "+_vm._s(_vm.$t(_vm.lessStr))+" ")]),_c(VChip,{directives:[{name:"show",rawName:"v-show",value:(_vm.layout === 'dialog'),expression:"layout === 'dialog'"}],attrs:{"x-small":"","id":"readmore"},on:{"click":function($event){$event.stopPropagation();_vm.showDialog = true}}},[_vm._v(" "+_vm._s(_vm.$t(_vm.moreStr))+" ")])],1)]),_c(VDialog,{attrs:{"max-width":"800px"},model:{value:(_vm.showDialog),callback:function ($$v) {_vm.showDialog=$$v},expression:"showDialog"}},[_c(VCard,[_c(VCardTitle,[_vm._v(_vm._s(_vm.i18n.t('locations.fields.description')))]),_c(VCardText,{staticClass:"dialog-content"},[_vm._v(" "+_vm._s(_vm.$t(_vm.text))+" ")]),_c(VCardActions,{staticClass:"justify-end"},[_c(VBtn,{attrs:{"color":"secondary","text":""},on:{"click":function($event){_vm.showDialog = false}}},[_vm._v(" "+_vm._s(_vm.$t(_vm.lessStr))+" ")])],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }