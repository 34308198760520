import { default as http } from '@/services/HttpService'

class NotificationService {
  constructor() {
  }

  getNotificationsMenuByUser() {
    return http.client.get('v1/notifications/by-user/recently')
  }

  getNotificationsByUser(filters) {
    return http.client.post('v1/notifications/by-user/filter-pageable', filters)
  }

  setReadNotifications(_ids) {
    return http.client.put('v1/notifications/change-watched', _ids)
  }

  deleteNotifications(_ids) {
    return http.client.put('v1/notifications/change-deleted', _ids)
  }
}

export default new NotificationService()