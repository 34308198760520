import i18n from '@/plugins/i18n';
import * as types from './mutations-type.js';
import DocumentTypeService from "../../../../services/DocumentTypeService";
import N_GroupUnitsService from "../../../../services/N_GroupUnitsService";

export default {
    async fetchListDocumentTypes({commit,state}, toast) {
        state.documentTypes = [];
        try {
            const {data} = await DocumentTypeService.documentTypes();

            commit(types.SET_DOCUMENT_TYPE_LIST, data)
        } catch (error) {
            if (error.message !== 'cancelRequest')
                toast.error(i18n.t('documentType.notification.listError'), {
                    queueable: true
                });
        }
    },

    async fetchListDocumentTypesByCompany({commit,state}, [company, toast]) {
        state.documentTypes = [];
        try {
            const {data} = await DocumentTypeService.getDocumentTypesByCompany(company);
            commit(types.SET_DOCUMENT_TYPE_LIST, data)
        } catch (error) {
            if (error.message !== 'cancelRequest')
                toast.error(i18n.t('documentType.notification.listError'), {
                    queueable: true
                });
        }
    },

    async saveDocumentTypes({commit, state}, [item, editOrSave, toast]) {
        try {
            await DocumentTypeService.save(item, editOrSave);
        } catch (error) {
            if (error.message !== 'cancelRequest')
                toast.error(i18n.t('documentType.notification.saveError'), {
                    queueable: true
                });
        }
    },
}
