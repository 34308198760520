import _ from "lodash";
import {default as http} from "./HttpService";
import BaseService from "@/services/BaseService"

class StorageTypesService extends BaseService {
  constructor() {
    super()
  }

  locations() {
    return http.client.get(`v1/company/locations`);
  }

  authorizeOrNot(data) {
    return http.client.put(`v1/location/change_status`, data);
  }

  locationsVersions(filters) {
    return http.client.post(`v1/location/versions_pageable`, filters);
  }

  locationsFiltered(filters) {
    return http.client.post(`v1/location/filter_pageable/extra_columns`, filters);
    // TODO: Improve cancel request
    // return this.requestWithCancellation('post',`v1/location/filter_pageable/extra_columns`, filters, 'locationsFiltered');
  }

  getById(id) {
    return http.client.get(`v1/location/${id}`);
  }

  getPersonsInChargeByLocation(data) {
    return http.client.post(`v1/person-in-charge/person_allowed`, data)
  }

  getAccessLocationByUser(data) {
    return http.client.post('v1/person-in-charge/locations_access', data)
  }

  activeLocations() {
    return http.client.get(`v1/company/active-locations`);
  }

  exportCSV(data) {
    return http.client.post(`v1/location/export/excel`, data, {
      responseType: 'blob'
    });
  }

  save(item, editOrSave, document_logo, logo_file, documents_json, documents_file, badges_file, geobufData) {
    let fd = new FormData()

    fd.append('location_json', JSON.stringify(item))

    if (logo_file) {
      fd.append('document_logo', JSON.stringify(document_logo))
      fd.append('logo_file', logo_file)
    }

    if(documents_file && documents_file.length > 0) {
      fd.append('document_json', JSON.stringify(documents_json))

      if (documents_file.length > 0) {
        _.forEach(documents_file, (file) => {
          fd.append('documents_file', file);
        });
      }
    }
    if(badges_file && badges_file.length > 0) {
      _.forEach(badges_file, (badgeFile) => {
        fd.append('badges_file', badgeFile);
      });
    }
    if(geobufData){
      const blob = new Blob([geobufData]);
      fd.append('geoData', blob, 'data.pbf');
    }


    if (editOrSave !== null) {
      return http.client.put(`v1/location`, fd, {
        headers: {'Content-Type': 'multipart/form-data'},
      });
    } else {
      return http.client.post(`v1/location`, fd, {
        headers: {'Content-Type': 'multipart/form-data'},
      });
    }
  }

  changeActiveValue(id_store, status_active) {
    let item = {"locationId": id_store, "active_value": status_active};
    return http.client.put(`v1/location/change_active_value`, item);
  }

  delete(id) {
    return http.client.delete('/v1/location/' + id);
  }

  checkGln(gln) {
    let item = {"gln": gln}
    return http.client.post('v1/location/check_gln', item)
  }

  getAreaRange() {
    return http.client.get('v1/location/get_area_range')
  }

  fetchTemplate(payload) {
    return http.client.get(`v1/location/template/${payload.language}`, {
      responseType: 'blob',
    })
  }

  import(options) {
    let fd = new FormData()
    fd.append('language', options.language)
    fd.append('file', options.file)

    return http.client.post(`v1/location/excel`, fd, { headers: { 'Content-Type': 'multipart/form-data' } })
  }

  getExtraColumns() {
    return http.client.get('v1/person-in-charge/location/get_extra_columns_configuration')
  }

  setExtraColumns(payload) {
    return http.client.post('v1/person-in-charge/location/save_extra_columns_configuration', payload)
  }

  getClassificationTypes() {
    return http.client.get('v1/location-type/type/primary')
  }

  getBadgeClassifications() {
    return http.client.get('v1/badge/classifications/primary')
  }
}

export default new StorageTypesService()
