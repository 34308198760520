<template>
  <v-app v-if="rastro">
  <v-container
    fluid
    class="px-0"
    style="padding: 0 !important;"
  >
    <v-card
      :loading="loading"
      class="mx-auto my-0"
    >
      <template slot="progress">
        <v-progress-linear
          color="deep-purple"
          height="10"
          indeterminate
        ></v-progress-linear>
      </template>

      <v-img
        height="230"
        :src=fondo
      ></v-img>
      <v-card-title style="padding-bottom: 25px !important; color: #383737">Informação do produto</v-card-title>

      <v-card-text>
        <v-row
          align="center"
          class="mx-0"
        >
        </v-row>

        <div v-if="rastro.record" class="my-1 text-h6 d-flex justify-start align-content-center align-center" >
          <v-icon left small>
            mdi-map-marker
          </v-icon>
          <p class="ml-0 ma-0 pa-0 text-subtitle-2 text-start" style="font-family: 'Times New Roman', sans-serif; font-weight: 400;font-size: 12px">
            Origem: <span style="font-weight: bold">{{ isNotExist(rastro.record.sdOrigin.locationVersion.name) + " | " + isNotExist(rastro.record.sdOrigin.storageDeviceVersion.control_label) | toStartCase }}</span></p>
        </div>

        <div v-if="product" class="my-1 text-h6 d-flex justify-start align-content-center align-center" >
          <v-icon left small>
            mdi-package-variant-closed
          </v-icon>
          <p class="ml-0 ma-0 pa-0 text-subtitle-2 text-start" style="font-family: 'Times New Roman', sans-serif; font-weight: 400;font-size: 12px">
            Produto: <span style="font-weight: bold">{{ isNotExist(product) | toStartCase }}</span></p>
        </div>

        <div v-if="lot || safra" class="my-1 text-h6 d-flex justify-start align-content-center align-center" >
          <v-icon left small>
            mdi-barcode-scan
          </v-icon>
          <p class="ml-0 ma-0 pa-0 text-subtitle-2 text-start" style="font-family: 'Times New Roman', sans-serif; font-weight: 400;font-size: 12px">
            {{
              lot && lot !== "-"
                ? "Lote"
                : "Safra"
            }}: <span style="font-weight: bold">{{ lot && lot !== "-" ? isNotExist(lot) : isNotExist(safra)  | toStartCase }}</span></p>
        </div>

        <div v-if="rastro.expirationDate" class="my-1 text-h6 d-flex justify-start align-content-center align-center" >
          <v-icon left small>
            mdi-calendar-clock
          </v-icon>
          <p class="ml-0 ma-0 pa-0 text-subtitle-2 text-start" style="font-family: 'Times New Roman', sans-serif; font-weight: 400;font-size: 12px">
            Data de validade: <span style="font-weight: bold">{{ formatDate(rastro.expirationDate, "DD/MM/YYYY")  }}</span></p>
        </div>

        <div v-if="rastro.packingDate" class="my-1 text-h6 d-flex justify-start align-content-center align-center" >
          <v-icon left small>
            mdi-calendar-export
          </v-icon>
          <p class="ml-0 ma-0 pa-0 text-subtitle-2 text-start" style="font-family: 'Times New Roman', sans-serif; font-weight: 400;font-size: 12px">
            Data de embalagem: <span style="font-weight: bold">{{ formatDate(rastro.packingDate, "DD/MM/YYYY")  }}</span></p>
        </div>

        <div v-if="rastro.additionalInformation">{{rastro.additionalInformation}}</div>
      </v-card-text>

      <v-divider class="mx-4"></v-divider>

      <v-card-title style=" color:#383737">Informação geográfica</v-card-title>

      <v-card-text style="padding-bottom: 0 !important;">
        <v-container style="padding: 0 !important;"  id='applicationContainer' fluid>
          <router-view ></router-view>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <a href='https://agtrace.ag' target='_blank'>
          <p class='ml-0 ma-0 pa-0 text-subtitle-2 text-end pr-3'
             style="font-family: 'Times New Roman', sans-serif; font-weight: 400;font-size: 12px">Powered by AgTrace</p>
        </a>
      </v-card-actions>
    </v-card>
  </v-container>
  </v-app>
</template>

<script>
import QrLabel from "@/module/labels/components/QrLabel";
import { mapActions, mapGetters } from "vuex";
import moment from 'moment/moment'

export default {
  name: "L_QrLabel",
  components: { QrLabel },
  data: () => ({
    label_code: null,
    rastro: null,
    lot: null,
    product: null,
    safra: null,
    time: new Date().toISOString().split("T").pop().split(".").shift(),
    sheet: false,
    height: 30,
    detailType: "",
    imageName: "",
    locationsProperties: {},
    keyP: "",
    testImages: [],
    logo: require("@/assets/img/final/agtrace_logo.png"),
    fondo: require("@/assets/img/final/fondo3.jpg"),
    background: require("@/assets/img/background_movil.jpg"),
    timeline: "",
    dallery: "",
    map: "",
    cert: "",
    variable: {
      L: "L",
      P: "P",
      S: "S",
    },
    allAddressProduced: "",
    allAddressDistributed: "",
  }),
  computed: {
    recordSelected: {
      get() {
        return this.$store.getters["labels/recordTrackindData"];
      },
      set(val) {
        this.$store.commit("labels/SET_RECORD_TRACKING_DATA", val);
      },
    },
    ...mapGetters({
      loading: "labels/loading",
    }),
  },
  created() {
    if (this.$route.params.label_code) {
      this.label_code = this.$route.params.label_code;
      localStorage.setItem("record_label", this.label_code);
      this.load(this.label_code);
    } else {
      this.label_code = localStorage.getItem("record_label");
      this.load(this.label_code);
      this.$router.push({
        name: "label_trace",
        params: { label_code: localStorage.getItem("record_label") },
      });
    }
  },
  methods: {
    ...mapActions({
      findRecordById: "labels/findRecordTrackingById",
    }),
    isNotExist(field) {
      if (field === null)
        return '-'

      return field
    },
    formatDate(date, format){
      return moment(date, "YYYY-MM-DD").format(format)
    },
    load(label_code) {
      this.findRecordById([label_code, this.$toast]).finally(() => {
        this.rastro = _.cloneDeep(this.recordSelected);
        this.product = this.rastro.productDescription;
        this.safra = this.rastro.season;
        this.lot = this.rastro.lot;
        const location_origin = this.rastro.record.sdOrigin.storageDeviceVersion;
        const location_destination =
          this.rastro.record.sdDestination.storageDeviceVersion;
        this.allAddressProduced =
          location_origin.name +
          " " +
          this.checkIfExist(location_origin.document_key, true, ": ") +
          this.checkIfExist(location_origin.document_value, true, ", ") +
          this.checkIfExist(location_origin.address1, true, ", ") +
          this.checkIfExist(location_origin.city, true, ", ") +
          this.checkIfExist(location_origin.state, true, ", ") +
          this.checkIfExist(location_origin.country, false);
        this.allAddressDistributed =
          location_destination.name +
          " " +
          this.checkIfExist(location_destination.document_key, true, ": ") +
          this.checkIfExist(location_destination.document_value, true, ", ") +
          this.checkIfExist(location_destination.address1, true, ", ") +
          this.checkIfExist(location_destination.city, true, ", ") +
          this.checkIfExist(location_destination.state, true, ", ") +
          this.checkIfExist(location_destination.country, false);
      });
    },
    checkIfExist(target, continueTrue, separator) {
      target = target ? target : "";
      continueTrue = continueTrue && target !== "" ? separator : "";
      return target + continueTrue;
    },
  },
};
</script>
<style scoped>
>>> .v-toolbar__image .v-image {
  border-radius: inherit;
  height: 200px !important;
}

>>> html {
  overflow-y: hidden;
}

>>> .v-toolbar__extension {
  padding: 0 !important;
  vertical-align: bottom;
}

>>> .borderNavigation {
  border-bottom: #03ac8c;
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-top-style: solid;
  border-top-width: 1px;
}
</style>
