import * as types from './mutations-type.js';
import {SET_S_STORAGE_DEVICES} from "./mutations-type.js";

export default {
    [types.SET_STORAGE_BY_COMPANY_ACTIVE_LIST](state, data) {
        state.storagesByCompanyAndActive = data;
    },
    [types.SET_STORAGES_FOR_FILTERS](state, data) {
        state.storagesForFilters = data;
    },
    [types.SET_STORAGE_LIST](state, data) {
        state.storagesList = data.content;
        state.totalFromServer = data.totalElements;
    },
    [types.SET_S_STORAGE_DEVICES](state, data) {
        state.sStorageDevices = data;
    },
    [types.SET_STORAGE_LIST_FOR_FILTERS](state, data) {
        state.storagesListForFilters = data.content;
        state.totalFromServerForFilters = data.totalElements;
    },
    [types.RESTORE_STORAGE_LIST](state) {
        state.storagesList = [];
        state.totalFromServer = 0;
    },
    [types.SET_STORAGE_TABLE](state, data) {
        state.storagesTable = data.content;
        state.totalFromServerTable = data.totalElements;
    },
    [types.RESTORE_STORAGE_TABLE](state) {
        state.storagesTable = [];
        state.totalFromServerTable = 0;
    },
    [types.SET_ALL_STORAGE_BY_COMPANY_LIST](state, data) {
        state.allStoragesByCompany = data;
    },
    [types.SET_ALL_STORAGE_LIST](state, data) {
        state.allStorages = data;
    },
    [types.SET_LOADING](state, data) {
        state.loading = data;
    },
    [types.SET_MAP_CONFIG](state, data) {
        state.mapConfig = data;
    },
    [types.SET_STORAGES_BY_USER_ACCESS](state, data) {
        state.storagesByUserAccess = data.content;
        state.totalFromServer = data.totalElements;
    },
    [types.RESTORE_STORAGES_BY_USER_ACCESS](state) {
        state.storagesByUserAccess = [];
        state.totalFromServer = 0;
    },
    [types.SET_VERSIONS_LIST](state, data) {
        state.versions = data.content;
        state.totalFromServerVersions = data.totalElements;
    },
    [types.RESTORE_VERSIONS_DATA](state) {
        state.versions = [];
        state.totalFromServerVersions = 0;
    },
    [types.SET_SELECTED_COLUMNS](state, data) {
        state.selectedColumns = data;
    },
    [types.SET_CLASSIFICATIONS](state, data) {
        state.classifications = data;
    },
    [types.SET_BADGE_CLASSIFICATIONS](state, data) {
        state.badgeClassifications = data;
    },

    // -------------- BRAIN AG --------------------------

    [types.SET_BRAINAG_HISTORY](state, data) {
        state.queryHistory = data;
    },
    [types.RESTORE_BRAINAG_HISTORY](state, data) {
        state.queryHistory = data;
    },
    [types.SET_BRAINAG_LATEST_QUERIES](state, data) {
        state.latestQueries = data;
    },
    [types.RESTORE_BRAINAG_LATEST_QUERIES](state, data) {
        state.latestQueries = data;
    },

    [types.SET_DOCUMENT_TYPES](state, data) {
        state.documentTypes = data;
    },
}
