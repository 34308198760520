import {default as http} from './HttpService'

class DocumentDescriptionService {
    constructor() {
    }

    documentDescriptions() {
        return http.client.get(`v1/document-description`);
    }

    documentDescriptionsByDocumentType(company, docType) {
        return http.client.post(`v1/document-description/byDocumentType`, {
            companyId: company,
            documentTypeIds: docType
        });
    }

    save(item, editOrSave) {
        if (editOrSave > -1) {
            return http.client.put('/v1/document-description', item);
        } else {
            return http.client.post('/v1/document-description', item);
        }
    }

    delete(id) {
        return http.client.delete('/v1/document-description/' + id);
    }
}

export default new DocumentDescriptionService()
