import {default as http} from './HttpService'
import BaseService from "@/services/BaseService";

class ReportsService extends BaseService {
    constructor() {
        super()
    }

    getDataStock(filters) {
        return http.client.post('/v1/report/stock-closed-projection',filters);
    }
    getProtheusDataFiltered(filters) {
        return http.client.post('/v1/protheus/protheus-filters', filters);
    }
    getDataContracts(filters) {
        return http.client.post('/v1/protheus/get_contract', filters);
    }
    getContract(contract) {
        return http.client.post('v1/protheus/contractData',{contract:contract});
    }
    getVolumesN(company) {
        return http.client.post('/v1/protheus/total-volumen-max-min', {
            company_id: company
        });
    }
    getVolumesB(company) {
        return http.client.post('/v1/protheus/total-benefit-volumen-max-min', {
            company_id: company
        });
    }
    getQuebra(company) {
        return http.client.post('/v1/protheus/quebra-max-min', {
            company_id: company
        });
    }
    getSellersNames(company) {
        return http.client.post('/v1/protheus/contract-seller-name-id', {
            company_id: company
        });
    }
    getContractsNumbers(company) {
        return http.client.post('/v1/protheus/contract-name-id', {
            company_id: company
        });
    }
    getDataSellers(filters) {
        return http.client.post('/v1/protheus/protheus-sellers', filters)
    }
    getGeneralReport(data) {
        return http.client.post('/v1/reports/general-report', data, {
            responseType: 'blob'
        });
    }
    getBiorganicFiltered(filters, source) {
        return http.client.post('/v1/record/records-paginated-to-biorganic-report', filters, {
            cancelToken: source.token
        });
    }
    getTraceabilityFiltered(filters) {
        return http.client.post('/v1/record/records-traceability-paginated', filters);
    }
    getBiorganicReport(filters) {
        return http.client.post('/v1/reports/biorganic-report/excel', filters, {
            responseType: 'blob'
        });
    }
    getTraceabilityV2Report(filters, source) {
        return http.client.post('/v1/record/records-lots-report', filters, {
            cancelToken: source.token
        });
    }
    getExistingTraceabilityReport(filters) {
        return http.client.post('/v1/traceability_saved/get-saved', filters);
    }
    generateReportsByLotAndProduct(data) {
        return http.client.post('/v1/traceability_saved/save-list', data);
    }
    getDateNumberOfPendants() {
        return this.requestWithCancellation('get', '/v1/report/get-date-number-of-pendants', null, 'getDateNumberOfPendants');
    }
    setManualStockUpdate() {
        return http.client.get('/v1/report/manual-stock-update');
    }
    setDateToStartStockReset(data) {
        console.log(data)
        return http.client.post('/v1/report/configuration', data);
    }
    checkCanProcess() {
        return http.client.get('/v1/report/check-can-process');
    }
    getQrList(filters) {
        return http.client.post('/v1/traceability_saved/filter_pageable', filters);
    }
    activeOrDeactive(data) {
        return http.client.put('/v1/traceability_saved/change_active', data);
    }
    setPrintLimit(data) {
        return http.client.put('/v1/traceability_saved/set_print_limit', data);
    }
    getStockDate() {
        return http.client.get('/v1/report/configuration-get-date');
    }
    deleteTraceabilityReport(id) {
        return http.client.delete(`/v1/traceability_saved/${id}`);
    }
}

export default new ReportsService()
