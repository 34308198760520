import { VBtn } from 'vuetify/lib/components/VBtn';
import { VChip } from 'vuetify/lib/components/VChip';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemAction } from 'vuetify/lib/components/VList';
import { VListItemAvatar } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemIcon } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VListItem,{key:_vm.index},[(_vm.pIcon)?_c(VListItemAvatar,[_c(VIcon,{attrs:{"color":_vm.pColor}},[_vm._v(" "+_vm._s(_vm.pIcon)+" ")])],1):_vm._e(),_c(VListItemIcon,{staticClass:"fixed-width"},[_c(VChip,{attrs:{"small":"","disabled":""}},[_vm._v(" "+_vm._s(_vm.notification.module)+" ")])],1),_c(VListItemContent,[_c(VListItemTitle,{staticClass:"d-block"},[_c(VChip,{staticClass:"mt-1",attrs:{"small":"","color":"grey lighten-4"}},[_vm._v(" "+_vm._s(_vm.notification.data.query.type.documentType)+" | "+_vm._s(_vm.getDatasetName(_vm.notification))+" "),_c(VTooltip,{attrs:{"bottom":"","content-class":"tooltip-styles"},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c(VIcon,_vm._g(_vm._b({staticClass:"ml-3",staticStyle:{"font-size":"14px"}},'v-icon',attrs,false),on),[_vm._v("mdi-information-outline")])]}}])},[_c('span',[_vm._v(" "+_vm._s(_vm.notification.data.query.type.document)+" ")])])],1),_c('p',{staticClass:"mx-1 ma-0 pa-0 subtitle-2 black--text my-1"},[_vm._v(_vm._s(_vm.$t('storage.titles.location'))+" "),_c('br'),_vm._v(" "+_vm._s(_vm.notification.data.storageDevice.control_label))]),_c('p',{staticClass:"mx-1 ma-0 pa-0 my-1",staticStyle:{"font-size":"12px","color":"#0f0d0d"}},[_vm._v(_vm._s(_vm.getDateFormat(_vm.notification.dateTime)))])],1)],1),(_vm.withAction)?_c(VListItemAction,[(!_vm.notification.watched)?_c(VBtn,{attrs:{"fab":"","x-small":"","color":"grey lighten-4","elevation":"0","loading":_vm.loading},on:{"click":function($event){return _vm.$emit('mark:read')}},scopedSlots:_vm._u([{key:"loader",fn:function(){return [_c(VProgressCircular,{attrs:{"indeterminate":"","size":"12","width":"2"}})]},proxy:true}],null,false,3385518082)},[_c(VIcon,{attrs:{"color":"secondary","small":""}},[_vm._v(" mdi-check ")])],1):_vm._e()],1):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }