
import i18n from '@/plugins/i18n';
import _ from "lodash";
import LanguajeService from "../../../services/LanguajeService";

export default {
    pageEnum() {
        return [
            {id: 1, text: i18n.t('reports.select.A4portrait'), type: 'portrait'},
            {id: 2, text: i18n.t('reports.select.A4landscape'), type: 'landscape'}
        ]
    },
    totalsByLocations: state => _.map(state.totalsByLocations,(dataByLocation)=>{
        return {
            ...dataByLocation,
            compress: true,
            storageDeviceName: dataByLocation.storageDevice.name,
            locationName: dataByLocation.storageDevice.primaryLocation.name,
            lots:_.map(dataByLocation.lots, (dataStock)=>{
                return {
                    ...dataStock,
                    storageDeviceName: dataStock.storageDevice.name,
                    locationName: dataStock.storageDevice.primaryLocation.name
                }
            })
        }
    }),
    totalsByProducts: state => state.totalsByProducts,
    generalTotals: state => state.generalTotals,

    // Wallet
    totalsByLocationsWallet: state => _.map(state.totalsByLocations,(dataByLocation)=>{
        return {
            ...dataByLocation,
            compress: true,
            storageDeviceName: dataByLocation.storageDevice.control_label,
            locationName: dataByLocation.storageDevice.primaryLocation.name,
            lots:_.map(dataByLocation.lots, (dataStock)=>{
                return {
                    ...dataStock,
                    storageDeviceName: dataStock.storageDevice.control_label,
                    locationName: dataStock.storageDevice.primaryLocation.name,
                    unit: dataStock.unit === 'Tonelada' ? 'Token': dataStock.unit
                }
            }),
            totals:_.map(dataByLocation.totals, (dataTotal)=>{
                return {
                    ...dataTotal,
                    values: _.map(dataTotal.values, (values)=>{
                        return {
                            ...values,
                            unit: values.unit === 'Tonelada' ? 'Token': values.unit
                        }
                    }),
                }
            })
        }
    }),
    totalsByProductsWallet: state => _.map(state.totalsByProducts, (dataByProducts)=>{
        return {
            ...dataByProducts,
            values: _.map(dataByProducts.values, (values)=>{
                return {
                    ...values,
                    unit: values.unit === 'Tonelada' ? 'Token': values.unit
                }
            }),
        }
    }),
    generalTotalsWallet: state => _.map(state.generalTotals, (dataStockWallet)=>{
        return {
            ...dataStockWallet,
            unit: dataStockWallet.unit === 'Tonelada' ? 'Token': dataStockWallet.unit
        }
    }),

    // loading
    loading: state => state.loading,
    loadingSellers: state => state.loadingSellers,
    loadingContracts: state => state.loadingContracts,

    // charts
    datasets: state => state.datasets,
    chart: state => state.chart,
    itemInSheet: state => state.itemInSheet,

    // sheet
    sheetContract: state => state.sheetContract,
    sheetSeller: state => state.sheetSeller,

    //sellers
    sellers: state => state.sellers,
    sellersNames: state => _.orderBy(state.sellersNames),

    // contracts
    contracts: state => state.contracts,
    contractsNames: state => _.orderBy(state.contractsNames),
    totalContracts: state => state.totalContracts,

    // volumes & quebra ranges
    quebra: state => state.quebra,
    volumesN: state => state.volumesN,
    volumesB: state => state.volumesB,

    records: state => _.map(state.records, (item) => {
        return {
            ...item,
            process_name:LanguajeService.getKey3(item.process.language_key),
            sdi_origin_name: item.sdi_origin && item.sdi_origin.control_label  ? item.sdi_origin.primaryLocation.name + ' / ' + item.sdi_origin.control_label: '-',
            sdi_destination_name: item.sdi_destination && item.sdi_destination.control_label ? item.sdi_destination.primaryLocation.name + ' / ' + item.sdi_destination.control_label: '-',
            person_in_charge_name: item.person_in_charge && item.person_in_charge.name ? item.person_in_charge.name : '-',
            sdt_to_show_translate: LanguajeService.getKey3(item.process.sdt_to_show.language_key),
            recordStructureValueDTOListNames: item.recordStructureValues && item.recordStructureValues.length > 0 ? _.map(item.recordStructureValues, (recordStructure) => {
                return {
                    ...recordStructure,
                    structure_name_translate: recordStructure ? LanguajeService.getKey3(recordStructure.structure.language_key) : recordStructure.structure.language_key,
                    groupStructure_name: recordStructure.groupStructures ? ((d) => {d['group_details_name'] = LanguajeService.getKey3(d.languageKey); return [d];}) (recordStructure.groupStructures) : [],
                }
            }) : item.recordStructureValues
        };
    }),
    recordsIds: state => _.map(state.records, (item) => {
        return item.id;
    }),
    totalRecordsFromServer: state => state.totalRecordsFromServer,

    inspections: (state, getters) => {
        return _.map(state.inspections, (survey) => {
            const status = _.find(getters.surveyStateEnum, {type: survey.surveyStateType});
            return {
                ...survey,
                questionSectionList: _.map(survey.questionSectionList, (questionSection) => {
                    return {
                        ...questionSection,
                        questions: _.map(questionSection.questions, (question) => {
                            return {
                                ...question,
                                answer_name: LanguajeService.getKey3(question.answer),
                                possibleAnswer: _.map(question.possibleAnswer, (answer) => {
                                    return {
                                        ...answer,
                                        answer_name: LanguajeService.getKey3(answer.answer)
                                    }
                                })
                            }
                        })
                    }
                }),
                location_name: survey.locations && survey.locations.length > 0 ? survey.locations : [],
                surveyStateTypeName: status ? status.text : survey.surveyStateType
            }
        });
    },
    inspectionsIds: state => _.map(state.inspections, (item) => {
        return item.id;
    }),
    totalInspectionsFromServer: state => state.totalInspectionsFromServer,

    documents: state => _.map(state.documents, (item) => {
        return {
            ...item,
            type_name: LanguajeService.getKey3(item.type),
            description_name: LanguajeService.getKey3(item.description)
        };
    }),
    documentsIds: state => _.map(state.documents, (item) => {
        return item.id;
    }),
    totalDocumentsFromServer: state => state.totalDocumentsFromServer,

    biorganic: state => _.map(state.biorganic, (item) => {
        return {
            area: parseFloat(item.columnsProcess1.area.column_value),
            quantity: parseFloat(item.columnsProcess2.quantity.column_value),
            ...item
        };
    }),
    biorganicIds: state => _.map(state.biorganic, (item) => {
        return item.id;
    }),
    totalBiorganicFromServer: state => state.totalBiorganicFromServer,

    traceability: state => _.map(state.traceability, (item) => {
        return {
            ...item,
            process_name: LanguajeService.getKey3(item.process.language_key) ,
            sdi_origin_name: item.sdi_origin && item.sdi_origin.control_label ? item.sdi_origin.control_label : '-',
            sdi_destination_name: item.sdi_destination && item.sdi_destination.control_label ? item.sdi_destination.control_label : '-',
            person_in_charge_name: item.person_in_charge && item.person_in_charge.name ? item.person_in_charge.name : '-',
            sdt_to_show_translate: LanguajeService.getKey3(item.process.sdt_to_show.language_key),
            recordStructureValueDTOListNames: item.recordStructureValues && item.recordStructureValues.length > 0 ? _.map(item.recordStructureValues, (recordStructure) => {
                return {
                    ...recordStructure,
                    structure_name_translate: recordStructure ? LanguajeService.getKey3(recordStructure.structure.language_key) : recordStructure.structure.language_key,
                    groupStructure_name: recordStructure.groupStructures ? ((d) => {d['group_details_name'] = LanguajeService.getKey3(d.languageKey); return [d];}) (recordStructure.groupStructures) : [],
                }
            }) : item.recordStructureValues
        };
    }),
    totalRTFromServer: state => state.totalRTFromServer,

    qrList: state => state.qrList,
    totalQRFromServer: state => state.totalQRFromServer,

    traceabilityV2: state => _.map(state.traceabilityV2, (item, index) => {
        return {
            storageTypeName: index,
            ...item
        };
    }),
    recordsByIds: state => state.recordsByIds,
    totalTraceabilityV2FromServer: state => state.totalTraceabilityV2FromServer,

    reCalculateInfo: state => state.reCalculateInfo,

    request: state => state.request,
    source: state => state.source,

    defaultHeadersTraceability: state => state.defaultHeadersTraceability,
    headersTraceability: state => state.headersTraceability,
}
