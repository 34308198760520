import {default as http} from './HttpService'
import i18n from '@/plugins/i18n';

class LanguajeService {
    constructor() {
    }

    capitalizeFirstLetter(text) {
        return text.toLowerCase().replace(/^\w/, (c) => c.toUpperCase());
    }

    languajeKeys() {
        return http.client.get(`v1/language-key`);
    }

    async languageStructure() {
        return await http.client.get(`v1/language-key/structure`);
    }

    getLenguajeName() {
        return this.getLenguajeNameByKey(i18n.locale);
    }

    getLenguajeNameByKey(languajeKey) {
        let languaje = '';
        switch (languajeKey) {
            case 'pt-br':
                languaje = 'PORTUGUESE';
                break;
            case 'en-us':
                languaje = 'ENGLISH';
                break;
            case 'es-es':
                languaje = 'ESPANNOL';
        }
        return languaje;
    }

    getKeyByLenguajeName(languajeName) {
        let key = '';
        switch (languajeName) {
            case 'PORTUGUESE':
                key = 'pt-br';
                break;
            case 'ENGLISH':
                key = 'en-us';
                break;
            case 'ESPANNOL':
                key = 'es-es';
        }
        return key;
    }

    changeLanguaje(languajeName) {
        i18n.locale = this.getKeyByLenguajeName(languajeName);
    }

    getKey(key) {
        let language = this.getLenguajeName();
        let translates = JSON.parse(localStorage.getItem('languaje_keys'));
        return translates[language][key] ? translates[language][key] : false;
    }

    getKey2(key, uppercase) {
        let language = this.getLenguajeName();
        let translates = JSON.parse(localStorage.getItem('languaje_keys'))[language];
        return translates[key] ? uppercase ? translates[key].toUpperCase() : translates[key] : key;
    }

    getKey3(translatesArrayText, uppercase) {
        if (translatesArrayText) {
            let language = this.getLenguajeName();
            let translates = _.find(translatesArrayText, {'language': language});
            return translates ? (uppercase ? translates.label.toUpperCase() : translates.label) : translatesArrayText;
        } else {
            return null
        }
    }

    setKey3(key, translatesArrayText, languageStructure) {
        let language = this.getLenguajeName();
        if (languageStructure)
            translatesArrayText = languageStructure;

        if (key === null)
            key = "";

        return _.map(translatesArrayText, (translate) => {
            return {
                ...translate,
                label: translate.language === language ? key : languageStructure ? key : translate.label
            }
        });

        // TODO: Tener en cuenta para explicar porque solo se esta guardando un solo idioma y no todos (sin soporte en el backend)
        // return _.map(translatesArrayText, (translate) => {
        //     return {
        //         ...translate,
        //         label: key
        //         ? key
        //         : translate.label
        //     }
        // });
    }

    validateLabels(array) {
        if (Array.isArray(array))
            return array.every(item => item.label === "" || item.label === undefined);

        return true;
    }
}

export default new LanguajeService()
