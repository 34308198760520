import store from '@/store';
import _ from "lodash";

const rules = [
    {match: '/v1/profile/auth', method: 'post', auth: false, role: ['All']},
    //controllers
    {
        match: '/v1/record/filter',
        auth: true,
        access: [{
            role: ['All']
        },
            {role: ['All']}]
    }
];

let navigates = [
    {
        path: '/dashboard',
        name: 'dashboard',
        domain: 'Dashboard',
        meta: {domain: 'Dashboard'}
    },
    {
        path: '/integrations',
        name: 'integrations',
        domain: 'AbrapaProductAPI',
        meta: {domain: 'AbrapaProductAPI'}
    },
    {
        path: '/integrations',
        name: 'integrations',
        domain: 'AbrapaOrderAPI',
        meta: {domain: 'AbrapaOrderAPI'}
    },
    {
        path: '/records',
        name: 'records',
        domain: 'Record',
        meta: {domain: 'Record'}
    },
    {
        path: '/',
        name: 'init',
        domain: 'Dashboard',
        meta: {domain: 'Dashboard'}
    },
    {
        path: '/recordsAdd',
        name: 'recordsAdd',
        domain: 'Record',
        meta: {domain: 'Record'}
    },
    {
        path: '/documents',
        name: 'documents',
        domain: 'Document',
        meta: {domain: 'Document'}
    },
    {
        path: '/inspections',
        name: 'inspections',
        domain: 'Survey',
        meta: {domain: 'Survey'}
    },
    {
        path: '/inspections/answer',
        name: 'inspectionsAnswer',
        domain: 'Survey',
        meta: {domain: 'Survey'}
    },
    {
        path: '/inspections/details',
        name: 'inspectionsDetails',
        domain: 'Survey',
        meta: {domain: 'Survey'}
    },
    {
        path: '/companies',
        name: 'companies',
        domain: 'Company',
        meta: {domain: 'Company'}
    },
    {
        path: '/nomenclators/inputs',
        name: 'inputs',
        domain: 'Input',
        meta: {domain: 'Input'}
    },
    {
        path: '/nomenclators/products',
        name: 'products',
        domain: 'Culture',
        meta: {domain: 'Culture'}
    },
    {
        path: '/nomenclators/survey',
        name: 'survey',
        domain: 'surveyAdm',
        meta: {domain: 'surveyAdm'}
    },
    {
        path: '/nomenclators/survey/surveyAdd',
        name: 'surveyAdd',
        domain: 'surveyAdm',
        meta: {domain: 'surveyAdm'}
    },
    {
        path: '/nomenclators/survey/questionAdd',
        name: 'questionAdd',
        domain: 'surveyAdm',
        meta: {domain: 'surveyAdm'}
    },
    {
        path: '/nomenclators/equipments',
        name: 'equipments',
        domain: 'Equipment',
        meta: {domain: 'Equipment'}
    },
    {
        path: '/nomenclators/operators',
        name: 'operators',
        domain: 'Operator',
        meta: {domain: 'Operator'}
    },
    {
        path: '/nomenclators/producers',
        name: 'producers',
        domain: 'Producer',
        meta: {domain: 'Producer'}
    },
    {
        path: '/nomenclators/seasons',
        name: 'seasons',
        domain: 'Season',
        meta: {domain: 'Season'}
    },
    {
        path: '/nomenclators/storage-types',
        name: 'storage-types',
        domain: 'StorageDeviceType',
        meta: {domain: 'StorageDeviceType'}
    },
    {
        path: '/nomenclators/locations',
        name: 'locations',
        domain: 'Location',
        meta: {domain: 'Location'}
    },
    {
        path: '/nomenclators/storage',
        name: 'storage',
        domain: 'StorageDevice',
        meta: {domain: 'StorageDevice'}
    },
    {
        path: '/nomenclators/storage-mgmt',
        name: 'storage-mgmt',
        domain: 'StorageDevice',
        meta: {domain: 'StorageDevice'}
    },
    {
        path: '/nomenclators/roles',
        name: 'roles',
        domain: 'Role',
        meta: {domain: 'Role'}
    },
    {
        path: '/nomenclators/proccess',
        name: 'proccess',
        domain: 'Process',
        meta: {domain: 'Process'}
    },
    {
        path: '/nomenclators/details',
        name: 'details',
        domain: 'Structure',
        meta: {domain: 'Structure'}
    },
    {
        path: '/configRecordLabel',
        name: 'configRecordLabel',
        domain: 'Record',
        meta: {domain: 'Record'}
    },
    {
        path: '/printRecordLabel/:id',
        name: 'printRecordLabel',
        domain: 'Record',
        meta: {domain: 'Record'}
    },
    {
        path: '/reports/traceability-report',
        name: 'traceability-report',
        domain: 'Traceability',
        meta: {domain: 'Traceability'}
    },
    {
        path: '/reports/stock-report',
        name: 'stock-report',
        domain: 'StockReport',
        meta: {domain: 'StockReport'}
    },
    {
        path: '/reports/token-wallet',
        name: 'token-wallet',
        domain: 'TokenWallet',
        meta: {domain: 'TokenWallet'}
    },
    {
        path: '/tokens/my-wallet',
        name: 'my-wallet',
        domain: 'MyWallet',
        meta: {domain: 'MyWallet'}
    },
    {
        path: '/reports/general-report',
        name: 'general-report',
        domain: 'GeneralReport',
        meta: {domain: 'GeneralReport'}
    },
    {
        path: '/reports/biorganic-report',
        name: 'biorganic-report',
        domain: 'BiorganicReport',
        meta: {domain: 'BiorganicReport'}
    },
    {
        path: '/reports/traceabilityV2-report',
        name: 'traceabilityV2-report',
        domain: 'TraceabilityV2Report',
        meta: {domain: 'TraceabilityV2Report'}
    },
    {
        path: '/reports/sellers-report',
        name: 'sellers-report',
        domain: 'IntegrationProducerQuebra',
        meta: {domain: 'IntegrationProducerQuebra'}
    },
    {
        path: '/reports/contracts-report',
        name: 'contracts-report',
        domain: 'IntegrationContractQuebra',
        meta: {domain: 'IntegrationContractQuebra'}
    },
    {
        path: '/nomenclators/authority',
        name: 'authority',
        domain: 'PersonInCharge',
        meta: {domain: 'PersonInCharge'}
    },
    {
        path: '/nomenclators/location-mgmt',
        name: 'location-mgmt',
        domain: 'Location',
        meta: {domain: 'Location'}
    },
    {
        path: '/nomenclators/location-mgmt',
        name: 'location-mgmt',
        domain: 'StorageDevice',
        meta: {domain: 'StorageDevice'}
    },

];

navigates = _.map(navigates, (navigate) => {
    return {
        ...navigate,
        meta: {domain: 'All', next: false}
    }
});

export const navigate = (to, from, next) => {
    let found = false;
    const roles = store.getters['login/roles'];
    if (to.name === 'final' || to.name === 'pictures' || to.name === 'map' || to.name === 'certificates' || to.name === 'label_trace') {
        next();
    } else {
        if (store.getters['login/isLoggedIn']) {
            try {
                if (to.meta.domain === 'All' || _.find(roles,{'manager': true})) {
                    found = true;
                    next();
                } else {
                    _.forEach(roles, (rol) => {
                        if (rol.authorityList && rol.authorityList.length > 0) {
                            const nextRoute = _.find(rol.authorityList, {'domain': to.meta.domain});
                            if (nextRoute) {
                                found = true;
                                next();
                            }
                        }
                    });
                }
                if (!found) {
                    const foundRoute = _.find(navigates, (navigate) => {
                        if (_.find(roles, (rol) => {
                            if (rol.authorityList && rol.authorityList.length > 0) {
                                const nextRoute = _.find(rol.authorityList, {'domain': navigate.domain});
                                if (nextRoute) {
                                    return true;
                                }
                            }
                        })) {
                            return navigate;
                        }
                    });
                    if (foundRoute)
                        next({name: foundRoute.name});
                }
            } catch (e) {
                localStorage.clear()
                store.dispatch('general/setLayout', 'Login');
                next({ name: 'login' });
            }
        } else if (to.name === 'login') {
            localStorage.clear()
            next();
        } else {
            localStorage.clear()
            store.dispatch('general/setLayout', 'Login');
            next({ name: 'login' });
        }
    }
};

export const redirectBasedOnIntegration = (to, from, next) => {
    const profile = JSON.parse(localStorage.getItem('profile'));

    if (profile) {
        store.commit('integration/SET_INTEGRATIONS', profile.integrationsCompany)
        next({name: 'dashboard'});
    } else {
        next({name: 'login'});
    }
};

export const request = (config, token) => {
    const path = rules.find(f => config.url.match(f.match));
    if (path) {
        if (path.auth) {
            if (token) {
                // const roles = AuthService.parseJwt(token).roles;
                if (path.hasOwnProperty('method')) {
                    if (path.method === config.method) {
                        if (path.role[0] === 'All') {
                            return false;
                        } else {
                            return !roles.find(o => path.role.filter(f => `ROLE_${f}` === o).length !== 0);
                        }
                    } else {
                        return true;
                    }
                } else {
                    roles.push('ROLE_All');
                    if (config.method === 'get') {
                        return !roles.find(o => path.access[0].role.filter(f => `ROLE_${f}` === o).length !== 0);
                    } else {
                        return !roles.find(o => path.access[1].role.filter(f => `ROLE_${f}` === o).length !== 0);
                    }
                }
            } else {
                return true;
            }
        } else {
            return false;
        }
    } else {
        return true;
    }
};

export default {navigate, request, redirectBasedOnIntegration};
