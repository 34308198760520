export const SET_INSPECTION = 'SET_INSPECTION';
export const SET_CANT_TO_EXPORT = 'SET_CANT_TO_EXPORT';
export const SET_SCORE = 'SET_SCORE';
export const SET_QUESTION = 'SET_QUESTION';
export const SET_LOADING_ON = 'SET_LOADING_ON';
export const SET_LOADING_OFF = 'SET_LOADING_OFF';
export const SET_UPLOAD_PROGRESS = 'SET_UPLOAD_PROGRESS';
export const SET_ERROR_ON = 'SET_ERROR_ON';
export const SET_ERROR_OFF = 'SET_ERROR_OFF';
export const SET_SURVEY_BY_COMPANY_LIST = 'SET_SURVEY_BY_COMPANY_LIST';
export const SET_INSPECTION_TO_EXPORT = 'SET_INSPECTION_TO_EXPORT';
export const SET_ORIGINAL_STATE_INSPECTION = 'SET_ORIGINAL_STATE_INSPECTION';
export const SET_BLOB = "SET_BLOB"

