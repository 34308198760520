import { default as http } from "./HttpService";
import LanguajeService from "./LanguajeService";

class ProducerService {
  constructor() {}

  producers(filters) {
    return http.client.post(`v1/producer/paginated`, {
      ...filters
    });
  }

  checkFields(fields) {
    return http.client.post(`v1/producer/check_fields`, {
      ...fields
    });
  }

  all() {
    return http.client.get(`v1/producer/all`);
  }

  import(company, file) {
    let fd = new FormData();
    fd.append("company_id", company);
    fd.append("file", file);

    return http.client.post(`/v1/producer/excel`, fd, {
      headers: { "Content-Type": "multipart/form-data" },
    });
  }

  export(data) {
    return http.client.post(`/v1/producer/export/excel`, data, {
      responseType: "blob",
    });
  }

  downloadTemplate(language) {
    return http.client.get(`/v1/producer/template/${language}`, {
      responseType: "blob",
    });
  }

  getEvidences(producer) {
    return http.client.get(`/v1/producer/${producer.id}/zip`, {
      responseType: "blob",
      headers: {
        Accept: "application/zip",
      },
    });
  }

  save(item, editOrSave) {
    if (editOrSave > -1) {
      return http.client.put("/v1/producer", item);
    } else {
      return http.client.post("/v1/producer", item);
    }
  }

  delete(id) {
    return http.client.delete("/v1/producer/" + id);
  }
}

export default new ProducerService();
