import * as types from './mutations-type'
import i18n from '@/plugins/i18n';
import N_GroupUnitsService from "../../../../services/N_GroupUnitsService";

export default {
    async fetchAllGroupUnits({commit}, toast) {
        try {
            const {data} = await N_GroupUnitsService.units();
            commit(types.SET_ALL_GROUPUNITS, data);
        } catch (error) {
            if (error.message !== 'cancelRequest')
                toast.error(i18n.t('groupUnit.notification.listError'), {
                    queueable: true
                });
        }
    },
    async saveGroupUnit({commit, state}, [item, editOrSave, toast]) {
        try {
            await N_GroupUnitsService.save(item, editOrSave);
        } catch (error) {
            if (error.message !== 'cancelRequest')
                toast.error(i18n.t('groupUnit.notification.saveError'), {
                    queueable: true
                });
        }
    },
}
