<template>
    <div class="locale-changer">
        <v-select id="locale-changer"
                  :items="langs"
                  v-model="locale"
                  max-height="auto"
                  return-object
                  text
                  :dense="dense"
                  solo
                  color="#cccccc"
                  :flat="flat"
                  :background-color="background"
                  :dark="dark"
                  item-color="secondary"
                  :hide-details="true"
                  @change="languajeChange($event)"
                  append-icon="mdi-chevron-down"
        >
            <template slot="selection" slot-scope="data">
                <div id="languajeSelected">
                    <v-avatar tile :height="flagH" :width="flagW" :min-width="flagW">
                        <v-img :src="require('@/assets/flag/' + data.item + '.png')"></v-img>
                    </v-avatar>
                </div>
            </template>
            <template slot="item" slot-scope="data">
                <template>
                    <div class="languajeName">
                        <v-avatar tile :height="flagH" :width="flagW" :min-width="flagW">
                            <v-img :src="require('@/assets/flag/' + data.item + '.png')"></v-img>
                        </v-avatar>
                        {{data.item.split('-')[1].toUpperCase()}}
                    </div>
                </template>
            </template>
        </v-select>
    </div>
</template>


<script>
    export default {
        name: 'locale-changer',
        props: [
            'dense',
            'flagW',
            'flagH',
            'flat',
            'background',
            'dark'
        ],
        data() {
            return {
                langs: [],
                locale: this.$i18n.locale
            }
        },
        watch: {},
        created() {
            this.langs = this.$i18n.availableLocales;
        },
        methods: {
            languajeChange(lang) {
                this.$i18n.locale = lang;
                localStorage.setItem('locale', lang);
                location.reload();
            }
        }
    }
</script>

<style lang="scss" scoped>
    @import '../../src/assets/css/variables.scss';

    .theme--dark.v-icon {
        color: $metal;
        font-size: 18px !important;
    }

    .v-application .primary--text {
        color: $secondary;
    }

    #locale-changer .v-list-item--dense, .v-list--dense .v-list-item {
        min-height: 0 !important;
        padding-left: 5px;
        padding-right: 5px;
        background-color: $neuter;
    }

    /* .theme--light.v-list {
         background: $neuter;
         color: rgba(0, 0, 0, 0.87);
     }
     .theme--light.v-card {
         background: $neuter;
         color: rgba(0, 0, 0, 0.87);
     }*/
    .languajeName {
        font-size: 14px;
    }

</style>
