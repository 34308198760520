import _ from "lodash"
import i18n from "@/plugins/i18n"

import {
  addFileIPFS,
  addFilePinata,
  addFileMFS,
  makeDir,
} from "@/services/IPFSIntegrationService"

import {
  balanceOf,
  burnToken,
  checkFactoryOwner,
  createNewToken,
  emitTokens,
  getStatus,
  getTotalMinted,
  getTotalSupply,
  getWalletBalance,
  getTokenData,
  getTokens,
  grantRole,
  revokeRole,
  hasRole,
  transferOwnership,
  pause,
  settleToken,
  transfer,
  unpause,
  uuidv4,
} from "@/services/Web3IntegrationService"

import * as contractAPI from "@/services/ContractsIntegrationService"

export default {
  async ADD_FILE_IPFS({}) {
    await addFileIPFS()
  },

  async ADD_FILE_MFS({}) {
    await addFileMFS()
  },

  async MAKE_DIR_IPFS({}, dir) {
    await makeDir(dir)
  },

  async LOGIN({ dispatch, commit }) {
    if (window.ethereum) {
      try {
        await window.ethereum.request({
          method: "wallet_requestPermissions",
          params: [
            {
              eth_accounts: {},
            },
          ],
        })
        const accounts = await ethereum.request({ method: "eth_accounts" })
        window.userAddress = accounts[0]
        localStorage.setItem("eth_account", accounts[0])
        commit("REGISTER_WEB3_INSTANCE", accounts[0])
        dispatch("IF_UPDATE_ADDRESS")
      } catch (error) {
        console.error(error)
      }
    } else {
      console.log("Nenhuma extensão de navegador ETH detectada.")
    }
  },

  async LOGOUT({ dispatch, commit }) {
    window.userAddress = null
    localStorage.removeItem("eth_account")
    commit("REVERT_WEB3_INSTANCE")
    commit("DEFAULT_TOKEN_DATA")
    commit("DEFAULT_WALLET_DATA")
    commit("DEFAULT_TOKEN_LIST")
    commit("DEFAULT_CONTRACTS_LIST")
    dispatch("IF_UPDATE_ADDRESS")
  },

  IF_UPDATE_ADDRESS(payload) {
    if (payload) return true
    else return !!window.userAddress
  },

  START_LISTENING_WALLET_CHANGES({ getters, dispatch }) {
    const wallet = getters.GET_WALLET
    window.ethereum.on("accountsChanged", wallets => {
      if (wallet.address !== window.userAddress) {
        window.userAddress = window.ethereum.selectedAddress
        localStorage.setItem("eth_account", window.userAddress)
        dispatch("IF_UPDATE_ADDRESS", window.userAddress)
        return
      }
      if (wallets.length === 0) {
        dispatch("LOGOUT")
      }
    })
  },

  async LOAD_FACTORY_DATA({ commit }, payload) {
    const factoryABI = payload.factoryJSON.abi
    const ERC1155ModelABI = payload.ERC1155ModelJSON.abi

    commit("FACTORY_DATA", {
      factoryABI: factoryABI,
      erc1155ModelABI: ERC1155ModelABI,
    })
  },

  async FETCH_WALLETS({ commit }) {
    try {
      const { data } = await contractAPI.getWalletsByCompany()
      commit("WALLETS_DATA", data)
    } catch (err) {
      console.log(err)
      this._vm.$toast.error("Load wallet error")
    }
  },

  async CREATE_WALLET({ getters, commit, dispatch }, payload) {
    const profile = JSON.parse(localStorage.getItem("profile"))

    try {
      const { data } = await contractAPI.createWallet(payload)
      dispatch("FETCH_WALLETS", profile.company_id)
    } catch (err) {
      console.log(err)
      this._vm.$toast.error("Create wallet error")
    }
  },

  async UPDATE_WALLET({ getters, commit, dispatch }, payload) {
    const profile = JSON.parse(localStorage.getItem("profile"))

    try {
      const { data } = await contractAPI.updateWallet(payload)
      dispatch("FETCH_WALLETS", profile.company_id)
    } catch (err) {
      console.log(err)
      this._vm.$toast.error("Update wallet error")
    }
  },

  async DELETE_WALLET({ getters, commit, dispatch }, payload) {
    const profile = JSON.parse(localStorage.getItem("profile"))

    try {
      const { data } = await contractAPI.deleteWallet(payload)
      dispatch("FETCH_WALLETS", profile.company_id)
    } catch (err) {
      console.log(err)
      this._vm.$toast.error("Delete wallet error")
    }
  },

  async FETCH_STOCK({ commit }) {
    try {
      const { data } = await contractAPI.getStockTokens()
      commit("TOKEN_LIST", data)
    } catch (err) {
      console.log(err)
      this._vm.$toast.error("Fetch stock error")
    }
  },

  async FETCH_ACTIVE_STOCK({ commit }) {
    try {
      const { data } = await contractAPI.getActiveStockTokens()
      commit("TOKEN_LIST", data)
    } catch (err) {
      console.log(err)
      this._vm.$toast.error("Fetch stock error")
    }
  },

  async FETCH_CONTRACTS_JSON({ getters, commit }) {
    const { erc1155ModelABI } = getters.GET_FACTORY

    try {
      const { data } = await contractAPI.getContractsByCompany()
      let contracts = data

      let toADD = []
      _.forEach(contracts, async token => {
        const _contractAddress = token.contractAddress

        let _totalSupply = 0
        let _totalMinted = 0
        try {
          _totalMinted = await getTotalMinted(erc1155ModelABI, _contractAddress)

          _totalSupply = await getTotalSupply(erc1155ModelABI, _contractAddress)
        } catch (err) {
          console.log(err)
        }

        toADD.push({
          ...token,
          active: _totalSupply,
          ownerSupply: _totalMinted,
        })
      })

      commit("CONTRACTS_JSON_LIST", toADD)
      console.log("Fetch contracts json Action being completed")
    } catch (err) {
      this._vm.$toast.error("Fetch contracts error")
      console.log("Fetch contracts json Action being reverted")
      console.log(err)
    }
  },

  async UPDATE_CONTRACTS_JSON({ dispatch }, payload) {
    try {
      await contractAPI.createContract(payload)

      await dispatch("FETCH_CONTRACTS_JSON")

      console.log("Fetch contracts json Action being completed")
    } catch (err) {
      this._vm.$toast.error("Update contract error")
      console.log("Fetch contracts json Action being reverted")
      console.log(err)
    }
  },

  async FETCH_LOTS_NFTs({ commit }, payload) {
    try {
      const { data } = await contractAPI.getNFTsByContract(payload)
      commit("SET_LOTS", data)
      console.log("Fetch products Action being completed")
    } catch (err) {
      console.log("Fetch products Action being reverted")
      console.log(err)
    }
  },

  async LOAD_WALLET_DATA({ getters, commit, dispatch }, payload) {
    commit('IS_SYNC', true)

    const { erc1155ModelABI } = getters.GET_FACTORY

    setTimeout(async () => {
      const balance = await getWalletBalance(
        erc1155ModelABI,
        payload.contractAddress,
        window.ethereum.selectedAddress
      )

      commit("WALLET_DATA", balance)
      commit('IS_SYNC', false)
    }, 5000)
    // await dispatch('LOAD_TOKEN_LIST', { contractAddress: payload.contractAddress });
  },

  async LOAD_TOKEN_LIST({ getters, commit }, payload) {
    // TODO: SEND viene ahora de la base de datos
    // console.log(send)
    // TODO: RECEIVER viene ahora de la base de datos
    // console.log(receiver)
    // TODO: SETTLE viene ahora de la base de datos
    // console.log(settle)
    /*commit('TOKEN_DATA', {
      tokens: {
        send: send,
        receiver: receiver,
        settle: settle
      },
      amount: {
        send: amountSend,
        receiver: amountReceived,
        settle: amountSettle
      }
    })*/
  },

  async FETCH_PRODUCTS({ commit }, payload) {
    commit("DEFAULT_PRODUCTS")

    try {
      const { data } = await contractAPI.getProductsByCompany(payload)
      commit("SET_PRODUCTS", data)
    } catch (err) {
      console.log(err)
    }
  },

  async OFFSET_CREDIT({ state, getters, commit, dispatch }, payload) {
    const { erc1155ModelABI } = getters.GET_FACTORY

    commit("SET_LOADING_SETTLE", true)

    const result = await settleToken(
      erc1155ModelABI,
      payload.data.contractAddress,
      state.web3.account,
      payload.amounts[0],
      payload.references[0],
      payload.description
    )

    const data = {
      ...payload.data,
      tx_hash: result["transactionHash"],
      origin: window.userAddress || state.web3.account,
      destination: payload.data.contractAddress,
      wallet: window.userAddress || state.web3.account,
    }

    await dispatch("HANDLE_ACTION", data)

    await dispatch("LOAD_WALLET_DATA", {
      contractAddress: payload.data.contractAddress,
    })

    commit("SET_LOADING_SETTLE", false)
    commit("SYNC_INFO", true)
  },

  async SEND_TOKEN({ state, getters, commit, dispatch }, payload) {
    const { erc1155ModelABI } = getters.GET_FACTORY

    commit("SET_LOADING_SEND", true)

    const result = await transfer(
      erc1155ModelABI,
      payload.data.contractAddress,
      state.web3.account,
      payload.wallet.address,
      payload.amounts[0],
      payload.references[0],
      payload.description
    )

    console.log(result)

    const data = {
      ...payload.data,
      tx_hash: result["transactionHash"],
      origin: window.userAddress || state.web3.account,
      destination: payload.wallet.address,
      wallet: window.userAddress || state.web3.account,
    }

    await dispatch("HANDLE_ACTION", data)

    await dispatch("LOAD_WALLET_DATA", {
      contractAddress: payload.data.contractAddress,
    })

    commit("SET_LOADING_SEND", false)
    commit("SYNC_INFO", true)
  },

  async GENERATE_REPORT({}, payload) {
    try {
      const { data } = await contractAPI.checkSettleReport(payload)

      if (data === "IS_NOT_READY") {
        return false;
      } else {
        const { data } = await contractAPI.generateSettleReport(payload)

        const FileSaver = require("file-saver")
        const blob = new Blob([data], {
          type: "application/pdf",
        })
        FileSaver.saveAs(blob, "settle-report.pdf")

        return true;
      }
    } catch (error) {
      if (error.message !== "cancelRequest")
        this._vm.$toast.error(i18n.t("documents.notification.download"), {
          queueable: true,
        })
    }
  },

  async GENERATE_REFERENCE({}, count) {
    const { data } = await contractAPI.generateReference(count)
    return data
  },

  async EMIT_TOKENS({ state, getters, commit, dispatch }, payload) {
    const { erc1155ModelABI } = getters.GET_FACTORY

    console.log(payload)

    const emitted = await emitTokens(
      erc1155ModelABI,
      payload.data.contractAddress,
      window.userAddress,
      payload.references,
      payload.amounts,
      payload.description
    )

    const data = {
      ...payload.data,
      tx_hash: emitted["transactionHash"],
      origin: window.userAddress || state.web3.account,
      destination: payload.data.contractAddress,
      wallet: window.userAddress || state.web3.account,
    }

    await dispatch("HANDLE_ACTION", data)
    await dispatch("FETCH_STOCK")

    commit("SYNC_INFO", true)
  },

  async HANDLE_ACTION({}, payload) {
    await contractAPI.handleAction(payload)
  },

  async BURN_TOKENS({ state, getters, commit, dispatch }, payload) {
    const { erc1155ModelABI } = getters.GET_FACTORY

    const burned = await burnToken(
      erc1155ModelABI,
      payload.data.contractAddress,
      window.userAddress,
      payload.references,
      payload.amounts
    )

    const data = {
      ...payload.data,
      tx_hash: burned["transactionHash"],
      origin: window.userAddress || state.web3.account,
      destination: payload.data.contractAddress,
      wallet: window.userAddress || state.web3.account,
    }

    await dispatch("HANDLE_ACTION", data)
    await dispatch("FETCH_STOCK")

    commit("SYNC_INFO", true)
  },

  async CREATE_CONTRACT({ getters, commit, dispatch }, payload) {
    const { factoryABI } = getters.GET_FACTORY

    const data = await createNewToken(
      factoryABI,
      payload.name,
      payload.symbol,
      payload.walletAddress,
      window.userAddress
    )

    console.log(data)

    const contract = {
      companyId: payload.companyId,
      contractAddress: data.logs[0].address,
      product: payload.name,
      reference: data.logs[0].address,
      symbol: payload.symbol,
      roles: payload.roles,
    }

    await dispatch("UPDATE_CONTRACTS_JSON", contract)

    commit("SYNC_INFO", true)

    // Contracts from Blockchain
    // await dispatch('FETCH_CONTRACTS')
  },

  async CHECK_FACTORY_OWNER({ getters, commit }) {
    const { factoryABI } = getters.GET_FACTORY

    const owner = await checkFactoryOwner(factoryABI, window.userAddress)

    commit("FACTORY_OWNER", owner)
  },

  async FETCH_CONTRACTS({ getters, commit }) {
    const { factoryABI, erc1155ModelABI } = getters.GET_FACTORY
    const tokens = await getTokens(factoryABI, window.userAddress)

    console.log(tokens)

    // let toAdd = []
    // _.forEach(tokens, async (token) => {

    //   const _contractAddress = token.tokenAddress;
    //   const _referenceID = token.tokenAddress;

    //   const _tokenData = await getTokenData(erc1155ModelABI, _contractAddress, _referenceID);
    //   let status = await getStatus(erc1155ModelABI, _contractAddress);

    //   status = !status;

    //   toAdd.push({
    //     contractAddress: _contractAddress,
    //     name: _tokenData.name,
    //     symbol: _tokenData.symbol,
    //     active: parseInt(_tokenData.totalSupply),
    //     ownerSupply: parseInt(_tokenData.ownerSupply),
    //     status: status,
    //     baseURI: _tokenData.baseURI
    //   })
    // });

    // commit('CONTRACTS_LIST', toAdd)
  },

  async HAS_ROLE({ getters }, payload) {
    const { erc1155ModelABI } = getters.GET_FACTORY

    return await hasRole(
      erc1155ModelABI,
      payload.contractAddress,
      window.userAddress,
      payload.roleAddress,
      payload.wallet.address
    )
  },

  async TRANSFER_FACTORY_OWNER({ getters }, payload) {
    const { factoryABI } = getters.GET_FACTORY

    return await transferOwnership(factoryABI, payload.userAddress)
  },

  async GRANT_ROLE({ getters, dispatch }, payload) {
    const { erc1155ModelABI } = getters.GET_FACTORY

    await grantRole(
      erc1155ModelABI,
      payload.contractAddress,
      window.userAddress,
      payload.roleAddress,
      payload.wallet.address
    )

    await contractAPI.grantRolesToWallet(payload.role).finally(() => {
      dispatch("FETCH_CONTRACTS_JSON")
    })

    console.log("finish")
  },

  async REVOKE_ROLE({ getters, commit, dispatch }, payload) {
    const { erc1155ModelABI } = getters.GET_FACTORY

    await revokeRole(
      erc1155ModelABI,
      payload.contractAddress,
      window.userAddress,
      payload.roleAddress,
      payload.wallet.address
    )
      .then(async res => {
        console.log(res)
        await contractAPI.revokeRolesToWallet(payload.role).finally(() => {
          dispatch("FETCH_CONTRACTS_JSON")
        })
      })
      .catch(err => console.log)

    commit("SYNC_INFO", true)
    console.log("finish")
  },

  async INIT_CONTRACT({ getters, commit, dispatch }, payload) {
    if (payload && payload.contractAddress) {
      const { erc1155ModelABI } = getters.GET_FACTORY

      try {
        commit("SET_LOADING_START", true)

        await getStatus(erc1155ModelABI, payload.contractAddress);

        await contractAPI.updateContractStatus({
          status: 'RUNNING',
          contractId: payload.id,
        })

        await dispatch('FETCH_CONTRACTS_JSON')
      } catch (e) {
        this._vm.$toast.warning(i18n.t('tokens.notification.notSynced'), {
          color: 'gray'
        })
      } finally {
        commit("SET_LOADING_START", false)
      }
    }
  },

  async START_CONTRACT({ getters, commit, dispatch }, payload) {
    if (payload && payload.contractAddress) {
      commit("SET_LOADING_START", true)

      const { erc1155ModelABI } = getters.GET_FACTORY
      await unpause(
        erc1155ModelABI,
        payload.contractAddress,
        window.userAddress
      )
        .then(async res => {
          console.log(res)
          await contractAPI.updateContractStatus({
            status: 'RUNNING',
            contractId: payload.id,
          })
        })
        .catch(console.log)

      commit("SET_LOADING_START", false)
      commit("SYNC_INFO", true)

      dispatch("FETCH_CONTRACTS_JSON")
      // await dispatch('FETCH_CONTRACTS')
    }
  },

  async STOP_CONTRACT({ getters, commit, dispatch }, payload) {
    if (payload && payload.contractAddress) {
      commit("SET_LOADING_STOP", true)

      const { erc1155ModelABI } = getters.GET_FACTORY
      await pause(erc1155ModelABI, payload.contractAddress, window.userAddress)
        .then(async res => {
          console.log(res)
          await contractAPI.updateContractStatus({
            status: 'STOPPED',
            contractId: payload.id,
          })
        })
        .catch(console.log)

      commit("SET_LOADING_STOP", false)
      commit("SYNC_INFO", true)

      await dispatch("FETCH_CONTRACTS_JSON")
      // await dispatch('FETCH_CONTRACTS')
    }
  },

  async FETCH_TRACKING_BY_CONTRACT({ commit }, [action, contractID]) {
    try {
      const { data } = await contractAPI.getLogsByAction(action, contractID)
      commit("TOKEN_DETAILS", data)
    } catch (err) {
      console.log(err)
    }
  },

  async FETCH_TRACKING_BY_WALLET({ commit }, [action, wallet, contract]) {
    try {
      const { data } = await contractAPI.getWalletsLogs(
        wallet,
        action,
        contract
      )
      commit("WALLET_TRACKING_DETAILS", data)
    } catch (err) {
      console.log(err)
    }
  },

  async FETCH_TRACKING_TOTAL_BY_WALLET({ commit }, [wallet, contract]) {
    try {
      const { data } = await contractAPI.getWalletTotalLogs(wallet, contract)
      commit("WALLET_TRACKING_TOTAL", data)
    } catch (err) {
      console.log(err)
    }
  },
}
