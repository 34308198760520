import i18n from "../../../plugins/i18n";

export default {
  web3: {
    account: null
  },
  wallet: {
    balance: null,
    loading: false
  },
  wallets: [],
  isSync: false,
  syncInfo: false,
  factory: {
    factoryABI: null,
    erc1155ModelABI: null
  },
  isFactoryOwner: false,
  token: {
    send: [],
    receiver: [],
    settle: []
  },
  amount: {
    sent: null,
    received: null,
    settled: null
  },
  tracking: [],
  products: [],
  contractInstance: null,

  loadings: {
    settle: false,
    send: false,
    start: false,
    pause: false,
    pending: false,
    primary: false,
    secondary: false
  },

  lots: [],

  tokenList: [],
  contractsList: [],
  contractsJsonList: [],
  contractHistory: [],

  roles: [
    {
      id: 3,
      name: 'tokens.roles.admin',
      role: 'DEFAULT_ADMIN',
      address: '0x0000000000000000000000000000000000000000000000000000000000000000'
    }, {
      id: 1,
      name: 'tokens.roles.customer',
      role: 'CUSTOMER',
      address: '0x288a15c4a15d470e4cd9cad2f113b91206b520c26dbd3dd74627f0c057baa19c'
    }, {
      id: 2,
      name: 'tokens.roles.finalCustomer',
      role: 'FINAL_CUSTOMER',
      address: '0xd21dc0a5da03a23c21bdcf904b058f54773a60cc628540c7d5513088c76602cd'
    },
  ],
}
