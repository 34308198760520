import LanguajeService from "../../../../services/LanguajeService";
import sortList from "../../../../services/OrderListService";

export default {
    allUnits: state => sortList.orderListByUppercase(_.map(state.allUnits, (item) => {
        return {
            ...item,
            unit_name: LanguajeService.getKey3(item.languageKey),
            measurementUnitGroupName: LanguajeService.getKey3(state.allGroupUnits.find(x => x.id === item.measurementUnitGroupId).language_key),
            objectRelId: item.id
            // baseMeasurementUnitName: item.base_measurement_unit ? state.allUnits.find(x => x.id === item.base_measurement_unit).name: item.base_measurement_unit
        };
    }), 'unit_name'),
    allObjectUnits: state => _.map(state.allUnits, (item) => {
        return {
            ...item,
            unit_name: LanguajeService.getKey3(item.languageKey),
            objectRelId: item.id
        };
    }),
    // Wallet
    allObjectUnitsWallet: state => _.map(state.allUnits, (item) => {
        return {
            ...item,
            unit_name: LanguajeService.getKey3(item.languageKey) === 'Tonelada' ? 'Token': LanguajeService.getKey3(item.languageKey),
            objectRelId: item.id
        };
    }),
    allGroupUnits: state => _.map(state.allGroupUnits, (item) => {
        return {
            ...item,
            measurementUnitGroupName: LanguajeService.getKey3(item.language_key)
        };
    }),
    allUnitsWhithObjects: state => _.map(state.allUnits, (item) => {
        return {
            ...item,
            unit_name: LanguajeService.getKey3(item.languageKey),
            measurementUnitGroup: state.allGroupUnits.find(x => x.id === item.measurementUnitGroupId),
            baseMeasurementUnit: item.base_measurement_unit ? state.allUnits.find(x => x.id === item.base_measurement_unit) : null
        };
    }),
    loading: state => state.loading
}
