import _ from "lodash";
import LanguajeService from "../../../../services/LanguajeService";


export default {
    groupDetails: state => _.map(state.groupDetails, (item) => {
        return {
            ...item,
            group_details_name: LanguajeService.getKey3(item.languageKey),
            compress: true,
            structure: _.map(item.structure, (details) => {
                return {
                    ...details,
                    details_name: LanguajeService.getKey3(details.language_key)

                };
            })
        };
    }),
    groupDetailsByCompany: state => _.map(state.groupDetailsByCompany, (item) => {
        return {
            ...item,
            group_details_name:LanguajeService.getKey3(item.languageKey),
            compress: true,
            structure: _.map(item.structure, (details) => {
                return {
                    ...details,
                    details_name:LanguajeService.getKey3(details.language_key),

                };
            })
        };
    }),
    groupDetailsDefaults: state => _.map(state.groupDetailsDefaults, (item) => {
        return {
            ...item,
            group_details_name: LanguajeService.getKey3(item.languageKey),
            compress: true,
            structure: _.map(item.structure, (details) => {
                return {
                    ...details,
                    details_name: LanguajeService.getKey3(details.language_key)
                };
            })
        };
    }),
    loading: state => state.loading,
}
