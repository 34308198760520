export default {
    proccess: [],
    loading: true,
    procces: {
        company: null,
        language_key: null,
        sdt_to_show: null,
        processType: null,
        isInternal: true,
        processStructureOrganized: null,
        reportType: null,
        automaticTraceability: false,
        massBalanceConfiguration: false,
        convertionFactor: null,
        printLimit: 0,
        redirectQr: null
    },
    structuresSelected: [],
    groupStructuresSelected: [],
}