import { default as http } from "./HttpService";
import LanguajeService from "./LanguajeService";

class ProductService {
  constructor() {}

  products(filters) {
    return http.client.post(`v1/product/string_filter`, {
      "companyId": filters.companyId,
      "stringFilter": filters.search,
      "pageableDTO": filters.pageableDTO,
      "language": LanguajeService.getLenguajeName()
    });
  }

  productsByRecord(data) {
    return http.client.post(`v1/product/get-into-record`, data);
  }

  import(company, file) {
    let fd = new FormData();
    fd.append("company_id", company);
    fd.append("file", file);

    return http.client.post(`/v1/product/excel`, fd, {
      headers: { "Content-Type": "multipart/form-data" },
    });
  }

  export(data) {
    return http.client.post(`/v1/product/export/excel`, data, {
      responseType: "blob",
    });
  }

  downloadTemplate(language) {
    return http.client.get(`/v1/product/template/${language}`, {
      responseType: "blob",
    });
  }

  getEvidences(product) {
    return http.client.get(`/v1/product/${product.id}/zip`, {
      responseType: "blob",
      headers: {
        Accept: "application/zip",
      },
    });
  }

  save(item, documents_file, editOrSave) {
    let fd = new FormData();
    fd.append("product_json", JSON.stringify(item));
    if (documents_file.length > 0) {
      _.forEach(documents_file, (file) => {
        fd.append("documents_file", file);
      });
    }
    if (editOrSave > -1) {
      return http.client.put("/v1/product", fd, {
        headers: { "Content-Type": "multipart/form-data" },
      });
    } else {
      return http.client.post("/v1/product", fd, {
        headers: { "Content-Type": "multipart/form-data" },
      });
    }
  }

  delete(id) {
    return http.client.delete("/v1/product/" + id);
  }
}

export default new ProductService();
