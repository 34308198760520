import Pbf from "pbf"
import geobuf from "geobuf"
import _ from "lodash";
import axios from "axios";

class MapService {
  constructor() {}
  countryList() {
    // Service Not more free
    // return axios({url: `https://restcountries.eu/rest/v2/all`, method: 'GET'});
    // Service free
    return axios({url: `https://flagcdn.com/pt/codes.json`, method: 'GET'});
  }

  async convertGeobufToGeoJSON(l) {
    try {
      if (l.geoData && l.geoData.s3_url) {
        await fetch(l.geoData.s3_url)
            .then(r => r.arrayBuffer())
            .then(arrayBuf => {
              // Decodifica los datos Geobuf
              const data = new Pbf(new Uint8Array(arrayBuf))
              l.location = JSON.stringify(geobuf.decode(data))
            })
      } else {
        console.log("Url de Geobuf no encontrada o propeidad geoData null.")
      }
    } catch (error) {
      console.error("Error al convertir Geobuf a GeoJSON:", error)
      l.location = null
    }
  }

  async singleLocationFormat(location) {
    const promises = []
    promises.push(this.convertGeobufToGeoJSON(location))
    await Promise.all(promises);
    return location
  }

  async mergeLocationsFormats(locationsList) {
    let geobufLocations = _.filter(locationsList, function(location) {
      return location.locationFormat === "GEOBUF" || location.locationFormat === "geobuf";
    });
    let geojsonLocations = _.filter(locationsList, function(location) {
      return location.locationFormat === "GEOJSON" || location.locationFormat === "geojson";
    });

    const promises = []
    for (const l of geobufLocations) {
      // add here a list of promises;
      promises.push(this.convertGeobufToGeoJSON(l))
    }
    await Promise.all(promises)
    return geobufLocations.concat(geojsonLocations)
  }
}

export default new MapService()
