import axios, {Cancel} from 'axios';

import store from '@/store';
import AuthService from "./AuthService";

export const client = axios.create({
    baseURL: process.env.VUE_APP_BACK_API,
    timeout: 1000000
});

export const test = axios.create({
    baseURL: process.env.VUE_APP_BACK_API_TEST,
    timeout: 1000000
});

export const s3 = axios.create({
    baseURL: '',
    timeout: 1000000
});

export const clientServer = axios.create({
    baseURL: process.env.VUE_APP_BACK_SERVER,
    timeout: 1000000
});

export const mapBoxAPI = axios.create({
    baseURL: process.env.VUE_APP_MAPBOX_API,
    timeout: 1000000
});

export const maps = axios.create({
    baseURL: process.env.VUE_APP_MAP_SERVER,
    timeout: 1000000
});

export const recordLog = axios.create({
    baseURL: process.env.VUE_APP_LOGS_API,
    timeout: 1000000
});

export const countryApiURI = axios.create({
    baseURL: process.env.VUE_APP_CUSTOM_COUNTRIES_API_URL,
    timeout: 1000000,
    headers: {
        'Content-Type': 'application/json'
    }
})

const excludeUrls = ['/login', '/label_traceability'];

const config = (config) => {
    let token = localStorage.getItem('token');
    let company = JSON.parse(localStorage.getItem('company:current'));

    const refresh_token = localStorage.getItem('refresh_token');
    if (token) {
        if (company)
            config.headers['x-company-key'] = `${company.identity}`;

        const time_token = AuthService.parseJwt(token);
        const time_refresh = AuthService.parseJwt(refresh_token);
        const timeLeft_token = AuthService.isTokenExpired(time_token.exp);
        const timeLeft_refresh = AuthService.isTokenExpired(time_refresh.exp);
        if (timeLeft_token < 900 && timeLeft_refresh > 120 && !store.getters['login/refresh']) {
            store.state.login.refresh = true;
            store.dispatch('login/refresh', [refresh_token, token]);
        } else if (timeLeft_refresh <= 120 || timeLeft_token < 120) {
            throw new axios.Cancel('tokenExpire');
        }
        if (!config.url.includes("/v1/profile/refresh_token"))
            config.headers['x-auth-token'] = `${token}`;
    } else {
        const isExcluded = excludeUrls.some(url => location.href.includes(url));
        if (!isExcluded) {
            localStorage.clear()
            location.reload()
        }
    }

    return config;
};

const errorRequest = (error) => Promise.reject(error)

const response = (response) => response

const errorResponse = (error) => {
    if (error instanceof Cancel) {
        if (error.message === 'cancelRequest') {
            return Promise.reject(error);
        } else if (error.message === 'tokenExpire') {
            localStorage.clear()
            location.reload()
        }
    } else {
        if (error.message !== 'Network Error') {
            const {status} = error.response;
            if ([401].includes(status) && store.getters['login/isLoggedIn']) {
                localStorage.clear()
                location.reload()
            } else {
                return Promise.reject(error);
            }
        } else {
            Promise.reject(error);
        }
    }
    return Promise.reject(error);
};

recordLog.interceptors.request.use(config, errorRequest);
recordLog.interceptors.response.use(response, errorResponse);

client.interceptors.request.use(config, errorRequest);
client.interceptors.response.use(response, errorResponse);

test.interceptors.request.use(config, errorRequest);
test.interceptors.response.use(response, errorResponse);

export default {s3, client, clientServer, mapBoxAPI, maps, test, recordLog}
