import {default as http} from './HttpService'

class SurveyService {
    constructor() {
    }

    surveyTemplate(surveyTemplateFind) {
        return http.client.post(`v1/survey/survey_templates`, surveyTemplateFind);
    }

    surveyTemplateLight(filters) {
        return http.client.post(`v1/survey/light`, filters);
    }

    surveyTemplatesForFilters(ids) {
        return http.client.post(`v1/survey/survey_templates_to_filters`, ids);
    }

    surveyById(id) {
        return http.client.get(`v1/survey/` + id);
    }

    sectionById(id) {
        return http.client.get(`v1/question_section/` + id);
    }

    save(item, editOrSave) {
        if (editOrSave > -1) {
            return http.client.put('/v1/survey', item);
        } else {
            return http.client.post('/v1/survey', item);
        }
    }

    saveQuestion(item, editOrSave) {
        if (editOrSave > -1) {
            return http.client.put('/v1/question', item);
        } else {
            return http.client.post('/v1/question', item);
        }
    }

    saveSection(item, editOrSave) {
        if (editOrSave > -1) {
            return http.client.put('/v1/question_section', item);
        } else {
            return http.client.post('/v1/question_section', item);
        }
    }

    delete(id) {
        return http.client.delete('/v1/survey/' + id);
    }

    questionDelete(id) {
        return http.client.delete('/v1/question/' + id);
    }

    questionDeleteWithDependents(id) {
        return http.client.delete('/v1/question/with_dependents/' + id);
    }

    questionSectionDelete(id) {
        return http.client.delete('/v1/question_section/' + id);
    }

    change_state(chageStateDTO) {
        return http.client.put('/v1/survey/change_state', chageStateDTO);
    }

    getEvidencas(questionId) {
        return http.client.get(`v1/question/${questionId}/zip`,{
            responseType: 'blob',
            headers: {
                'Accept': 'application/zip'
            }
        });
    }

    blockTemplate(surveyId) {
        return http.client.put(`/v1/survey/blocked_state/${surveyId}`);
    }
}

export default new SurveyService()
