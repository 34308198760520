<template>
  <v-container
    fluid
    class="px-0"
    style="padding-left: 0 !important; padding-right: 0 !important"
  >
    <v-app v-if="rastro">
      <v-app-bar
        extension-height="116"
        elevation="1"
        height="190"
        app
        color="secondary"
        dark
        :src="fondo"
      >
        <template v-slot:img="{ props }">
          <v-img v-bind="props"></v-img>
        </template>
        <template v-slot:default>
          <v-container class="mb-3 pt-0">
            <v-row class="pb-6">
              <v-img contain position="left" height="150px" :src="logo"></v-img>
            </v-row>
            <v-row>
              <v-toolbar-title
                class="text-center pr-6"
                style="
                  width: 100%;
                  font-family: 'Roboto', sans-serif;
                  font-weight: normal;
                "
              >
              </v-toolbar-title>
            </v-row>
          </v-container>
        </template>
        <template v-slot:extension>
          <v-card light tile outlined style="width: 100%">
            <v-card-text class="px-0 py-0">
              <v-row no-gutters class="mb-0">
                <v-col cols="12">
                  <h5
                    v-if="product"
                    style="background-color: #03ac8c; height: 7px"
                  >
                    <span
                      class="white--text"
                      style="
                        font-size: 18px;
                        font-family: 'Roboto', sans-serif;
                        font-weight: normal;
                      "
                    ></span>
                  </h5>
                  <h5 v-if="product" class="pl-2">
                    <span
                      style="
                        font-size: 18px;
                        font-family: 'Roboto', sans-serif;
                        font-weight: normal;
                      "
                      >{{ product.toUpperCase() }}</span
                    >
                  </h5>
                  <h5 class="pl-2" v-if="lot || safra">
                    {{
                      lot
                        ? $t("rastreo.fields.lote")
                        : $t("rastreo.fields.season")
                    }}:
                    <span
                      class="secondary--text"
                      style="
                        font-family: 'Roboto', sans-serif;
                        font-weight: normal;
                      "
                      >{{ lot ? lot : safra }}</span
                    >
                  </h5>
                  <h5 class="pl-2" v-if="allAddressDistributed !== ''">
                    {{ $t("records.label.titles.distributedBy") }}:
                    <span
                      class="secondary--text"
                      style="
                        font-family: 'Roboto', sans-serif;
                        font-weight: normal;
                      "
                      >{{ allAddressDistributed }}</span
                    >
                  </h5>
                  <h5 class="pl-2" v-if="allAddressProduced !== ''">
                    {{ $t("records.label.titles.producedBy") }}:
                    <span
                      class="secondary--text"
                      style="
                        font-family: 'Roboto', sans-serif;
                        font-weight: normal;
                      "
                      >{{ allAddressProduced }}</span
                    >
                  </h5>
                </v-col>
              </v-row>
              <v-divider></v-divider>
            </v-card-text>
            <v-card-actions class="py-0 px-0">
              <v-bottom-navigation
                grow
                mandatory
                class="elevation-0 py-0 align-self-end borderNavigation"
                color="secondary"
              >
                <v-btn
                  v-if="!!rastro ? rastro.record.company : false"
                  link
                  exact
                  :to="'/product_traceability/' + label_code"
                >
                  <span>{{ $t("rastreo.menu_final.map") }}</span>
                  <v-icon>mdi-map-marker</v-icon>
                </v-btn>
              </v-bottom-navigation>
            </v-card-actions>
          </v-card>
        </template>
      </v-app-bar>
      <v-main>
        <v-bottom-sheet hide-overlay v-model="sheet" v-if="sheet" persistent>
          <v-sheet class="text-center" height="390px">
            <v-btn
              fab
              elevation="3"
              style="z-index: 10"
              color="secondary"
              id="btngf"
              x-small
              @click="sheet = !sheet"
              class="mt-n8"
            >
              <v-icon>mdi-chevron-down</v-icon>
            </v-btn>
            <v-toolbar
              dark
              color="secondary"
              class="mt-n8"
              height="36"
              flat
              tile
            >
            </v-toolbar>
            <component
              :procedure="!!rastro ? rastro.record.process : ''"
              :location="locationsProperties"
              :cert="!!rastro ? rastro.record.certificates : ''"
              :testImages="testImages"
              :keyP="keyP"
              :is="detailType"
              :image="imageName"
            >
            </component>
          </v-sheet>
        </v-bottom-sheet>
        <v-container class="px-0 py-0" id="applicationContainer" fluid>
          <router-view @openSheet="openSheet"></router-view>
        </v-container>
      </v-main>
    </v-app>
    <v-card
      class="pa-md-4 mx-lg-auto"
      width="100%"
      height="800px"
      elevation="0"
      v-if="!!rastro ? rastro.record.isDelete === true : false"
    >
      <v-card-text justify="center" align="center" style="padding-top: 200px">
        <v-row>
          <v-col cols="12">
            <h1 style="font-size: 88px" class="pb-12">:(</h1>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12"> </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" class="mt-12">
            <span style="padding-top: 400px">{{
              $t("rastreo.titles.:)")
            }}</span>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import Procedures from "@/module/final/details/Procedures";
import Certificate from "@/module/final/details/Certificate";
import Image from "@/module/final/details/Image";
import MapTracking from "@/module/final/details/MapTracking";
import { mapActions, mapGetters } from "vuex";

export default {
  name: "Final",
  components: { Certificate, Procedures, ImageComp: Image, MapTracking },
  data: () => ({
    label_code: null,
    rastro: null,
    lot: null,
    product: null,
    safra: null,
    time: new Date().toISOString().split("T").pop().split(".").shift(),
    sheet: false,
    height: 30,
    detailType: "",
    imageName: "",
    locationsProperties: {},
    keyP: "",
    testImages: [],
    logo: require("@/assets/img/final/agtrace_logo.png"),
    fondo: require("@/assets/img/final/fondo3.jpg"),
    background: require("@/assets/img/background_movil.jpg"),
    timeline: "",
    dallery: "",
    map: "",
    cert: "",
    variable: {
      L: "L",
      P: "P",
      S: "S",
    },
    allAddressProduced: "",
    allAddressDistributed: "",
  }),
  computed: {
    recordSelected: {
      get() {
        return this.$store.getters["final/recordTrackindData"];
      },
      set(val) {
        this.$store.commit("final/SET_RECORD_TRACKING_DATA", val);
      },
    },
    ...mapGetters({
      loading: "final/loading",
    }),
  },
  created() {
    if (this.$route.params.label_code) {
      this.label_code = this.$route.params.label_code;
      console.log(this.label_code)
      localStorage.setItem("record", this.label_code);
      this.load(this.label_code);
    } else {
      this.label_code = localStorage.getItem("record");
      console.log(this.label_code)
      this.load(this.label_code);
      this.$router.push({
        name: "final",
        params: { label_code: localStorage.getItem("record") },
      });
    }
  },
  methods: {
    ...mapActions({
      findRecordById: "final/findRecordTrackingById",
    }),
    openSheet([type, extra, keyP, properties]) {
      this.detailType = "";
      this.detailType = type;
      this.imageName = extra;
      this.locationsProperties = properties;
      this.keyP = keyP;
      this.sheet = true;
    },
    load(label_code) {
      this.findRecordById([label_code, this.$toast]).finally(() => {
        this.rastro = _.cloneDeep(this.recordSelected);
        console.log(this.rastro)
        this.product = this.rastro.productDescription;
        this.safra = this.rastro.season;
        const location_origin = this.rastro.record.sdi_origin.primaryLocation;
        const location_destination =
          this.rastro.record.sdi_destination.primaryLocation;
        this.allAddressProduced =
          location_origin.name +
          " " +
          this.checkIfExist(location_origin.document_key, true, ": ") +
          this.checkIfExist(location_origin.document_value, true, ", ") +
          this.checkIfExist(location_origin.address1, true, ", ") +
          this.checkIfExist(location_origin.city, true, ", ") +
          this.checkIfExist(location_origin.state, true, ", ") +
          this.checkIfExist(location_origin.country, false);
        this.allAddressDistributed =
          location_destination.name +
          " " +
          this.checkIfExist(location_destination.document_key, true, ": ") +
          this.checkIfExist(location_destination.document_value, true, ", ") +
          this.checkIfExist(location_destination.address1, true, ", ") +
          this.checkIfExist(location_destination.city, true, ", ") +
          this.checkIfExist(location_destination.state, true, ", ") +
          this.checkIfExist(location_destination.country, false);
      });
    },
    checkIfExist(target, continueTrue, separator) {
      target = target ? target : "";
      continueTrue = continueTrue && target !== "" ? separator : "";
      return target + continueTrue;
    },
  },
};
</script>
<style scoped>
>>> .v-toolbar__image .v-image {
  border-radius: inherit;
  height: 200px !important;
}

>>> html {
  overflow-y: hidden;
}

>>> .v-toolbar__extension {
  padding: 0 !important;
  vertical-align: bottom;
}

>>> .borderNavigation {
  border-bottom: #03ac8c;
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-top-style: solid;
  border-top-width: 1px;
}
</style>
