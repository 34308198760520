import LanguajeService from "../../../../services/LanguajeService";

export default {
    roles: state => _.map(state.roles, (item) => {
        return {
            ...item,
            name: LanguajeService.getKey3(item.language_key)
        };
    }),
    modules: state => state.modules,
    loading: state => state.loading,
}
