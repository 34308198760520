import i18n from '@/plugins/i18n'

export default {
    loading: true,
    recordsByCompany: [],
    record: {
        company: null,
        process: null,
        person_in_charge: null,
        originApp: null,
        sdi_origin: null,
        sdi_destination: null,
        record_date_time: new Date().toJSON(),
        sent_date_time: new Date().toJSON(),
        recordStructureValuesOrganized: {},
        evidences: {},
    },
    evidences: [],
    recordsForPrint: [],
    request: false,
    source: null,
    recordsByCompanyTotalCount: null,
    recordsToExports: [],
    recordsToTraceabilityReport: [],
    recordsByCompanyFiltered: [],
    tabIndex: -1,
    structures: [],
    recordsByCompanyWithoutStage: [],
    recordsByCompanyTotalCountWithoutStage: 0,
    cantToExport: 0,
    dialogDetails: false,

    defaultHeaders: [
        {
            text: "",
            value: "statusLine",
            sortable: false,
        },
        {
            text: "records.fields.status",
            value: "status",
            val: "status",
            type: 'text'
        },
        {
            text: "records.fields.code",
            value: "uniqueIdRepresentation",
            val: "uniqueIdRepresentation",
            sortable: true,
        },
        {
            text: 'records.fields.origin',
            value: 'sdi_origin',
            val: 'sdi_origin',
            type: 'text',
            sortable: false
        },
        {
            text: 'records.fields.destination',
            value: 'sdi_destination',
            val: 'sdi_destination',
            type: 'text',
            sortable: false
        },
        {
            text: 'records.fields.date',
            value: 'record_date_time',
            val: 'record_date_time',
            type: 'text',
            width: 150
        },
        {
            text: 'records.fields.process',
            value: 'process_name',
            val: 'process',
            type: 'text',
            sortable: false
        },
        {
            text: 'records.fields.person',
            value: 'person_in_charge_name',
            val: 'person_in_charge',
            type: 'text',
            width: 170,
            sortable: false
        },
        {
            text: 'general.fields.action',
            value: 'action',
            width: 100,
            sortable: false,
            type: 'actions',
            align: 'center'
        },
    ],

    headers: [],
    recordLog: {
        recordById: null,
        actionExecute: '',
        insertionDate: '',
        blockchainHash: null,
        url: '',
        blockchainSave: false
    },
    checkBurdensError: false,
    checkBurdensErrorData: []
}
