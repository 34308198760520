import { default as http } from '@/services/HttpService';

class DashboardService {
    // Crear un dashboard
    saveDashboard(payload) {
        console.log(payload)
        if (payload.id) {
            return http.client.put('/v1/dashboard', payload);
        }
        return http.client.post('/v1/dashboard', payload);
    }

    // Eliminar un layout y sus configuraciones.
    deleteLayout(layoutId) {
        return http.client.delete(`/v1/dashboard/${layoutId}`);
    }

    // Obtener el config de un layout específico
    getLayoutConfig(layoutId) {
        return http.client.get(`/v1/dashboard/layout/${layoutId}/config`);
    }

    // Obtener los valores de un widget específico
    getWidgetValues(widgetId, filters) {
        return http.client.post(`/v1/dashboard/widget/${widgetId}/data`, filters);
    }

    // Enviar un dashboard
    sendDashboard(payload) {
        return http.client.post('/v1/dashboard/layout/share', payload);
    }

    // Listar todos los layouts del usuario
    listUserLayouts() {
        return http.client.get('/v1/dashboard/layout/config');
    }

    // Lista un archivo en el s3
    uploadFile(file) {
        const formData = new FormData();
        formData.append('file', file);

        return http.client.post('/v1/dashboard/file/upload', formData, {headers: {'Content-Type': 'multipart/form-data'}});
    }

    // Añadir permisos de usuario a un dashboard
    setDefault(dashboardId) {
        return http.client.put(`/v1/dashboard/set-default/${dashboardId}`);
    }

    // Añadir permisos de usuario a un dashboard
    addUserPermissions(payload) {
        return http.client.post('/v1/dashboard/user-permissions', payload);
    }

    // Añadir permisos de usuario a un dashboard
    getUserPermissions(dashboardId) {
        return http.client.get(`/v1/dashboard/user-permissions/${dashboardId}`);
    }

    // Desvincularse de un dashboard compartido
    removeSharedDashboard(dashboardId) {
        return http.client.get(`/v1/dashboard/unsubscribe/${dashboardId}`);
    }

    // Clonar un dashboard
    cloneDashboard(dashboardId) {
        const profile = JSON.parse(localStorage.getItem('profile'));
        return http.client.post('/v1/dashboard/layout/share', {
            dashboardId: dashboardId,
            recipients: [profile.id]
        });
    }

    transformData(dataArray) {
        return dataArray.map(item => ({
            type: item.type,
            subtype: item.subType,
            applicableFilters: item.applicableFilters,
            props: {
                title: item.title,
                values: item.value ? [item.value.toString() + " Unit"] : [Math.random().toFixed(2) + " Unit"],
                icon: item.icon || "mdi-card-bulleted",
                color: item.color || "#232323",
                itemsLimit: item.itemsLimit || 0,
                filter: item.filter || null,
                format: item.format,
                id: item.id
            },
            w: item.gridConfig.w,
            h: item.gridConfig.h,
            x: item.gridConfig.x,
            y: item.gridConfig.y,
            i: item.gridConfig.i,
            initialized: true,
            valid: true
        }));
    }
}

export default new DashboardService();
