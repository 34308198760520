import * as types from './mutations-type.js';

export default {
    [types.SET_LOCATIONS_LIST](state, data) {
        state.locationsList = data.content;
        state.totalFromServer = data.totalElements;
    },
    [types.SET_S_LOCATIONS](state, data) {
        state.sLocations = data;
    },
    [types.SET_LOCATIONS_LIST_FOR_FILTERS](state, data) {
        state.locationsListForFilters = data.content;
        state.totalFromServerForFilters = data.totalElements;
    },
    [types.SET_LOCATIONS_FOR_FILTERS](state, data) {
        state.locationsForFilters = data;
    },
    [types.RESTORE_LOCATIONS_FOR_FILTERS](state) {
        state.locationsForFilters = [];
    },
    [types.RESTORE_LOCATIONS_LIST](state) {
        state.locationsList = [];
        state.totalFromServer = 0;
    },
    [types.SET_ACTIVE_LOCATIONS_LIST](state, data) {
        state.activeLocationsList = data;
    },
    [types.SET_LOADING_ON](state, data) {
        state.loading = data;
    },
    [types.SET_LOADING_OFF](state, data) {
        state.loading = data;
    },
    [types.SET_MAP_CONFIG](state, data) {
        state.mapConfig = data;
    },
    [types.SET_PERSONS_IN_CHARGE](state, data) {
        state.personInCharge = data;
    },
    [types.RESTORE_PERSONS_IN_CHARGE](state) {
        state.personInCharge = [];
    },
    [types.SET_LOCATION_BY_USER_ACCESS](state, data) {
        state.locationByUserAccess = data.content;
        state.totalFromServer = data.totalElements;
    },
    [types.RESTORE_LOCATION_BY_USER_ACCESS](state) {
        state.locationByUserAccess = [];
        state.totalFromServer = 0;
    },
    [types.SET_VERSIONS_LIST](state, data) {
        state.versions = data.content;
        state.totalFromServerVersions = data.totalElements;
    },
    [types.RESTORE_VERSIONS_DATA](state) {
        state.versions = [];
        state.totalFromServerVersions = 0;
    },
    [types.SET_SELECTED_COLUMNS](state, data) {
        state.selectedColumns = data;
    },
    [types.SET_CLASSIFICATIONS](state, data) {
        state.classifications = data;
    },
    [types.SET_BADGE_CLASSIFICATIONS](state, data) {
        state.badgeClassifications = data;
    },
}
