import * as types from './mutations-type.js';

export default {
    [types.SET_ROLE_LIST](state, data) {
        state.roles = data;
    },
    [types.SET_MODULE_LIST](state, data) {
        state.modules = data;
    },
    [types.SET_LOADING_ON](state, data) {
        state.loading = data;
    },
    [types.SET_LOADING_OFF](state, data) {
        state.loading = data;
    },

}
