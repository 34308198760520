import i18n from '@/plugins/i18n';

export default {
    mapConfig: () => {
        return {
            sources: [
                {
                    id: 'TrackingSource',
                    config:{
                        type: 'geojson',
                        data: {type: 'FeatureCollection', features: []}
                    }
                }
            ],
            layers: [
                {
                    id: 'TrackingLayer',
                    typeLayers: 'fill',
                    source: 'TrackingSource'
                },
                {
                    id: 'TrackingNamesLayer',
                    typeLayers: 'symbol',
                    source: 'TrackingSource'
                }
            ]
        }
    },
    trackingData: state => state.trackingData,
    loading: state => state.loading
}
