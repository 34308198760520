<template>
  <v-img :src="require('@/assets/img/HyperT.svg')" contain height="14" width="14" :srcset="require('@/assets/img/HyperT.svg')"/>
</template>

<script>
  export default {
    name: 'HypertIcon'
  }
</script>

<style scoped>

</style>