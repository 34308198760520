import * as types from './mutations-type.js';
import { SET_TEMPLATES_FOR_FILTERS } from "./mutations-type.js"


export default {
    [types.SET_SURVEY_BY_COMPANY_LIST](state, data) {
        state.surveyList = data;
    },
    [types.SET_SURVEY_TEMPLATE_BY_COMPANY_LIST](state, data) {
        state.surveyTemplateList = data;
    },
    [types.SET_ALL_TEMPLATES_TO_EXPORT](state, data) {
        state.allTemplates = data;
    },
    [types.SET_TEMPLATES_FOR_FILTERS](state, data) {
        state.templatesForFilters = data;
    },
    [types.SET_SURVEY_BY_ID](state, data) {
        state.surveyEdit = data;
    },
    [types.SET_SECTION_BY_ID](state, data) {
        state.sectionEdit = data;
    },
    [types.SET_QUESTION_DELETE](state, data) {
        state.deletedQuestionsNotifications = data;
    },
    [types.SET_LOADING_ON](state, data) {
        state.loading = data;
    },
    [types.SET_LOADING_OFF](state, data) {
        state.loading = data;
    },
    [types.SET_ERROR_ON](state, data) {
        state.error = data;
    },
    [types.SET_ERROR_OFF](state, data) {
        state.error = data;
    },
}
