import * as types from './mutations-type.js';
import _ from "lodash";

export default {
    [types.SET_LOADING_ON](state, data) {
        state.loading = data;
    },
    [types.SET_LOADING_OFF](state, data) {
        state.loading = data;
    },
    [types.SET_APPOINTMENTS_BY_COMPANY_PAGINATED](state, data) {
        state.recordsByCompany = data.content;
        state.recordsByCompanyTotalCount = data.totalElements;
    },
    [types.SET_APPOINTMENTS_BY_COMPANY_FILTERED](state, data) {
        state.prerecordsByCompanyFiltered = data;
    },
    [types.SET_STORAGE_TYPE_FOR_RECORD_LIST](state, data) {
        state.storageDeviceTypesActiveByCompany = data;
    },
    [types.EXPORT](state, data) {
        state.exportUrl = data;
    },
    [types.SET_APPOINTMENTS_BY_PROCESS](state, data) {
        state.recordsToExports = data;
    },
    [types.SET_APPOINTMENTS_BY_LOTE](state, data) {
        state.recordsToTraceabilityReport = data;
    },
    [types.SET_TAB_INDEX](state, data) {
        state.tabIndex = data.data;
    },
    [types.SET_STRUCTURES_LIST](state, data) {
        state.structures = data;
    },

    [types.SET_PRE_RECORD](state, data) {
        state.preRecord = data;
    },

    [types.SET_SOURCE](state, data) {
        state.source = data;
    },
    [types.SET_REQUEST](state, data) {
        state.request = data;
    },

    [types.SET_ORIGINAL_STATE_PRE_RECORD](state) {
        state.preRecord = {
            company: null,
            process: null,
            person_in_charge: null,
            originApp: null,
            sdi_origin: null,
            sdi_destination: null,
            record_date_time: new Date().toJSON(),
            recordStructureValuesOrganized: {},
            evidences: {},
            hash: null,
            isDelete: null,
            latitude: null,
            longitude: null,
            sent_date_time: null,
            languageKey: null
        }
    },
}
