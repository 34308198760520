import * as types from './mutations-type.js';


export default {
    [types.SET_LABELS](state, data) {
        state.labels = data;
    },
    [types.SET_ORIGINAL_STATE_LABELS](state) {
        state.labels = [];
    },
    [types.SET_LOADING_ON](state, data) {
        state.loading = data;
    },
    [types.SET_LOADING_OFF](state, data) {
        state.loading = data;
    },
    [types.QRLABEL](state, data) {
        this.dispatch('general/setLayout', 'L_QrLabel');
    },
    [types.SET_RECORD_TRACKING_DATA](state, data) {
        state.recordTrackindData = data;
    },
}
