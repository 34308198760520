export function convertLatitude(latitude: number): string {
  const absLatitude = Math.abs(latitude);
  const degrees = Math.floor(absLatitude);
  const minutes = Math.floor((absLatitude - degrees) * 60);
  const seconds = ((absLatitude - degrees - minutes / 60) * 3600).toFixed(2);

  const direction = latitude >= 0 ? "N" : "S";

  return `${degrees}° ${minutes}' ${seconds}" ${direction}`;
}

export  function convertLongitude(longitude: number): string {
  const absLongitude = Math.abs(longitude);
  const degrees = Math.floor(absLongitude);
  const minutes = Math.floor((absLongitude - degrees) * 60);
  const seconds = ((absLongitude - degrees - minutes / 60) * 3600).toFixed(2);

  const direction = longitude >= 0 ? "E" : "W";

  return `${degrees}° ${minutes}' ${seconds}" ${direction}`;
}

export function isValidLatitude(input: string): boolean {
  const decimalDegreesRegex = /^-?([1-8]?[0-9](\.[0-9]+)?|90(\.0+)?)$/;

  if (!decimalDegreesRegex.test(input)) {
    return false;
  }

  const value = Number(input);
  return value >= -90 && value <= 90;
}

export function isValidLongitude(input: string): boolean {
  const decimalDegreesRegex = /^-?((1[0-7][0-9]|[1-9]?[0-9])(\.[0-9]+)?|180(\.0+)?)$/;

  if (!decimalDegreesRegex.test(input)) {
    return false;
  }

  const value = Number(input);
  return value >= -180 && value <= 180;
}


export  function isValidLatLong(input: string, isLat: boolean): boolean {
  if(isLat){
    return isValidLatitude(input)
  } else {
    return isValidLongitude(input)
  }
}