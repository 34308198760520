import * as types from './mutations-type.js';
import _ from 'lodash';

export default {
    [types.SET_LOADING](state, data) {
        state.loading = data;
    },
    [types.SET_LOADING_SELLERS](state, data) {
        state.loadingSellers = data;
    },
    [types.SET_LOADING_CONTRACTS](state, data) {
        state.loadingContracts = data;
    },
    [types.SET_DATA_STOCK](state, data) {
        _.forEach(data.totalsByLocations, (dataByLocation) => {
            state.totalsByLocations.push(dataByLocation);
        });
        state.totalsByLocations = _.orderBy(state.totalsByLocations, (dataByLocation) => {
            return dataByLocation.storageDevice.control_label;
        });
        state.totalsByProducts = data.totalsByProducts;
        state.generalTotals = data.generalTotals;
    },
    [types.SET_RECALCULATE_STOCK_INFO](state, data) {
        state.reCalculateInfo = data
    },

    // charts
    [types.SET_DATASETS](state, data) {
        state.datasets = data;
    },
    [types.SET_CHART](state, data) {
        state.chart = data;
    },
    [types.SET_ITEM_IN_SHEET](state, data) {
        state.itemInSheet = data;
    },

    // sheet
    [types.SET_SHEET_SELLER](state, data) {
        state.sheetSeller = data;
    },
    [types.SET_SHEET_CONTRACT](state, data) {
        state.sheetContract = data;
    },

    // contracts
    [types.SET_DATA_CONTRACTS](state, data) {
        state.contracts = data.content;
        state.totalContracts = data.totalElements;
    },
    [types.SET_CONTRACTS_NAMES](state, data) {
        state.contractsNames = data;
    },

    //sellers
    [types.SET_DATA_SELLERS](state, data) {
        state.sellers = data;
    },
    [types.SET_SELLERS_NAMES](state, data) {
        state.sellersNames = data;
    },

    //volumes & quebra
    [types.SET_QUEBRA](state, data) {
        state.quebra = data;
    },
    [types.SET_VOLUMES_NEGOTIATE](state, data) {
        state.volumesN = data;
    },
    [types.SET_VOLUMES_BENEFIT](state, data) {
        state.volumesB = data;
    },


    [types.SET_RECORDS](state, data) {
        state.records = data.content;
        state.totalRecordsFromServer = data.totalElements;
    },
    [types.SET_INSPECTIONS](state, data) {
        state.inspections = data.content;
        state.totalInspectionsFromServer = data.totalElements;
    },
    [types.SET_DOCUMENTS](state, data) {
        state.documents = data.content;
        state.totalDocumentsFromServer = data.totalElements;
    },
    [types.SET_BIORGANIC](state, data) {
        state.biorganic = data;
    },

    [types.SET_TRACEABILITY](state, data) {
        state.traceability = data.content;
        state.totalRTFromServer = data.totalElements;
    },

    [types.SET_QR_LIST](state, data) {
        state.qrList = data.content;
        state.totalQRFromServer = data.totalElements;
    },

    [types.SET_TRACEABILITYV2](state, data) {
        state.traceabilityV2 = data;
    },

    [types.SET_RECORDS_ORIGINAL_STATE](state) {
        state.records = [];
        state.totalRecordsFromServer = null
    },
    [types.SET_INSPECTIONS_ORIGINAL_STATE](state) {
        state.inspections = [];
        state.totalInspectionsFromServer = null
    },
    [types.SET_DOCUMENTS_ORIGINAL_STATE](state) {
        state.documents = [];
        state.totalDocumentsFromServer = null
    },
    [types.SET_BIORGANIC_ORIGINAL_STATE](state) {
        state.biorganic = [];
        state.totalBiorganicFromServer = null
    },
    [types.SET_TRACEABILITY_ORIGINAL_STATE](state) {
        state.traceability = [];
        state.totalRTFromServer = null
    },
    [types.SET_TRACEABILITYV2_ORIGINAL_STATE](state) {
        state.traceabilityV2 = [];
        state.totalTraceabilityV2FromServer = null
    },

    [types.SET_RECORDS_BY_IDS](state, payload) {
        state.recordsByIds = payload;
    },
    [types.SET_RECORDS_BY_IDS_ORIGINAL_STATE](state) {
        state.recordsByIds = [];
    },

    [types.SET_SOURCE](state, data) {
        state.source = data;
    },
    [types.SET_REQUEST](state, data) {
        state.request = data;
    },

    [types.SET_HEADERS_TRACEABILITY](state, data) {
        state.headersTraceability = data;
    },
}
