import _ from "lodash";
import LanguajeService from "../../../../services/LanguajeService";
import sortList from "../../../../services/OrderListService";
import i18n from '@/plugins/i18n';

export default {
    proccess: (state) => sortList.orderListByUppercase(_.map(state.proccess, (item) => {
        return {
            ...item,
            proccess_name: LanguajeService.getKey3(item.language_key),
            sdt_to_show_name: LanguajeService.getKey3(item.sdt_to_show.language_key)
        };
    }),'proccess_name'),
    proccessStructure: state => _.map(state.proccessStructure, (item) => {
        return {
            ...item,
            structure_name_translate: LanguajeService.getKey3(item.structure_name.language_key),
        };
    }),
    processTypeEnum() {
        return [
            {text: i18n.t('proccess.select.SHIPPING'), value: 'SHIPPING'},
            {text: i18n.t('proccess.select.PROCESSING'), value: 'PROCESSING'},
            {text: i18n.t('proccess.select.RECEIPT'), value: 'RECEIPT'},
            {text: i18n.t('proccess.select.AGRICULTURAL'), value: 'AGRICULTURAL'}
        ]
    },
    loading: state => state.loading,
    procces: state => ({
        ...state.procces,
        proccess_name: state.procces.language_key ? LanguajeService.getKey3(state.procces.language_key) : null,
        sdt_to_show_name: state.procces.language_key ? LanguajeService.getKey3(state.procces.sdt_to_show.language_key) : null,
        sdt_to_show: {
            ...state.procces.sdt_to_show,
            storage_type_name: (state.procces.language_key && state.procces.sdt_to_show) ? LanguajeService.getKey3(state.procces.sdt_to_show.language_key) : null
        }
    }),
    structuresSelected: state => state.structuresSelected,
    groupStructuresSelected: state => state.groupStructuresSelected
}
