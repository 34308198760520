import LanguajeService from "../../../services/LanguajeService";
import i18n from "../../../plugins/i18n";
import sortList from "../../../services/OrderListService";

export default {
    GET_LABELS: state => state.labels,
    lineas() {
        return 'Lineas-' + LanguajeService.getLenguajeName();
    },
    lineas110() {
        return 'Lineas110-' + LanguajeService.getLenguajeName();
    },
    labelsEnum() {
        return sortList.orderListByUppercase([
            {id: 3, text: 'Embalados – 25mm x 40mm', types: [{type: 'A4'}]},
            {id: 1, text: 'GS1-128 – 160mm x 50mm', types: [{type: 'A4'}, {type: 'DUPLO'}]},
            {id: 2, text: 'PLU – 160mm x 50mm', types: [{type: 'A4'}, {type: 'DUPLO'}]},
            {id: 4, text: 'IFCO – 160mm x 50mm', types: [{type: 'DUPLO'}]},
            {id: 6, text: 'IFCO SIMPLE – 145mm x 63mm', types: [{type: 'SIMPLE'}]},
            {id: 5, text: i18n.t('records.labelsTypes.product'), types: [{type: 'DUPLO'}, {type: 'A4'}]},
            {id: 8, text: i18n.t('records.labelsTypes.product3'), types: [{type: 'SIMPLE'}]},
            {id: 9, text: i18n.t('records.labelsTypes.product2'), types: [{type: 'SIMPLE'}]},
            {id: 10, text: 'GS1-128 (v2) – 160mm x 50mm', types: [{type: 'A4'}, {type: 'DUPLO'}]},
            {id: 11, text: 'Ambev – 100mm x 150mm', types: [{type: 'SIMPLE'}]},

        ], 'text')
    },
    labelsReportEnum() {
        return sortList.orderListByUppercase([
            {id: 3, text: 'QR de Rastreabilidade – 25mm x 40mm', types: [{type: 'A4'}]},
            {id: 1, text: 'Etiqueta Café torrado (pacote)', types: [{type: 'TRIPLA'}]},
            // {id: 2, text: 'Etiqueta Café verde (saca)', types: [{type: 'SIMPLE'}]},
        ], 'text')
    },
    loading: (state) => state.loading,
    recordTrackindData: state => state.recordTrackindData,
    mapConfig: () => {
        return {
            sources: [
                {
                    id: 'TrackingSource',
                    config:{
                        type: 'geojson',
                        data: {type: 'FeatureCollection', features: []}
                    }
                }
            ],
            layers: [
                {
                    id: 'TrackingLayer',
                    typeLayers: 'fill',
                    source: 'TrackingSource'
                },
                {
                    id: 'TrackingNamesLayer',
                    typeLayers: 'symbol',
                    source: 'TrackingSource'
                }
            ]
        }
    },
}
