import i18n from "../../../plugins/i18n";

export default {
    drawer: false,
    sidenav: true,
    loading: false,
    type: null,
    source: null,
    notification: false,
    status: [
        {
            id: 1,
            value: 'VALIDATED',
            name: i18n.t('records.filters.status.validated')
        },
        {
            id: 2,
            value: 'VALIDATING',
            name: i18n.t('records.filters.status.validating')
        },
        {
            id: 3,
            value: 'ERRORED',
            name: i18n.t('records.filters.status.errored')
        },
        {
            id: 4,
            value: 'PENDING',
            name: i18n.t('records.filters.status.pending')
        }
    ],
    nomenclator: {
        icon: 'mdi-check-all',
        color: 'secondary',
        message: 'Example Alert',
        import: 0,
        error: 0,
        total: 0
    },
    filtersSort: [],
    filters: {
        stringFilter: null,
        template_names: null,
        company_id: null,
        process_ids: [],
        classificationTypes: [],
        products_ids: [],
        seasons_ids: [],
        lots: [],
        person_in_charge_ids: [],
        sdi_origin_ids: [],
        sdi_destination_ids: [],
        start_record_date_time: null,
        end_record_date_time: [],
        sent_date_time: null,
        structure: [],
        structureIds: [],
        pageableDTO: null,
        record_ids: [],
        storage_device_types_ids: [],
        min_punctuation: 0,
        max_punctuation: 100,
        minArea: null,
        maxArea: null,
        document_authority_ids: [],
        withExternalVisibility: null,
        document_type_ids: [],
        document_description_ids: [],
        storage_devices_ids: [],
        document_date: [],
        expiration_date: [],
        isActive: null,
        productive: null,
        isExpirated: null,
        isPublic: null,
        isActiveOnMobile: null,
        sellers_ids: [],
        contracts_names: [],
        volumeB_range: [],
        volumeN_range: [],
        quebra_range: [],
        contracts_dates: [],
        sellers_dates: [],
        answers: [],
        questions: [],
        reportType: null,
        status: [],
        primaryLocationIds: [],
        ids: []
    },
    producerFilters: {
      stringFilter: null,
      locations: [],
      storageDevices: [],
      name: null,
      cpf: null,
      rg: null,
      address: null,
      state: null,
      city: null,
      country: null,
      zipCode: null,
      phone: null,
      email: null,
      isActive: null
    },
    questions: [],
    selectedQuestions: [],
    answers: [],
    selectedAnswers: [],
    chartRanges: {
        quebra: [0, 100],
        volume: [0, 100]
    },
    locationRanges: {
        primary: [0, 100],
        secondary: [0, 100]
    },
    contractsRanges: {
        quebra: [0, 100],
        volumeN: [0, 100],
        volumeB: [0, 100],
    },
    locations: {
        origin_locations_filters: [],
        destination_locations_filters: [],
    },
    filterRecord: {
        stageId: null
    },
    filtersObjects: {
        stringFilter: null,
        locations: {
            area: [0, 0],
            isActive: null,
            productive: null,
            status: [],
            ids: [],
            classificationTypes: []
        },
        storages: {
            area: [0, 0],
            primaryLocationIds: [],
            ids: [],
            classificationTypes: []
        },
        process_ids: [],
        isActiveOnMobile: null,
        products_ids: [],
        seasons_ids: [],
        lots: [],
        person_in_charge_ids: [],
        storage_device_types_ids: [],
        storage_devices_ids: [],
        sdi_origin_ids: [],
        sdi_destination_ids: [],
        start_record_date_time: null,
        sent_date_time: null,
        range: [0,100],
        reportType: null,
        document: {
            document_date: [],
            expiration_date: [],
            isActive: null,
            isExpirated: null,
            isPublic: null
        },
        qr: {
            isActive: null
        },
        record: {
            status: []
        },
        sellers_ids: [],
        contracts_names: [],
        volumeB_range: [],
        volumeN_range: [],
        quebra_range: [],
        contracts_dates: []
    },
    chartData: {
        volumes: [],
        quebras: []
    },
    contractsData: {
        quebras: [],
        volumesN: [],
        volumesB: [],
    },
    date: {
        record_date_time: '',
        sent_date_time: '',
        filtersDate: [],
        filtersRecordDate: [],
        filtersRegisterDate: [],
        filtersRecordRegisterDate: [],
        filtersInspectionsDate: [],
        filtersDocumentDate: {
            document_date: [],
            expiration_date: []
        },
        filtersContractsDate: [],
        document_date: '',
        expiration_date: '',
        contracts_dates: '',
        dateFormat: 'DD/MM/YYYY HH:mm',
    },
    structureFilter: [],
    structuresByIds: [],
    data: {},
    othersFilter: [],
    optionsTable: {
        page: null,
        size: null,
        sortBy: null,
        direction: null
    },
    filterHeaderContract: [
        {
            text: 'No. ' + i18n.t('reports.titles.contract'),
            locale: 'No. ' + i18n.t('reports.titles.contract'),
            val: 'contracts_names',
            type: 'multi',
        },
        {
            text: i18n.t('reports.titles.seller'),
            locale: i18n.t('reports.titles.seller'),
            val: 'sellers_ids',
            type: 'multi',
        },
        {
            text: i18n.t('reports.titles.volumeChart'),
            locale: i18n.t('reports.titles.volumeChart'),
            val: 'volumeN_range',
            type: 'multi',
        },
        {
            text: i18n.t('reports.titles.volumeBenefit'),
            locale: i18n.t('reports.titles.volumeBenefit'),
            val: 'volumeB_range',
            type: 'multi',
        },
        {
            text: i18n.t('reports.titles.bankruptcyChart'),
            locale: i18n.t('reports.titles.bankruptcyChart'),
            val: 'quebra_range',
            type: 'multi',
        },
        {
            text: i18n.t('reports.titles.date'),
            locale: i18n.t('reports.titles.date'),
            val: 'contracts_dates',
            type: 'date'
        },
        {
            text: i18n.t('reports.titles.date'),
            locale: i18n.t('reports.titles.date'),
            val: 'sellers_dates',
            type: 'date'
        },
    ],
    filterHeaderDocuments: [
        {
            text: i18n.t('documents.fields.expired'),
            locale: i18n.t('documents.fields.expired'),
            val: 'isExpirated',
            type: 'multi'
        },
        {
            text: i18n.t('documents.fields.public'),
            locale: i18n.t('documents.fields.public'),
            val: 'isPublic',
            type: 'multi'
        },
        {
            text: i18n.t('documents.fields.authority'),
            locale: i18n.t('documents.fields.authority'),
            val: 'document_authority_ids',
            type: 'multi'
        },
        {
            text: i18n.t('documents.fields.type'),
            locale: i18n.t('documents.fields.type'),
            val: 'document_type_ids',
            type: 'multi'
        },
        {
            text: i18n.t('documents.fields.description'),
            locale: i18n.t('documents.fields.description'),
            val: 'document_description_ids',
            type: 'multi'
        },
        {
            text: i18n.t('documents.fields.document_date'),
            locale: i18n.t('documents.fields.document_date'),
            val: 'document_date',
            type: 'date'
        },
        {
            text: i18n.t('documents.fields.expiration_date'),
            locale: i18n.t('documents.fields.expiration_date'),
            val: 'expiration_date',
            type: 'date',
        },
        {
            text: i18n.t('documents.fields.location'),
            locale: i18n.t('documents.fields.location'),
            val: 'storage_devices_ids',
            type: 'multi'
        },
        {
            text: i18n.t('documents.fields.season'),
            locale: i18n.t('documents.fields.season'),
            val: 'seasons_ids',
            type: 'multi'
        },
        {
            text: i18n.t('general.fields.active'),
            locale: i18n.t('general.fields.active'),
            val: 'isActive',
            type: 'multi'
        },
        {
            text: i18n.t('documents.fields.visibility'),
            locale: i18n.t('documents.fields.visibility'),
            val: 'withExternalVisibility',
            type: 'multi'
        },
        {
            text: i18n.t('documents.fields.file'),
            value: 'action',
            width: 150,
            sortable: false,
            type: 'actions'
        },
    ],
    filterHeaderInspections: [
        {
            text: i18n.t('survey.fields.name'),
            locale: i18n.t('survey.fields.name'),
            val: 'template_names',
            type: "text"
        },
        {
            text: i18n.t('survey.fields.score'),
            locale: i18n.t('survey.fields.score'),
            val: 'score',
            type: "text"
        },
        {
            text: i18n.t('records.fields.origin'),
            locale: i18n.t('records.fields.originReg'),
            val: 'sdi_origin_ids',
            type: 'multi'
        },
        {
            text: i18n.t('records.fields.destination'),
            locale: i18n.t('records.fields.destinationRed'),
            val: 'sdi_destination_ids',
            type: 'multi'
        },
        {
            text: i18n.t('pre-records.fields.stage'),
            locale: i18n.t('pre-records.fields.stage'),
            val: 'storage_device_types_ids',
            type: 'multi'
        },
        {text: i18n.t('records.fields.date'), val: 'start_record_date_time', type: 'date'},
        {text: i18n.t('records.fields.person'), val: 'person_in_charge_ids', type: 'text'},
        {text: i18n.t('inspections.labels.answers'), val: 'answers', value: 'answers', type: 'text'}
    ],
    filterHeaderRecords: [
        {
            text: i18n.t('records.fields.status'),
            locale: i18n.t('records.fields.status'),
            val: 'status',
            type: 'multi'
        },
        {
            text: i18n.t('records.fields.origin'),
            locale: i18n.t('records.fields.originReg'),
            val: 'sdi_origin_ids',
            type: 'multi'
        },
        {
            text: i18n.t('records.fields.destination'),
            locale: i18n.t('records.fields.destinationRed'),
            val: 'sdi_destination_ids',
            type: 'multi'
        },
        {text: i18n.t('records.fields.date'), val: 'start_record_date_time', type: 'date'},
        {text: i18n.t('records.fields.registerDate'), val: 'sent_date_time', type: 'date'},
        {text: i18n.t('records.fields.process'), val: 'process_ids', type: 'text'},
        {text: i18n.t('pre-records.fields.mobile'), val: 'isActiveOnMobile', type: 'text'},
        {text: i18n.t('records.label.titles.product'), val: 'products_ids', type: 'text'},
        {text: i18n.t('records.filters.lot'), val: 'lots', type: 'text'},
        {text: i18n.t('records.fields.season'), val: 'seasons_ids', type: 'text'},
        {text: i18n.t('records.fields.person'), val: 'person_in_charge_ids', type: 'text'}
    ],
    filterHeaderTraceability: [
        {
            text: i18n.t("records.fields.status"),
            locale: i18n.t("records.fields.status"),
            val: "status",
            type: 'multi'
        },
        {
            text: i18n.t('pre-records.fields.stage'),
            locale: i18n.t('pre-records.fields.stage'),
            val: 'storage_device_types_ids',
            type: 'multi'
        },
        {
            text: i18n.t('records.fields.origin'),
            locale: i18n.t('records.fields.originReg'),
            val: 'sdi_origin_ids',
            type: 'multi'
        },
        {
            text: i18n.t('records.fields.destination'),
            locale: i18n.t('records.fields.destinationRed'),
            val: 'sdi_destination_ids',
            type: 'multi'
        },
        {text: i18n.t('records.fields.date'), val: 'start_record_date_time', type: 'date'},
        {text: i18n.t('records.fields.registerDate'), val: 'sent_date_time', type: 'date'},
        {text: i18n.t('records.fields.process'), val: 'process_ids', type: 'text'},
        {text: i18n.t('pre-records.fields.mobile'), val: 'isActiveOnMobile', type: 'text'},
        {text: i18n.t('records.filters.lot'), val: 'lots', type: 'text'},
        {text: i18n.t('records.label.titles.product'), val: 'products_ids', type: 'text'},
        {text: i18n.t('records.fields.season'), val: 'seasons_ids', type: 'text'},
        {text: i18n.t('records.fields.person'), val: 'person_in_charge_ids', type: 'text'}
    ],
    filterHeaderTraceabilityV2: [
        {
            text: i18n.t('pre-records.fields.stage'),
            locale: i18n.t('pre-records.fields.stage'),
            val: 'storage_device_types_ids',
            type: 'multi'
        },
        {
            text: i18n.t('records.fields.origin'),
            locale: i18n.t('records.fields.originReg'),
            val: 'sdi_origin_ids',
            type: 'multi'
        },
        {
            text: i18n.t('records.fields.destination'),
            locale: i18n.t('records.fields.destinationRed'),
            val: 'sdi_destination_ids',
            type: 'multi'
        },
        {text: i18n.t('records.fields.date'), val: 'start_record_date_time', type: 'date'},
        {text: i18n.t('records.fields.registerDate'), val: 'sent_date_time', type: 'date'},
        {text: i18n.t('records.fields.process'), val: 'process_ids', type: 'text'},
        {text: i18n.t('records.filters.lot'), val: 'lots', type: 'text'},
        {text: i18n.t('records.label.titles.product'), val: 'products_ids', type: 'text'},
        {text: i18n.t('records.fields.season'), val: 'seasons_ids', type: 'text'},
        {text: i18n.t('records.fields.person'), val: 'person_in_charge_ids', type: 'text'}
    ],
    filterHeaderPreRecords: [
        {
            text: i18n.t('pre-records.fields.stage'),
            locale: i18n.t('pre-records.fields.stage'),
            val: 'storage_device_types_ids',
            type: 'multi'
        },
        {
            text: i18n.t('pre-records.fields.origin'),
            locale: i18n.t('pre-records.fields.originReg'),
            val: 'sdi_origin_ids',
            type: 'multi'
        },
        {
            text: i18n.t('pre-records.fields.destination'),
            locale: i18n.t('pre-records.fields.destinationRed'),
            val: 'sdi_destination_ids',
            type: 'multi'
        },
        {text: i18n.t('pre-records.fields.process'), val: 'process_ids', type: 'text'},
        {text: i18n.t('pre-records.fields.mobile'), val: 'isActiveOnMobile', type: 'text'}
    ],
    filtersChips: [],
    cantFilters: null,
    months: [
        i18n.t("home.months.jan"),
        i18n.t("home.months.feb"),
        i18n.t("home.months.mar"),
        i18n.t("home.months.apr"),
        i18n.t("home.months.may"),
        i18n.t("home.months.jun"),
        i18n.t("home.months.jul"),
        i18n.t("home.months.aug"),
        i18n.t("home.months.sep"),
        i18n.t("home.months.oct"),
        i18n.t("home.months.nov"),
        i18n.t("home.months.dec")
    ]
}
