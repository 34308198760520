import { default as http } from "./HttpService";

class TrackingService {
  constructor() {}
  getTrackingData(locations) {
    return http.client.post("/v1/tracking", locations);
  }
  recordById(id) {
    return http.client.get("/v1/record/tracking/" + id);
  }
  recordByQrId(label_code) {
    return http.client.get("/v1/qr-code/" + label_code);
  }
  saveLabel(labelData) {
    return http.client.post("/v1/qr-code/", labelData);
  }
  changePrint(labelData) {
    return http.client.put("/v1/traceability_saved/change_print_limit/", labelData);
  }
}

export default new TrackingService();
