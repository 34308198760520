import * as types from './mutations-type.js';

export default {
    [types.SET_EQUIPMENT_LIST](state, data) {
        state.equipments = data.content;
        state.totalFromServer = data.totalElements;
    },
    [types.SET_LOADING_ON](state, data) {
        state.loading = data;
    },
    [types.SET_LOADING_OFF](state, data) {
        state.loading = data;
    },
    [types.EXPORT](state, data) {
        state.exportUrl = data;
    },
}
