import {default as http} from './HttpService'

class StorageTypesService {
    constructor() {
    }

    storageTypesByCompany() {
        return http.client.get(`v1/company/storage-device-types-all`);
    }

    storageTypesActiveByCompany() {
        return http.client.get(`v1/storage-device-type/by_user_access`);
    }

    allStorageTypes() {
        return http.client.get(`v1/storage-device-type/companies`);
    }

    changePosition(companySDTs) {
        let fd = new FormData();
        fd.append('sdt_positions_json', JSON.stringify(companySDTs));
        return http.client.post(`v1/company/sdt-change-position`, fd, {headers: {'Content-Type': 'multipart/form-data'}});
    }

    save(item, editOrSave) {
        if (editOrSave > -1) {
            return http.client.put(`v1/company/storage-device-types`, item);
        } else {
            return http.client.post(`v1/storage-device-type`, item);
        }
    }

    saveAdmin(item, editOrSave) {
        if (editOrSave > -1) {
            return http.client.put(`v1/storage-device-type`, item);
        } else {
            return http.client.post(`v1/storage-device-type`, item);
        }
    }

    delete(id) {
        return http.client.delete('/v1/storage-device-type/' + id);
    }
}

export default new StorageTypesService()
