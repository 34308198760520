import i18n from '@/plugins/i18n';
import * as types from './mutations-type.js';
import DocumentDescriptionService from "../../../../services/DocumentDescriptionService";

export default {
    async fetchListDocumentDescriptions({commit, state}, toast) {
        state.documentDescriptions = [];
        try {
            const {data} = await DocumentDescriptionService.documentDescriptions();
            commit(types.SET_DOCUMENT_DESCRIPTION_LIST, data)
        } catch (error) {
            if (error.message !== 'cancelRequest')
                toast.error(i18n.t('documentDescription.notification.listError'), {
                    queueable: true
                });
        }
    },
    async fetchListDocumentDescriptionsByType({commit, state}, [company, type, toast]) {
        state.documentDescriptions = [];
        try {
            const {data} = await DocumentDescriptionService.documentDescriptionsByDocumentType(company, type);
            commit(types.SET_DOCUMENT_DESCRIPTION_LIST, data)
        } catch (error) {
            if (error.message !== 'cancelRequest')
                toast.error(i18n.t('documentDescription.notification.listError'), {
                    queueable: true
                });
        }
    },
    async saveDocumentDescriptions({commit, state}, [item, editOrSave, toast]) {
        try {
            await DocumentDescriptionService.save(item, editOrSave);
        } catch (error) {
            if (error.message !== 'cancelRequest')
                toast.error(i18n.t('documentDescription.notification.saveError'), {
                    queueable: true
                });
        }
    },
}
