import OrderListService from "@/services/OrderListService";
import i18n from '@/plugins/i18n'
import _ from "lodash";
import LanguajeService from "@/services/LanguajeService"

export default {
    locationsList: state => OrderListService.orderListByUppercase(state.locationsList, 'name'),
    sLocations: state => OrderListService.orderListByUppercase(state.sLocations, 'name'),
    locationsListForFilters: state => OrderListService.orderListByUppercase(state.locationsListForFilters, 'name'),
    GET_LOCATIONS_FOR_FILTERS: state => OrderListService.orderListByUppercase(state.locationsForFilters, 'name'),
    totalFromServer: state => state.totalFromServer,
    versions: state => state.versions,
    totalFromServerVersions: state => state.totalFromServerVersions,
    totalFromServerForFilters: state => state.totalFromServerForFilters,
    personInCharge: state => state.personInCharge,
    locationByUserAccess: state => state.locationByUserAccess,
    activeLocationsList: state => state.activeLocationsList,
    mapConfig: state => state.mapConfig,
    GET_CLASSIFICATIONS: state => _.map(state.classifications, (item) => {
        return {
            ...item,
            name: LanguajeService.getKey3(item.languageKey)
        }
    }),
    GET_BADGE_CLASSIFICATIONS: state => _.map(state.badgeClassifications, (item) => {
        return {
            ...item,
            name: LanguajeService.getKey3(item.languageKey)
        }
    }),

    // ------------ EXTRA COLUMNS ----------------
    GET_COLUMNS: state => state.columns,
    GET_SELECTED_COLUMNS: state => state.selectedColumns,
    GET_HEADERS: state => (columns) => {
        return [
            {
                text: "", value: "statusLine",
                sortable: false
            },
            {
                text: i18n.t("records.fields.status"),
                val: "status",
                value: "status",
                sortable: true
            },
            {
                text: i18n.t("locations.fields.name"),
                value: "name",
                sortable: true
            },
            {
                text: i18n.t("locations.fields.storage_type"),
                value: "storageDeviceType",
                val: "storage_device_types_ids",
                sortable: false},
            {
                text: i18n.t("locations.fields.gln"),
                value: "gln"
            },
            {
                text: i18n.t("locations.fields.active"),
                value: "active",
                val: "isActive",
                align: "center",
                width: 120,
            },
            {
                text: i18n.t('locations.fields.identificationType'),
                value: 'document_key',
                val: "document_key",
                width: 100,
                sortable: false,
                align: !!_.find(columns, {key: 'DOCUMENT_KEY'}) ? '' : 'none'
            },
            {
                text: i18n.t('locations.fields.identificationValue'),
                value: 'document_value',
                val: "document_value",
                width: 100,
                sortable: false,
                align: !!_.find(columns, {key: 'DOCUMENT_VALUE'}) ? '' : 'none'
            },
            {
                text: i18n.t('storage.fields.area'),
                value: 'area',
                val: "area",
                width: 100,
                sortable: false,
                align: !!_.find(columns, {key: 'AREA'}) ? '' : 'none'
            },
            {
                text: i18n.t('storage.fields.longitude'),
                value: 'longitude',
                sortable: false,
                align: !!_.find(columns, {key: 'LONGITUDE'}) ? '' : 'none'
            },
            {
                text: i18n.t('storage.fields.latitude'),
                value: 'latitude',
                sortable: false,
                align: !!_.find(columns, {key: 'LATITUDE'}) ? '' : 'none'
            },
            {
                text: i18n.t('storage.fields.measurement_unit'),
                value: 'measurementUnit',
                width: 150,
                sortable: false,
                align: !!_.find(columns, {key: 'MEASUREMENT_UNIT'}) ? '' : 'none'
            },
            {
                text: i18n.t("locations.fields.telephone"),
                value: "telephone",
                align: !!_.find(columns, {key: 'TELEPHONE'}) ? '' : 'none'
            },
            {
                text: i18n.t("locations.fields.email"),
                value: "email",
                align: !!_.find(columns, {key: 'EMAIL'}) ? '' : 'none'
            },
            {
                text: i18n.t('locations.fields.address1'),
                value: 'address1',
                sortable: false,
                align: !!_.find(columns, {key: 'ADDRESS1'}) ? '' : 'none'
            },
            {
                text: i18n.t('locations.fields.address2'),
                value: 'address2',
                sortable: false,
                align: !!_.find(columns, {key: 'ADDRESS2'}) ? '' : 'none'
            },
            {
                text: i18n.t('locations.fields.city'),
                value: 'city',
                sortable: false,
                align: !!_.find(columns, {key: 'CITY'}) ? '' : 'none'
            },
            {
                text: i18n.t('locations.fields.state'),
                value: 'state',
                sortable: false,
                align: !!_.find(columns, {key: 'STATE'}) ? '' : 'none'
            },
            {
                text: i18n.t('locations.fields.country'),
                value: 'country',
                sortable: false,
                align: !!_.find(columns, {key: 'COUNTRY'}) ? '' : 'none'
            },
            {
                text: i18n.t('locations.fields.zipCode'),
                value: 'zipCode',
                sortable: false,
                align: !!_.find(columns, {key: 'ZIP_CODE'}) ? '' : 'none'
            },
            {
                text: i18n.t('locations.fields.description'),
                value: 'description',
                sortable: false,
                align: !!_.find(columns, {key: 'DESCRIPTION'}) ? '' : 'none'
            },
            {
                text: i18n.t('locations.fields.classificationType'),
                value: 'classificationType',
                sortable: false,
                align: !!_.find(columns, {key: 'CLASSIFICATION_TYPE'}) ? '' : 'none'
            },
            {
                text: i18n.t('locations.fields.commercialSite'),
                value: 'comercialSite',
                sortable: false,
                align: !!_.find(columns, {key: 'COMMERCIAL_SITE'}) ? '' : 'none'
            },
            {
                text: i18n.t('locations.fields.color'),
                value: 'color',
                sortable: false,
                align: !!_.find(columns, {key: 'COLOR'}) ? '' : 'none'
            },
            {
                text: i18n.t('general.fields.action'),
                value: 'action',
                width: 160,
                sortable: false,
                align: 'center'
            },
        ]
    },

    GET_EXTRA_COLUMNS: state => ([
        {key: 'AREA', name: i18n.t('storage.fields.area')},
        {key: 'MEASUREMENT_UNIT', name: i18n.t('storage.fields.measurement_unit')},
        {key: 'DOCUMENT_KEY', name: i18n.t('locations.fields.identificationType')},
        {key: 'DOCUMENT_VALUE', name: i18n.t('locations.fields.identificationValue')},
        {key: 'EMAIL', name: i18n.t('locations.fields.email')},
        {key: 'TELEPHONE', name: i18n.t('locations.fields.telephone')},
        {key: 'ADDRESS1', name: i18n.t('locations.fields.address1')},
        {key: 'ADDRESS2', name: i18n.t('locations.fields.address2')},
        {key: 'CITY', name: i18n.t('locations.fields.city')},
        {key: 'STATE', name: i18n.t('locations.fields.state')},
        {key: 'COUNTRY', name: i18n.t('locations.fields.country')},
        {key: 'ZIP_CODE', name: i18n.t('locations.fields.zipCode')},
        {key: 'COLOR', name: i18n.t('locations.fields.color')},
        {key: 'COMMERCIAL_SITE', name: i18n.t('locations.fields.commercialSite')},
        {key: 'DESCRIPTION', name: i18n.t('locations.fields.description')},
        {key: 'LONGITUDE', name: i18n.t('storage.fields.longitude')},
        {key: 'LATITUDE', name: i18n.t('storage.fields.latitude')},
        {key: 'CLASSIFICATION_TYPE', name: i18n.t('locations.fields.classification')}
    ])
}
