import i18n from '@/plugins/i18n';
import * as types from './mutations-type.js';
import StorageService from "../../../../services/StorageService";
import AuthorityService from "@/services/AuthorityService";
import DocumentTypeService from "@/services/DocumentTypeService";
import Locations from "@/services/Locations"

export default {
    loading({commit}, payload) {
        commit(types.SET_LOADING, payload);
    },
    async fetchListStorage({dispatch, commit, state}, [profile, toast]) {
        dispatch('loading', true);
        state.storagesByCompanyAndActive = [];
        try {
            const company = profile.company_id;
            const {data} = await StorageService.storages(company);
            commit(types.SET_STORAGE_BY_COMPANY_ACTIVE_LIST, data);

            dispatch('loading', false);
        } catch (error) {
            if (error.message !== 'cancelRequest')
                toast.error(i18n.t('storage.notification.listError'), {
                    queueable: true
                });

            dispatch('loading', false);
        }
    },

    async fetchAllStoragesByIsActive({dispatch, commit, state}, [payload, toast]) {
        state.authoritiesLocations = [];
        try {
            const {data} = await AuthorityService.authoritiesStorageDevices(payload);
            commit(types.SET_STORAGES_FOR_FILTERS, data);
        } catch (error) {
            if (error.message !== 'cancelRequest')
                toast.error(i18n.t('authority.notification.listError'), {
                    queueable: true
                });
        }
    },
    async fetchFilteredStorages({dispatch, commit, state}, [filters, toast]) {
        state.storagesByCompanyAndActive = [];
        try {
            const { data } = await StorageService.storagesFiltered(filters, 'fetchFilteredStorages');
            commit(types.SET_STORAGE_LIST, data);
        } catch (error) {
            commit(types.SET_STORAGE_LIST, []);
            if (error.code !== 'ERR_CANCELED')
                toast.error(i18n.t('storage.notification.listError'), {
                    queueable: true
                });
        }
    },
    async fetchSStorageDevices({dispatch, commit, state}, [filters, toast]) {
        try {
            const { data } = await StorageService.storagesFiltered({...filters, light: true, paginated: false}, 'fetchSStorageDevices');
            commit(types.SET_S_STORAGE_DEVICES, data);
        } catch (error) {
            commit(types.SET_S_STORAGE_DEVICES, []);
            if (error.code !== 'ERR_CANCELED')
                toast.error(i18n.t('storage.notification.listError'), {
                    queueable: true
                });
        }
    },
    async fetchFilteredStoragesForFilters({dispatch, commit, state}, [filters, toast]) {
        state.storagesByCompanyAndActive = [];
        try {
            const { data } = await StorageService.storagesFiltered(filters, 'fetchFilteredStoragesForFilters');
            commit(types.SET_STORAGE_LIST_FOR_FILTERS, data);
        } catch (error) {
            if (error.code !== 'ERR_CANCELED')
                toast.error(i18n.t('storage.notification.listError'), {
                    queueable: true
                });
        }
    },
    async fetchFilteredStoragesForTable({dispatch, commit, state}, [filters, toast]) {
        try {
            const { data } = await StorageService.storagesFiltered(filters, 'fetchFilteredStoragesForTable');
            commit(types.SET_STORAGE_TABLE, data);
        } catch (error) {
            if (error.code !== 'ERR_CANCELED')
                toast.error(i18n.t('storage.notification.listError'), {
                    queueable: true
                });
        }
    },
    async fetchListVersions({dispatch, commit, state}, [filters, toast]) {
        try {
            const {data} = await StorageService.storageVersions(filters);
            commit(types.SET_VERSIONS_LIST, data);
        } catch (error) {
            if (error.message !== 'cancelRequest')
                toast.error(i18n.t('documents.notification.listError'), {
                    queueable: true
                });
        }
    },
    async fetchStorageById({dispatch}, [id, toast]) {
        dispatch('loading', true);
        try {
            const {data} = await StorageService.getById(id);
            return data;
        } catch (error) {
            if (error.message !== 'cancelRequest')
                toast.error(i18n.t('storage.notification.listError'), {
                    queueable: true
                });
        } finally {
            dispatch('loading', false);
        }
    },
    async fetchListAllStorageByCompany({dispatch, commit, state}, [profile, toast]) {
        dispatch('loading', true);
        state.allStoragesByCompany = [];
        try {
            const company = profile.company_id;
            const {data} = await StorageService.allStoragesByCompany(company);
            commit(types.SET_ALL_STORAGE_BY_COMPANY_LIST, data);
            dispatch('loading', false);
        } catch (error) {
            if (error.message !== 'cancelRequest')
                toast.error(i18n.t('storage.notification.listError'), {
                    queueable: true
                });

            dispatch('loading', false);
        }
    },
    async fetchListAllStorage({dispatch, commit, state}, [toast]) {
        dispatch('loading', true);
        state.allStorages = [];
        try {
            const {data} = await StorageService.allStorages();
            commit(types.SET_ALL_STORAGE_LIST, data);
            dispatch('loading', false);
        } catch (error) {
            if (error.message !== 'cancelRequest')
                toast.error(i18n.t('storage.notification.listError'), {
                    queueable: true
                });

            dispatch('loading', false);
        }
    },

    async exportCSV({dispatch, commit,state}, [language, toast]) {
        state.locationsList = [];
        dispatch('loading', true);
        try {
            const { data } = await StorageService.exportCSV(language);
            const FileSaver = require('file-saver');
            const blob = new Blob([data], {
                type: 'application/xlsx'
            });
            FileSaver.saveAs(blob, 'storages_data.xlsx');

            toast.success(i18n.t('storage.notification.export'), {
                color: 'secondary',
                queueable: true
            });

            dispatch('loading', false);
        } catch (error) {
            if (error.message !== 'cancelRequest')
                toast.error(i18n.t('locations.notification.exportError'), {
                    queueable: true
                });

            dispatch('loading', false);
        }
    },

    async downloadHypertReport({dispatch, commit,state}, [payload, toast]) {
        state.locationsList = [];
        dispatch('loading', true);
        try {
            const { data } = await StorageService.downloadHyperTReport(payload);
            const FileSaver = require('file-saver');
            const blob = new Blob([data], {
                type: 'application/pdf'
            });
            FileSaver.saveAs(blob, 'hypert-report.pdf');

            toast.success(i18n.t('storage.notification.export'), {
                color: 'secondary',
                queueable: true
            });

            dispatch('loading', false);
        } catch (error) {
            if (error.message !== 'cancelRequest')
                toast.error(i18n.t('storage.notification.exportError'), {
                    queueable: true
                });

            dispatch('loading', false);
        }
    },

    async changeActiveValue({dispatch, commit, state}, [id_store, status_value, toast]) {
        dispatch('loading', true);
        try {
            await StorageService.changeActiveValue(id_store, status_value);
        } catch (error) {
            if (error.message !== 'cancelRequest') {
                if (error.response.status === 406) {
                    toast.warning(i18n.t('storage.notification.inUse'), {
                        color: 'gray',
                        queueable: true
                        //icon: 'mdi-alert-circle-outline'
                    });
                } else {
                    toast.error(i18n.t('storage.notification.saveError'), {
                        queueable: true
                    });
                }

                dispatch('loading', false);
            }
        }
    },

    async fetchAccessStoragesByUser({dispatch, commit,state}, [filters, toast]) {
        return new Promise((resolve, reject) => {
            StorageService.storagesFiltered(filters, 'fetchAccessStoragesByUser')
              .then(res => {
                  commit(types.SET_STORAGES_BY_USER_ACCESS, res.data);
                  resolve(res.data)
              }).catch((error) => {
                if (error.code !== 'ERR_CANCELED')
                    toast.error(i18n.t('storage.notification.listError'), {
                        queueable: true
                    });

                commit(types.RESTORE_STORAGES_BY_USER_ACCESS);
                reject(err)
            })
        })
    },

    async fetchClassificationTypes({commit}) {
        await StorageService.getClassificationTypes()
          .then(res => {
              console.log(res)
              commit(types.SET_CLASSIFICATIONS, res.data);
          })
    },

    async fetchBadgeClassifications({commit}) {
        await StorageService.getBadgeClassifications()
          .then(res => {
              console.log(res)
              commit(types.SET_BADGE_CLASSIFICATIONS, res.data);
          })
    },

    async saveStorage({dispatch, commit, state}, [item, editOrSave, document_logo, logo_file, document_json, document_file, badges_file, geobufData, toast]) {
        try {
            const data = await StorageService.save(item, editOrSave, document_logo, logo_file, document_json, document_file, badges_file, geobufData)
            toast.success(i18n.t('storage.notification.save'), {
                queueable: true,
                color: 'secondary'
            });

            return !!data;
        } catch (error) {
            switch (error.response.data) {
                case "LOCATION_IN_USE":
                    toast.error(i18n.t('storage.notification.inUse'), {
                        queueable: true,
                        color: 'gray'
                    });
                    break;
                case "LOCATION_NAME_ALREADY_EXISTS":
                case "LOCATION_GLN_ALREADY_EXISTS":
                    toast.error(i18n.t(`notifications.locations.406.${error.response.data}`), {
                        queueable: true,
                        color: 'gray'
                    })
                    break;
                default:
                    toast.error(i18n.t('locations.notification.saveError'), {
                        queueable: true
                    });
                    break;
            }
        }
    },

    // ----------------- BRAIN AG -------------------------------

    async fetchBrainAgHistory({dispatch, commit, state, getters}, [_id, toast]) {
        commit('authority/SET_LOADING_ON', true, {root: true})

        return new Promise((resolve, reject) => {
            StorageService.getBrainAgHistory(_id)
              .then(res => {
                  commit(types.SET_BRAINAG_HISTORY, res.data);
                  resolve(res.data)
              }).catch((err) => {
                if (err.message !== 'cancelRequest')
                    toast.error(i18n.t('storage.notification.listError'), {
                        queueable: true
                    });

                commit(types.RESTORE_BRAINAG_HISTORY, getters.queryHistory);
                reject(err)
            }).finally(() => {
                commit('authority/SET_LOADING_OFF', false, {root: true})
            })
        })
    },

    async fetchLatestQueries({dispatch, commit,state}, [_id, toast]) {
        commit('authority/SET_LOADING_ON', true, {root: true})

        return new Promise((resolve, reject) => {
            StorageService.getLatestQueries(_id)
              .then(res => {
                  commit(types.SET_BRAINAG_LATEST_QUERIES, res.data);
                  resolve(res.data)
              }).catch((err) => {
                if (err.message !== 'cancelRequest')
                    toast.error(i18n.t('storage.notification.listError'), {
                        queueable: true
                    });

                commit(types.RESTORE_BRAINAG_LATEST_QUERIES, data);
                reject(err)
            }).finally(() => {
                commit('authority/SET_LOADING_OFF', false, {root: true})
            })
        })
    },

    async sendBrainAgQuery({dispatch, commit, getters}, [_data, toast]) {
        return new Promise((resolve, reject) => {
            StorageService.sendBrainAgQuery(_data)
              .then(res => {
                  commit(types.SET_BRAINAG_HISTORY, [...res.data, ...getters.queryHistory]);
                  resolve(res.data)
              }).catch((err) => {
                if (err.message !== 'cancelRequest')
                    toast.error(i18n.t('storage.notification.listError'), {
                        queueable: true
                    });

                commit(types.RESTORE_BRAINAG_HISTORY, getters.queryHistory);
                reject(err)
            })
        })
    },

    // ----------------- BR CARBON -------------------------------

    async fetchBrcarbonData({}, [id, toast]) {
        return new Promise((resolve, reject) => {
            StorageService.getBrcarbonData(id)
                .then(res => {
                    resolve(res)
                })
                .catch((e) => {
                    reject(e)
                    if (error.message !== 'cancelRequest') {
                        toast.error(i18n.t('storage.notification.listError'), {
                        queueable: true
                        });
                    }
                })
        })
    },

    async authorizeStorageOrNot({ dispatch }, [payload, toast]) {
        try {
            await StorageService.authorizeOrNot(payload);

            dispatch('fetchFilteredStoragesForTable', [{
                pageableDTO: {
                    page:0,
                    size:10,
                    sortBy: "id",
                    direction: "desc"
                }
            }, toast]);

            toast.success(i18n.t('reports.notification.activeOrDeactiveSuccess'), {
                color: '#03AC8C',
                queueable: true
            });
        } catch (error) {
            if (error.message !== 'cancelRequest') {
                toast.error(i18n.t('reports.notification.activeOrDeactiveError'), {
                    queueable: true
                });
            }
        }
    },

    async fetchTemplate({ commit, state }, [payload, toast]) {
        try {
            const { data } = await StorageService.fetchTemplate(payload)

            const FileSaver = require('file-saver')
            const blob = new Blob([data], {
                type: 'application/xlsx',
            })
            FileSaver.saveAs(blob, `location-template.xlsx`)

            toast.success(i18n.t('general.notification.downloadTemplateSuccess'), {
                queueable: true,
                color: '#37A98C',
            })
        } catch (error) {
            if (error.message !== 'cancelRequest')
                toast.error(i18n.t('general.notification.downloadTemplateError'), {
                    queueable: true,
                })
        }
    },

    importStorages({ commit, state }, [options, toast]) {
        return new Promise((resolve, reject) => {
            StorageService.import(options)
                .then((res) => {
                    switch (res.data.importState) {
                        case "ERRORED":
                            toast.error(i18n.t('records.notification.importError'), {
                                queueable: true,
                            })
                            reject(res.data)
                            break
                        case "SUCCESSFUL":
                            toast.success(i18n.t('records.notification.importSuccess'), {
                                queueable: true,
                                color: '#37A98C',
                            })
                            resolve(res.data)
                            break
                        case "PARTIALLY":
                            toast.warning(i18n.t('records.notification.importSuccess'), {
                                queueable: true,
                                color: '#777',
                            })
                            resolve(res.data)
                            break
                    }
                })
                .catch((err) => {
                    reject(err)

                    if (err.message !== 'cancelRequest')
                        toast.error(i18n.t('records.notification.importError'), {
                            queueable: true,
                        })
                })
        })
    },

    async fetchExtraColumns({ getters, commit }) {
        try {
            const { data } = await StorageService.getExtraColumns();

            const extraColumnsSet = new Set(data.extraColumns);

            const filteredExtraColumns = getters['GET_EXTRA_COLUMNS'].filter(({ key }) => extraColumnsSet.has(key));

            const keyToNameMap = filteredExtraColumns.reduce((acc, {key, name}) => {
                acc[key] = name;
                return acc;
            }, {});
            const selectedColumns = data.extraColumns.map(key => {
                return { key, name: keyToNameMap[key] || key };
            });

            commit(types.SET_SELECTED_COLUMNS, selectedColumns);
        } catch (error) {
            console.log(error);
        }
    },

    async setExtraColumns({ dispatch }, payload) {
        try {
            await StorageService.setExtraColumns(payload);
            await dispatch('fetchExtraColumns')
        } catch (error) {
            console.log(error)
        }
    },

    async fetchDocumentTypes({commit,state}, [payload, toast]) {
        state.documentTypes = [];
        try {
            const { data } = await DocumentTypeService.getDocumentTypesFiltered(payload);

            commit(types.SET_DOCUMENT_TYPES, data)
        } catch (error) {
            if (error.message !== 'cancelRequest')
                toast.error(i18n.t('documentType.notification.listError'), {
                    queueable: true
                });
        }
    },

    async verifyCar({commit,state}, payload) {
        try {
            const { data } = await StorageService.verifyCar(payload);
            return data.valid
        } catch (error) {
            console.log(error)
            throw new Error(error);
        }
    },
}
