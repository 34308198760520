export default {
    IS_EDITING_MODE: (state) => state.isEditingMode,
    GET_WIDGET_DRAWER: (state) => state.drawer,
    DASHBOARDS: state => state.dashboards,
    CURRENT_LAYOUT: state => state.currentLayout,
    WIDGETS: state => state.widgets,
    LAYOUTS: state => state.layouts,
    LAYOUT: state => state.layout,
    GENERAL_FORMAT: state => state.generalFormat,
    GET_FILTERS: state => state.filters,
    GET_USER_PERMISSIONS: state => state.userPermissions,
};
