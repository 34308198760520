import router from '@/router'
import * as types from './mutations-type.js';
import AuthService from '@/services/AuthService';
import _ from "lodash";

export default {
    [types.SET_LOADING_ON](state, data) {
        state.loading = data;
    },
    [types.SET_LOADING_OFF](state, data) {
        state.loading = data;
    },
    [types.LOGIN](state, data) {
        localStorage.setItem('token', data[0].token);
        localStorage.setItem('refresh_token', data[0].refresh_token);
        state.token = data[0].token;
        state.refresh_token = data[0].refresh_token;
    },
    [types.SET_ITEM_SELECTED](state, data) {
        state.credentials = data.data;
    },
    [types.REFRESH](state, data) {
        const decode = AuthService.parseJwt(data.token);
        localStorage.setItem('token', data.token);
        localStorage.setItem('refresh_token', data.refresh_token);
        // state.roles = decode.roles;
        state.token = data.token;
        state.refresh_token = data.refresh_token;
        state.exp = decode.exp;
        state.email = decode.name;
        state.refresh = false;
    },
    [types.LOAD_INIT_DATA](state) {
        if (state.token !== '') {
            const profile = JSON.parse(localStorage.getItem('profile'));
            this.commit('integration/SET_INTEGRATIONS', profile.integrationsCompany, {root: true})

            this.dispatch('general/setLayout', 'Default');
        } else {
            this.dispatch('general/setLayout', 'Login');
            if (!router.history.pending)
                router.push({name: 'login'});
        }
    }
}
