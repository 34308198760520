import _ from "lodash";
import LanguajeService from "../../../services/LanguajeService";
import sortList from "../../../services/OrderListService";

export default {
    loading: state => state.loading,
    source: state => state.source,
    request: state => state.request,
    preRecord: state => {
        state.preRecord.prerecords_name = state.preRecord.languageKey?LanguajeService.getKey3(state.preRecord.languageKey): state.preRecord.prerecords_name;
        return state.preRecord
    },

    storageDeviceTypesActiveByCompany: state => sortList.orderListByUppercase(_.map(state.storageDeviceTypesActiveByCompany, (item) => {
        return {
            ...item,
            storage_type_name: item.storage_device_type_key ? LanguajeService.getKey3(item.storage_device_type_key) : item.storage_device_type_key
        };
    }), 'disposition'),
    recordsByCompany: state => {
        return _.map(state.recordsByCompany, (item) => {
            return {
                ...item,
                prerecords_name: LanguajeService.getKey3(item.languageKey),
                process_name: LanguajeService.getKey3(item.process.language_key),
                sdi_origin_name: item.sdi_origin && item.sdi_origin.control_label  ? item.sdi_origin.primaryLocation.name + ' | ' + item.sdi_origin.control_label: '-',
                sdi_destination_name: item.sdi_destination && item.sdi_destination.control_label ? item.sdi_destination.primaryLocation.name + ' | ' + item.sdi_destination.control_label: '-',
                person_in_charge_name: item.person_in_charge && item.person_in_charge.name ? item.person_in_charge.name : '-',
                sdt_to_show_translate: LanguajeService.getKey3(item.process.sdt_to_show.language_key),
            };
        });
    },
    recordsCompanyById: state => {
        return {
                prerecords_name: LanguajeService.getKey3(state.preRecord.languageKey),
                process_name: LanguajeService.getKey3(state.preRecord.process.language_key),
                sdi_origin_name: state.preRecord.sdi_origin && state.preRecord.sdi_origin.control_label  ? state.preRecord.sdi_origin.primaryLocation.name + ' | ' + state.preRecord.sdi_origin.control_label: '-',
                sdi_destination_name: state.preRecord.sdi_destination && state.preRecord.sdi_destination.control_label ? state.preRecord.sdi_destination.primaryLocation.name + ' | ' + state.preRecord.sdi_destination.control_label: '-',
                person_in_charge_name: state.preRecord.person_in_charge && state.preRecord.person_in_charge.name ? state.preRecord.person_in_charge.name : '-',
                sdt_to_show_translate: LanguajeService.getKey3(state.preRecord.process.sdt_to_show.language_key)
        };
    },
    recordsByCompanyTotalCount: state => state.recordsByCompanyTotalCount,
    recordsToExports: state => _.map(state.recordsToExports, (item) => {
        return {
            ...item,
            prerecords_name: LanguajeService.getKey3(item.languageKey),
            process_name: LanguajeService.getKey3(item.process.language_key),
            sdi_origin_name: item.sdi_origin && item.sdi_origin.control_label  ? item.sdi_origin.primaryLocation.name + ' | ' + item.sdi_origin.control_label: '-',
            sdi_destination_name: item.sdi_destination && item.sdi_destination.control_label ? item.sdi_destination.primaryLocation.name + ' | ' + item.sdi_destination.control_label: '-',
            person_in_charge_name: item.person_in_charge && item.person_in_charge.name ? item.person_in_charge.name : '-',
            sdt_to_show_translate: LanguajeService.getKey3(item.process.sdt_to_show.language_key),
            recordStructureValueDTOListNames: item.recordStructureValues && item.recordStructureValues.length > 0 ? _.map(item.recordStructureValues, (recordStructure) => {
                return {
                    ...recordStructure,
                    structure_name_translate: LanguajeService.getKey3(recordStructure.structure.language_key),
                    groupStructure_name: recordStructure.groupStructures ? ((d) => {d['group_details_name'] = LanguajeService.getKey3(d.languageKey); return [d];}) (recordStructure.groupStructures) : [],
                }
            }) : item.recordStructureValues
        };
    }),
    recordsToTraceabilityReport: state => _.map(state.recordsToTraceabilityReport, (item) => {
        return {
            ...item,
            prerecords_name: LanguajeService.getKey3(item.languageKey),
            process_name: LanguajeService.getKey3(item.process.language_key) ,
            sdi_origin_name: item.sdi_origin && item.sdi_origin.control_label ? item.sdi_origin.control_label : '-',
            sdi_destination_name: item.sdi_destination && item.sdi_destination.control_label ? item.sdi_destination.control_label : '-',
            person_in_charge_name: item.person_in_charge && item.person_in_charge.name ? item.person_in_charge.name : '-',
            sdt_to_show_translate: LanguajeService.getKey3(item.process.sdt_to_show.language_key),
            recordStructureValueDTOListNames: item.recordStructureValues && item.recordStructureValues.length > 0 ? _.map(item.recordStructureValues, (recordStructure) => {
                return {
                    ...recordStructure,
                    structure_name_translate: recordStructure ? LanguajeService.getKey3(recordStructure.structure.language_key) : recordStructure.structure.language_key,
                    groupStructure_name: recordStructure.groupStructures ? ((d) => {d['group_details_name'] = LanguajeService.getKey3(d.languageKey); return [d];}) (recordStructure.groupStructures) : [],
                }
            }) : item.recordStructureValues
        };
    }),

    exportUrl: state => state.exportUrl,
    structures: state => _.map(_.filter(state.structures, (item) => {
        if (!item.language_key.includes('_id')) {
            const name = LanguajeService.getKey3(item.language_key);
            return item.structure_name = name ? name.charAt(0).toUpperCase() + name.slice(1).toLowerCase() : name;
        }
    }), (item) => {
        return {
            ...item,
            filters: []
        };
    }),
    prerecordsByCompanyFiltered: state => _.sortBy(_.map(state.prerecordsByCompanyFiltered, (item) => {
        return {
            ...item,
            prerecords_name: LanguajeService.getKey3(item.languageKey)
        };
    }), ['prerecords_name'])
}
