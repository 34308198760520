import { VBtn } from 'vuetify/lib/components/VBtn';
import { VExpansionPanel } from 'vuetify/lib/components/VExpansionPanel';
import { VExpansionPanelContent } from 'vuetify/lib/components/VExpansionPanel';
import { VExpansionPanelHeader } from 'vuetify/lib/components/VExpansionPanel';
import { VExpansionPanels } from 'vuetify/lib/components/VExpansionPanel';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSnackbar } from 'vuetify/lib/components/VSnackbar';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VSnackbar,{staticClass:"mr-2",attrs:{"timeout":-1,"value":_vm.notification,"bottom":"","color":_vm.data.color,"absolute":"","multi-line":""}},[_c('div',[_c('div',{staticClass:"d-flex justify-start align-center"},[_c(VIcon,{attrs:{"color":_vm.data.color + ' lighten-5',"size":"30px"}},[_vm._v(" "+_vm._s(_vm.data.icon)+" ")]),_c('div',{staticClass:"d-flex flex-column justify-space-between pl-4 pt-3"},[_c('strong',[_vm._v(_vm._s(_vm.data.message))]),_c('div',{staticClass:"d-flex justify-space-between align-center"},[_c('p',{staticClass:"pr-2"},[_vm._v(_vm._s(_vm.$t('notifications.nomenclator.success'))+": "),_c('strong',[_vm._v(_vm._s(_vm.data.import))])]),_c('p',{staticClass:"pr-2"},[_vm._v(_vm._s(_vm.$t('notifications.nomenclator.errors'))+": "),_c('strong',[_vm._v(_vm._s(_vm.data.error))])]),_c('p',[_vm._v(_vm._s(_vm.$t('notifications.nomenclator.total'))+": "),_c('strong',[_vm._v(_vm._s(_vm.data.total))])])])]),_c(VBtn,{staticClass:"ml-2",attrs:{"text":""},on:{"click":function($event){_vm.notification = !_vm.notification}}},[_c(VIcon,{attrs:{"dense":"","size":"20","color":_vm.data.color + ' lighten-5'}},[_vm._v(" mdi-close ")])],1)],1),((_vm.data.rowsFailed && Object.keys(_vm.data.rowsFailed).length !== 0))?_c(VExpansionPanels,{attrs:{"flat":"","light":""}},[_c(VExpansionPanel,[_c(VExpansionPanelHeader,[_vm._v(" "+_vm._s(_vm.$t('general.titles.details'))+" ")]),_vm._l((_vm.data.rowsFailed),function(value,key){return _c(VExpansionPanelContent,{key:key,attrs:{"eager":""}},[_c('p',[_vm._v(_vm._s(_vm.$t('notifications.nomenclator.row'))+": "),_c('strong',[_vm._v("#"+_vm._s(key))]),_vm._v(" - "+_vm._s(_vm.$t(`notifications.nomenclator.errorType.${value}`)))])])})],2)],1):_vm._e()],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }