export default {
    surveyList: [],
    surveyTemplateList: [],
    allTemplates: [],
    templatesForFilters: [],
    surveyEdit: [],
    sectionEdit: [],
    deletedQuestionsNotifications: [],
    loading: true,
    surveyStateEnum: [],
    error: false
}
