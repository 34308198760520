import NotificationService from '@/services/NotificationService'
import * as types from './mutations-type'
import _ from 'lodash'
import i18n from '@/plugins/i18n'

export default {
  async fetchNotificationsMenuByUser({dispatch, commit, state, getters}, [toast]) {
    return new Promise((resolve, reject) => {
      NotificationService.getNotificationsMenuByUser()
        .then(res => {
          commit(types.SET_NOTIFICATIONS_MENU, res.data)
          resolve(res.data)
        }).catch((err) => {
        if (err.message !== 'cancelRequest')
          toast.error('Error al cargar las notificaciones', {
            queueable: true
          });

        commit(types.RESTORE_NOTIFICATIONS, getters.notifications)
        reject(err)
      })
    })
  },

  async fetchNotificationsByUser({dispatch, commit, state, getters}, [filters, toast]) {
    return new Promise((resolve, reject) => {
      NotificationService.getNotificationsByUser(filters)
        .then(res => {
          commit(types.SET_NOTIFICATIONS, res.data)
          resolve(res.data)
        }).catch((err) => {
        if (err.message !== 'cancelRequest')
          toast.error('Error al cargar las notificaciones', {
            queueable: true
          });

        commit(types.RESTORE_NOTIFICATIONS, getters.notifications)
        reject(err)
      })
    })
  },

  async setReadStatus({dispatch, commit, state, getters}, [_ids, toast, view = null]) {
    let notifications = _.cloneDeep(getters.notifications)

    return new Promise((resolve, reject) => {
      NotificationService.setReadNotifications(_ids)
        .then(async (res) => {

          if(res.data === "success") {
            if (view !== 'menu')
              await dispatch('fetchNotificationsMenuByUser', [toast])
            else {
              const notifications = _.map(state.notificationsMenu, (n) => {
                return {
                  ...n,
                  watched: !n.watched
                }
              })

              commit(types.SET_NOTIFICATIONS_MENU, { notifications: notifications, total: state.totalFromServerMenu })
              dispatch('fetchNotificationsByUser', [{
                modules: null,
                priorities: null,
                watched: null,
                pageableDTO: {
                  page: 0,
                  size: 10,
                  sortBy: "dateTime",
                  direction: "DESC",
                },
              }, toast])
            }
          }
          resolve(res.data)
        }).catch((err) => {
        if (err.message !== 'cancelRequest')
          toast.error('Error al cargar las notificaciones', {
            queueable: true
          });

        commit(types.RESTORE_NOTIFICATIONS, notifications)
        reject(err)
      })
    })
  },
}
