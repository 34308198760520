import * as types from './mutations-type.js';

export default {
    [types.SET_SEASON_LIST](state, data) {
        state.seasons = data.content;
        state.totalFromServer = data.totalElements;
    },
    [types.SET_LOADING](state, data) {
        state.loading = data;
    }
}
