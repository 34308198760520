import * as types from './mutations-type.js';


export default {
    [types.SET_DOCUMENTS_LIST](state, data) {
        state.documents = data;
    },
    [types.SET_FILTERS_DOCUMENTS_LIST](state, data) {
        state.filterDocuments = data.content;
        state.filterDocumentsTotalCount = data.totalElements;
    },
    [types.SET_DOCUMENT](state, data) {
        state.document = data;
    },
    [types.SET_VERSIONS_LIST](state, data) {
        state.versions = data.documents;
    },
    [types.SET_STORAGE_IN_DOCUMENT_LIST](state, data) {
        state.storages = data;
    },
    [types.SET_LOADING_ON](state, data) {
        state.loading = data;
    },
    [types.SET_LOADING_OFF](state, data) {
        state.loading = data;
    },
    [types.SET_EVIDENCES](state, data) {
        state.evidences.push(data);
    },
    [types.REMOVE_EVIDENCE](state, data) {
        state.evidences.splice(data, 1);
    },
    [types.CLEAR_EVIDENCES](state) {
        state.evidences = [];
    },

    [types.SET_ORIGINAL_STATE_DOCUMENT](state) {
        state.document = {
            document_authority_id: '',
            document_type_id: '',
            document_description_id: '',
            document_date: null,
            expiration_date: null,
            storageDeviceType: null,
            primaryLocation: null,
            storage_device_ids: null,
            externalVisibility: true,
            observations: '',
            active: true,
            season: null,
            seasonId: null,
            useSeason: false
        }
    },

    [types.SET_ORIGINAL_STATE_VERSIONS](state) {
        state.versions = []
    },
}
