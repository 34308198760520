import i18n from '@/plugins/i18n';
import * as types from "../../survey/store/mutations-type";
import SurveyService from "../../../../services/SurveyService";

export default {
    loadingON({commit}) {
        commit(types.SET_LOADING_ON, true);
    },
    loadingOFF({commit}) {
        commit(types.SET_LOADING_OFF, false);
    },
    errorON({commit}) {
        commit(types.SET_ERROR_ON, true);
    },
    errorOFF({commit}) {
        commit(types.SET_ERROR_OFF, false);
    },
    async fetchListSurveyTemplate({dispatch, commit, state}, [filter, toast]) {
        dispatch('loadingON');
        state.surveyTemplateList = [];
        try {
            const {data} = await SurveyService.surveyTemplateLight(filter);
            commit(types.SET_SURVEY_TEMPLATE_BY_COMPANY_LIST, data);
            dispatch('loadingOFF');
        } catch (error) {
            if (error.message !== 'cancelRequest')
                toast.error(i18n.t('survey.notification.listError'), {
                    queueable: true
                });
        }
    },
    async blockSurveyTemplate({dispatch, commit, state}, [surveyId, toast]) {
        dispatch('loadingON');
        try {
            await SurveyService.blockTemplate(surveyId);
            dispatch('loadingOFF');
        } catch (error) {
            if (error.message !== 'cancelRequest')
                toast.error(i18n.t('survey.notification.listError'), {
                    queueable: true
                });
        }
    },
    async fetchAllTemplates({dispatch, commit, state}, [filter, toast]) {
        dispatch('loadingON');
        state.allTemplates = [];
        try {
            const {data} = await SurveyService.surveyTemplateLight(filter);
            commit(types.SET_ALL_TEMPLATES_TO_EXPORT, data);
            dispatch('loadingOFF');
        } catch (error) {
            if (error.message !== 'cancelRequest')
                toast.error(i18n.t('survey.notification.listError'), {
                    queueable: true
                });
        }
    },
    async fetchTemplatesForFilters({dispatch, commit, state}, [ids, toast, persist = true]) {
        try {
            const { data } = await SurveyService.surveyTemplatesForFilters({ surveyIds: ids });
            if (persist) {
                state.templatesForFilters = []
                commit(types.SET_TEMPLATES_FOR_FILTERS, data)
            } else {
                return data
            }
        } catch (error) {
            if (error.message !== 'cancelRequest')
                toast.error(i18n.t('survey.notification.listError'), {
                    queueable: true
                });
        }
    },
    async fetchSurveyById({dispatch, commit, state}, [id, toast]) {
        dispatch('loadingON');
        try {
            const {data} = await SurveyService.surveyById(id);
            commit(types.SET_SURVEY_BY_ID, data);
            dispatch('loadingOFF');
        } catch (error) {
            if (error.message !== 'cancelRequest')
                toast.error(i18n.t('survey.notification.listError'), {
                    queueable: true
                });
        }
    },
    async fetchSectionById({dispatch, commit, state}, [id, toast]) {
        dispatch('loadingON');
        try {
            const {data} = await SurveyService.setionById(id);
            commit(types.SET_SECTION_BY_ID, data);
            dispatch('loadingOFF');
        } catch (error) {
            if (error.message !== 'cancelRequest')
                toast.error(i18n.t('survey.notification.listError'), {
                    queueable: true
                });
        }
    },
    async saveSurvey({commit, state, dispatch}, [item, editOrSave, files, document, toast, notify = true]) {
        return new Promise(async (resolve, reject) => {
            try {
                const { data } = await SurveyService.save(item, editOrSave, files, document);
                commit(types.SET_SURVEY_BY_ID, data);
                if (notify)
                    toast.success(i18n.t('survey.notification.save'), {
                        queueable: true,
                        color: '#37A98C'
                    });

                resolve(data)
                dispatch('errorOFF');
            } catch (error) {
                if (error.response.data.message === 'SURVEY_ALREADY_EXISTS') {
                    toast.error(i18n.t('survey.notification.alreadyExist'), {
                        queueable: true
                    });
                } else {
                    if (error.message !== 'cancelRequest')
                        toast.error(i18n.t('survey.notification.saveError'), {
                            queueable: true
                        });
                }

                reject(error)
                dispatch('errorON');
            }
        })
    },
    async saveSection({commit, state, dispatch}, [item, editOrSave, toast, notify = true]) {
        return new Promise(async (resolve, reject) => {
            try {
                const { data } =await SurveyService.saveSection(item, editOrSave);
                if (notify)
                    toast.success(i18n.t('survey.notification.save'), {
                        queueable: true,
                        color: '#37A98C'
                    });

                resolve(data)
                dispatch('errorOFF');
            } catch (error) {
                if (error.message !== 'cancelRequest')
                    toast.error(i18n.t('survey.notification.saveError'), {
                        queueable: true
                    });

                reject(false)
                dispatch('errorON');
            }
        })
    },
    async saveQuestion({commit, state, dispatch}, [item, editOrSave, toast, notify = true]) {
        return new Promise(async (resolve, reject) => {
            try {
                const { data } = await SurveyService.saveQuestion(item, editOrSave);
                if (notify)
                    toast.success(i18n.t('survey.notification.save'), {
                        queueable: true,
                        color: '#37A98C'
                    });

                resolve(data)
                dispatch('errorOFF');
            } catch (error) {
                if (error.message !== 'cancelRequest')
                    toast.error(i18n.t('survey.notification.saveError'), {
                        queueable: true
                    });

                reject(false)
                dispatch('errorON');
            }
        })
    },
    async deleteSurvey({dispatch}, [id, toast]) {
        dispatch('loadingON');
        try {
            await SurveyService.delete(id);
            toast.success(i18n.t('survey.notification.deleted'), {
                queueable: true,
                color: '#37A98C'
            });
        } catch (error) {
            if (error.message.includes('406')) {
                toast.error(i18n.t('survey.notification.inUse'), {
                    queueable: true,
                    color: 'gray'
                });
            } else {
                if (error.message !== 'cancelRequest')
                    toast.error(i18n.t('survey.notification.deletedError'), {
                        queueable: true
                    });
            }
        } finally {
            dispatch('loadingOFF');
        }
    },
    async deleteQuestion({commit, state}, [id, toast]) {
        try {
            const {data} = await SurveyService.questionDelete(id);
            commit(types.SET_QUESTION_DELETE, data);
            if(!data.extraQuestions){
                toast.success(i18n.t('survey.notification.deleted'), {
                    queueable: true,
                    color: '#37A98C'
                });
            }
        } catch (error) {
            if (error.message.includes('406')) {
                toast.error(i18n.t('survey.notification.inUse'), {
                    queueable: true,
                    color: 'gray'
                });
            } else {
                if (error.message !== 'cancelRequest')
                    toast.error(i18n.t('survey.notification.deletedError'), {
                        queueable: true
                    });
            }
        }
    },
    async deleteQuestionWithDependents({commit, state}, [id, toast]) {
        try {
            await SurveyService.questionDeleteWithDependents(id);
            toast.success(i18n.t('survey.notification.deleted'), {
                queueable: true,
                color: '#37A98C'
            });
        } catch (error) {
            if (error.message.includes('406')) {
                toast.error(i18n.t('survey.notification.inUse'), {
                    queueable: true,
                    color: 'gray'
                });
            } else {
                if (error.message !== 'cancelRequest')
                    toast.error(i18n.t('survey.notification.deletedError'), {
                        queueable: true
                    });
            }
        }
    },
    async deleteQuestionSection({commit, state}, [id, toast]) {
        try {
            await SurveyService.questionSectionDelete(id);
            toast.success(i18n.t('survey.notification.deleted'), {
                queueable: true,
                color: '#37A98C'
            });
        } catch (error) {
            if (error.message.includes('406')) {
                toast.error(i18n.t('survey.notification.inUse'), {
                    queueable: true,
                    color: 'gray'
                });
            } else {
                if (error.message !== 'cancelRequest')
                    toast.error(i18n.t('survey.notification.deletedError'), {
                        queueable: true
                    });
            }
        }
    },
    async change_state({commit, state, dispatch}, [item, toast, action]) {
        try {
            await SurveyService.change_state(item);

            if (action === 'delete') {
                toast.success(i18n.t('survey.notification.deleted'), {
                    queueable: true,
                    color: '#37A98C'
                });
            } else {
                toast.success(i18n.t('survey.notification.save'), {
                    queueable: true,
                    color: '#37A98C'
                });
            }
        } catch (error) {
            if (error.message !== "cancelRequest") {
                if (action === 'delete') {
                    toast.error(i18n.t("survey.notification.deletedError"), {
                        queueable: true,
                    })
                } else {
                    toast.error(i18n.t("survey.notification.saveError"), {
                        queueable: true,
                    })
                }
            }
        }
    },
}
