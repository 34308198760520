import * as types from './mutations-type.js';

export default {
    [types.SET_GROUP_STRUCTURE](state, data) {
        state.groupDetails = data;
    },
    [types.SET_GROUP_STRUCTURE_BY_COMPANY](state, data) {
        state.groupDetailsByCompany = data;
    },
    [types.SET_GROUP_STRUCTURE_DAFULTS](state, data) {
        state.groupDetailsDefaults = data;
    },
    [types.SET_LOADING_ON](state, data) {
        state.loading = data;
    },
    [types.SET_LOADING_OFF](state, data) {
        state.loading = data;
    },

}
