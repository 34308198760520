import * as types from './mutations-type.js';

export default {
    [types.SET_PROCCESS_LIST](state, data) {
        state.proccess = data;
    },
    [types.SET_LOADING_ON](state, data) {
        state.loading = data;
    },
    [types.SET_LOADING_OFF](state, data) {
        state.loading = data;
    },
    [types.SET_LOADING](state, data) {
        state.loading = data;
    },
    [types.SET_PROCESS](state, data) {
        state.procces = data;
    },
    [types.SET_GROUP_SELECTED](state, data) {
        state.groupStructuresSelected = data;
    },
    [types.SET_STRUCTURES_SELECTED](state, data) {
        state.structuresSelected = data;
    },
    [types.SET_ORIGINAL_STATE_PROCESS](state) {
        state.procces = {
            company: null,
            language_key: "",
            sdt_to_show: null,
            processType: null,
            isInternal: true,
            processStructureOrganized: null
        };
        state.structuresSelected = [];
        state.groupStructuresSelected = [];
    },
}
