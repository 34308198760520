import * as types from './mutations-type'

export default {
    [types.SET_EDITING_MODE](state, value) {
        state.isEditingMode = value
    },
    [types.SET_WIDGET_DRAWER](state, value) {
        state.drawer = value
    },
    [types.SET_DASHBOARDS](state, dashboards) {
        state.dashboards = dashboards;
    },
    [types.SET_CURRENT_LAYOUT](state, layout) {
        state.currentLayout = layout;
    },
    [types.SET_WIDGETS](state, widgets) {
        state.widgets = widgets;
    },
    [types.SET_LAYOUTS](state, layouts) {
        state.layouts = layouts;
    },
    [types.SET_LAYOUT](state, layout) {
        state.layout = layout;
    },
    [types.SET_GENERAL_FORMAT](state, format) {
        state.generalFormat = format;
    },
    [types.SET_FILTERS](state, payload) {
        state.filters = { ...state.filters, ...payload };
    },
    [types.SET_USER_PERMISSIONS](state, payload) {
        state.userPermissions = payload;
    },
    [types.CLEAR_FILTERS](state) {
        state.filters = { 
            applyGlobally: false,
            dates: [],
            recordDates: [],
            processes: [],
            primaryLocations: [],
            secondaryLocations: [],
            isGlobal: false,
            stages: [],
            locationType: null,
            classificationTypes: []
        };
    },
}
