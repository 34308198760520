import {default as http} from './HttpService'
import BaseService from "@/services/BaseService"

class AuthorityService extends BaseService {
    constructor() {
        super()
    }

    authorities() {
        return http.client.get(`v1/company/person-in-charge`);
    }

    authorityById(id) {
        return http.client.get(`v1/person-in-charge/${id}`);
    }

    authoritiesProcess(authority) {
        return http.client.get(`v1/person-in-charge/${authority}/web/processes`);
    }

    authoritiesLocations(payload) {
        return http.client.post('v1/person-in-charge/get_locations_user_list', {
            "companyId": payload.companyId,
            "userId": payload.userId,
            "isActive": payload.isActive
        });
    }

    authoritiesStorageDevices(payload) {
        return http.client.post('v1/person-in-charge/get_storage_devices_user_list', {
            "companyId": payload.companyId,
            "userId": payload.userId,
            "isActive": payload.isActive
        });
    }

    authorityConfigCreate(config) {
        return http.client.post('v1/person-in-charge-configuration/create', config);
    }

    authorityConfigLoad() {
        return this.requestWithCancellation('get', 'v1/person-in-charge-configuration/configurations', null, 'authorityConfigLoad');
    }

    // authoritiesLocations(profile, status) {
    //     return http.client.post('v1/person-in-charge/get_locations_user', {
    //         companyId: profile.company_id,
    //         userId: profile.id,
    //         status: status
    //     });
    // }
    //
    // authoritiesStorageDevices(profile, status, productive) {
    //     return http.client.post('v1/person-in-charge/get_storages_devices_user', {
    //         companyId: profile.company_id,
    //         userId: profile.id,
    //         status: status,
    //         productive: productive
    //     });
    // }

    save(item, editOrSave) {
        if (editOrSave > -1) {
            return http.client.put('/v1/person-in-charge/', item);
        } else {
            return http.client.post('/v1/person-in-charge/', item);
        }
    }

    pushProcess(item) {
        return http.client.post('/v1/person-in-charge/push-process', item);
    }

    pushLocations(item) {
        return http.client.post('/v1/person-in-charge/push_locations_storage_devices', item);
    }

    delete(id) {
        return http.client.delete('/v1/person-in-charge/' + id);
    }
}

export default new AuthorityService()
