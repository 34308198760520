export default {
    labels: [],
    loading: true,
    recordTrackindData: {
        company: null,
        process: null,
        person_in_charge: null,
        originApp: null,
        sdi_origin: null,
        sdi_destination: null,
        record_date_time: new Date().toJSON(),
        recordStructureValuesOrganized: {},
        evidences: {},
    },
}
