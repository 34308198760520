import * as types from './mutations-type.js';

export default {
    [types.SET_OPERATOR_LIST](state, data) {
        state.operators = data.content;
        state.totalFromServer = data.totalElements;
    },
    [types.SET_LOADING_ON](state, data) {
        state.loading = data;
    },
    [types.SET_LOADING_OFF](state, data) {
        state.loading = data;
    }
}
