import Vue from 'vue';
import Vuetify, {VSnackbar, VBtn, VIcon} from 'vuetify/lib';
import VuetifyToast from 'vuetify-toast-snackbar-ng';
import VueQrcode from '@xkeshi/vue-qrcode';
import JsonExcel from 'vue-json-excel';
import SVGInjectorVue from 'svginjector-vue';
import { Plugin } from "vue-fragment";
import DatetimePicker from '../components/vuetify-datetime-picker';
import HypertIcon from '@/components/icons/HypertIcon.vue';

Vue.use(DatetimePicker);

Vue.use(Vuetify, {
    components: {
        VSnackbar,
        VBtn,
        VIcon
    }
});

Vue.use(VuetifyToast,
    {
        x: 'right', // default
        y: 'top', // default
        classes: [
            'body-2'
        ]
    });

Vue.component(VueQrcode.name, VueQrcode);
Vue.component('downloadExcel', JsonExcel);
Vue.use(SVGInjectorVue);
Vue.use(Plugin);

export default new Vuetify({
    icons: {
        iconfont: 'mdi',
        values: {
            hypert: { // name of our custom icon
                component: HypertIcon, // our custom component
            },
        },
    },
    theme: {
        options: {
            customProperties: true,
        },
        themes: {
            light: {
                primary: '#2497d3',
                secondary: '#03AC8C',
                accent: '#82B1FF',
                error: '#e83b36',
                info: '#2196F3',
                success: '#4CAF50',
                warning: '#E8BD30',
                neuter: '#232323',
                white: '#FFFFFF',
                gray: '#6c757d',
                gray2: '#cacaca',
                metal: '#ebebeb',
            }
        },
    },
});
