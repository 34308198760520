import _ from "lodash";
import LanguajeService from "../../../../services/LanguajeService";
import i18n from '@/plugins/i18n'
import sortList from "@/services/OrderListService";
import OrderListService from "@/services/OrderListService";


export default {
    versions: state => _.map(state.versions, (item) => {
        return {
            ...item,
            measurement_unit_name_translate: LanguajeService.getKey3(item.measurementUnit),
            storageDeviceType: {
                ...item.storageDeviceType,
                storage_type_name: item.storageDeviceType ? LanguajeService.getKey3(item.storageDeviceType) : '-'
            }
        };
    }),
    totalFromServerVersions: state => state.totalFromServerVersions,
    storagesByCompanyAndActive: state => _.map(state.storagesByCompanyAndActive, (item) => {
        return {
            ...item,
            measurement_unit_name_translate: LanguajeService.getKey3(item.measurementUnit),
            storageDeviceType: {
                ...item.storageDeviceType,
                storage_type_name: item.storageDeviceType ? LanguajeService.getKey3(item.storageDeviceType) : '-'
            }
        };
    }),
    allStoragesByCompany: state => _.map(state.allStoragesByCompany, (item) => {
        return {
            ...item,
            measurement_unit_name_translate: LanguajeService.getKey3(item.measurementUnit),
            storageDeviceType: {
                ...item.storageDeviceType,
                storage_type_name: item.storageDeviceType ? LanguajeService.getKey3(item.storageDeviceType) : '-'
            }
        };
    }),
    storagesList: state => _.map(state.storagesList, (item) => {
        return {
            ...item,
            measurement_unit_name_translate: LanguajeService.getKey3(item.measurementUnit),
            storageDeviceType: item.storageDeviceType ? LanguajeService.getKey3(item.storageDeviceType) : '-'
        };
    }),

    sStorageDevices: state => OrderListService.orderListByUppercase(state.sStorageDevices, 'control_label'),
    storagesListForFilters: state => _.map(state.storagesListForFilters, (item) => {
        return {
            ...item,
            measurement_unit_name_translate: LanguajeService.getKey3(item.measurementUnit),
            storageDeviceType: item.storageDeviceType ? LanguajeService.getKey3(item.storageDeviceType) : '-'
        };
    }),
    storagesTable: state => _.map(state.storagesTable, (item) => {
        return {
            ...item,
            measurement_unit_name_translate: LanguajeService.getKey3(item.measurementUnit),
            storageDeviceType: item.storageDeviceType ? LanguajeService.getKey3(item.storageDeviceType) : '-'
        };
    }),
    GET_STORAGES_FOR_FILTERS: state => _.map(state.storagesForFilters, (item) => {
        return {
            ...item,
            measurement_unit_name_translate: LanguajeService.getKey3(item.measurementUnit),
            storageDeviceType: {
                ...item.storageDeviceType,
                storage_type_name: item.storageDeviceType ? LanguajeService.getKey3(item.storageDeviceType) : '-'
            }
        };
    }),
    totalFromServer: state => state.totalFromServer,
    totalFromServerTable: state => state.totalFromServerTable,
    allStorages: state => _.map(state.allStorages, (item) => {
        return {
            ...item,
            storageDeviceType: {
                ...item.storageDeviceType,
                storage_type_name:  item.storageDeviceType ? LanguajeService.getKey3(item.storageDeviceType) : '-'
            }
        };
    }),
    loading: state => state.loading,
    mapConfig: state => state.mapConfig,
    storagesByUserAccess: state => _.map(state.storagesByUserAccess, (item) => {
        return {
            ...item,
            storage_device_type_name: LanguajeService.getKey3(item.storageDeviceType.language_key)
        }
    }),
    GET_CLASSIFICATIONS: state => _.map(state.classifications, (item) => {
        return {
            ...item,
            name: LanguajeService.getKey3(item.languageKey)
        }
    }),
    GET_BADGE_CLASSIFICATIONS: state => _.map(state.badgeClassifications, (item) => {
        return {
            ...item,
            name: LanguajeService.getKey3(item.languageKey)
        }
    }),


    // ----- BRAIN AG ------------

    // SOIL_USE_CONSOLIDATED - CAR
    // COMPLIANCE - CAR
    // EMBARGO - CPF/CNPJ
    // SLAVERY - CPF/CNPJ

    documentTypes: () => {
        return {
            types: ['CPF', 'CNPJ', 'CAR'],
            datasets: {
                document: [{
                    name: i18n.t('storage.integrations.brainag.embargo'),
                    value: 'EMBARGO'
                },{
                    name: i18n.t('storage.integrations.brainag.slavery'),
                    value: 'SLAVERY'
                }],
                car: [
                    {
                        name: i18n.t('storage.integrations.brainag.soilUse'),
                        value: 'SOIL_USE_CONSOLIDATED'
                    },{
                        name: i18n.t('storage.integrations.brainag.compliance'),
                        value: 'COMPLIANCE'
                    }
                ]
            }
        }
    },

    queryHistory: state => state.queryHistory,
    latestQueries: state => state.latestQueries,

    // ------------ EXTRA COLUMNS ----------------
    GET_COLUMNS: state => state.columns,
    GET_SELECTED_COLUMNS: state => state.selectedColumns,
    GET_HEADERS: state => (columns) => {
        return [
            {
                text: "",
                value: "statusLine",
                sortable: false
            },
            {
                text: i18n.t("records.fields.status"),
                val: "status",
                value: "status",
                sortable: true
            },
            {
                text: i18n.t('storage.fields.name'),
                value: 'control_label',
                width: 250,
                sortable: true,
            },
            {
                text: i18n.t('storage.fields.location'),
                value: 'primaryLocationName',
                width: 250,
                sortable: false,
                isHidden: false
            },
            {
                text: i18n.t('storage.fields.storage_type'),
                value: 'storageDeviceType',
                val: "storage_device_types_ids",
                width: 120,
                sortable: false
            },
            {
                text: i18n.t('storage.fields.gln'),
                val: "gln",
                value: 'gln',
                isHidden: false
            },
            {
                text: i18n.t('storageType.fields.active'),
                value: 'active',
                val: 'isActive',
                width: 100,
                align: 'center'
            },
            {
                text: i18n.t('storage.fields.locationOwner'),
                value: 'locationOwner',
                val: 'locationOwner',
                width: 140,
                align: !!_.find(columns, {key: 'LOCATION_OWNER'}) ? 'center' : 'none'
            },
            {
                text: i18n.t('storage.fields.prd'),
                value: 'productive',
                val: 'isProductive',
                width: 140,
                align: !!_.find(columns, {key: 'PRODUCTIVE'}) ? 'center' : 'none'
            },
            {
                text: i18n.t('storage.fields.area'),
                value: 'area',
                val: "area",
                width: 100,
                sortable: false,
                align: !!_.find(columns, {key: 'AREA'}) ? '' : 'none'
            },
            {
                text: i18n.t('storage.fields.longitude'),
                value: 'longitude',
                sortable: false,
                align: !!_.find(columns, {key: 'LONGITUDE'}) ? '' : 'none'
            },
            {
                text: i18n.t('storage.fields.latitude'),
                value: 'latitude',
                sortable: false,
                align: !!_.find(columns, {key: 'LATITUDE'}) ? '' : 'none'
            },
            {
                text: i18n.t('storage.fields.measurement_unit'),
                value: 'measurementUnit',
                width: 150,
                sortable: false,
                align: !!_.find(columns, {key: 'MEASUREMENT_UNIT'}) ? '' : 'none'
            },
            {
                text: i18n.t('storage.fields.mediumHeight'),
                value: 'mediumHeight',
                width: 150,
                sortable: false,
                align: !!_.find(columns, {key: 'MEDIUM_HEIGHT'}) ? '' : 'none'
            },
            {
                text: i18n.t('storage.fields.mediumTemperature'),
                value: 'mediumTemperature',
                width: 150,
                sortable: false,
                align: !!_.find(columns, {key: 'MEDIUM_TEMPERATURE'}) ? '' : 'none'
            },
            {
                text: i18n.t('locations.fields.address1'),
                value: 'address1',
                sortable: false,
                align: !!_.find(columns, {key: 'ADDRESS1'}) ? '' : 'none'
            },
            {
                text: i18n.t('locations.fields.address2'),
                value: 'address2',
                sortable: false,
                align: !!_.find(columns, {key: 'ADDRESS2'}) ? '' : 'none'
            },
            {
                text: i18n.t('locations.fields.city'),
                value: 'city',
                sortable: false,
                align: !!_.find(columns, {key: 'CITY'}) ? '' : 'none'
            },
            {
                text: i18n.t('locations.fields.state'),
                value: 'state',
                sortable: false,
                align: !!_.find(columns, {key: 'STATE'}) ? '' : 'none'
            },
            {
                text: i18n.t('locations.fields.country'),
                value: 'country',
                sortable: false,
                align: !!_.find(columns, {key: 'COUNTRY'}) ? '' : 'none'
            },
            {
                text: i18n.t('locations.fields.zipCode'),
                value: 'zipCode',
                sortable: false,
                align: !!_.find(columns, {key: 'ZIP_CODE'}) ? '' : 'none'
            },
            {
                text: i18n.t('locations.fields.description'),
                value: 'description',
                sortable: false,
                align: !!_.find(columns, {key: 'DESCRIPTION'}) ? '' : 'none'
            },
            {
                text: i18n.t('storage.fields.classificationType'),
                value: 'classificationType',
                sortable: false,
                align: !!_.find(columns, {key: 'CLASSIFICATION_TYPE'}) ? '' : 'none'
            },
            {
                text: i18n.t('locations.fields.commercialSite'),
                value: 'comercialSite',
                sortable: false,
                align: !!_.find(columns, {key: 'COMMERCIAL_SITE'}) ? '' : 'none'
            },
            {
                text: i18n.t('locations.fields.color'),
                value: 'color',
                sortable: false,
                align: !!_.find(columns, {key: 'COLOR'}) ? '' : 'none'
            },
            {
                text: i18n.t('general.fields.action'),
                value: 'action',
                width: 160,
                sortable: false,
                align: 'center'
            },
        ]
    },

    GET_EXTRA_COLUMNS: state => ([
        {key: 'LOCATION_OWNER', name: i18n.t('storage.fields.locationOwner')},
        {key: 'AREA', name: i18n.t('storage.fields.area')},
        {key: 'PRODUCTIVE', name: i18n.t('storage.fields.prd')},
        {key: 'MEASUREMENT_UNIT', name: i18n.t('storage.fields.measurement_unit')},
        {key: 'ADDRESS1', name: i18n.t('locations.fields.address1')},
        {key: 'ADDRESS2', name: i18n.t('locations.fields.address2')},
        {key: 'CITY', name: i18n.t('locations.fields.city')},
        {key: 'STATE', name: i18n.t('locations.fields.state')},
        {key: 'COUNTRY', name: i18n.t('locations.fields.country')},
        {key: 'ZIP_CODE', name: i18n.t('locations.fields.zipCode')},
        {key: 'DESCRIPTION', name: i18n.t('locations.fields.description')},
        {key: 'LONGITUDE', name: i18n.t('storage.fields.longitude')},
        {key: 'LATITUDE', name: i18n.t('storage.fields.latitude')},
        {key: 'COMMERCIAL_SITE', name: i18n.t('locations.fields.commercialSite')},
        {key: 'MEDIUM_HEIGHT', name: i18n.t('storage.fields.mediumHeight')},
        {key: 'MEDIUM_TEMPERATURE', name: i18n.t('storage.fields.mediumTemperature')},
        {key: 'COLOR', name: i18n.t('locations.fields.color')},
        {key: 'CLASSIFICATION_TYPE', name: i18n.t('locations.fields.classification')}
    ]),

    GET_DOCUMENT_TYPES: state => sortList.orderListByUppercase(_.map(state.documentTypes, (item) => {
        return {
            ...item,
            document_type_name: LanguajeService.getKey3(item.language_key)
        };
    }), 'document_type_name')
}
