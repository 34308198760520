export const SET_DOCUMENTS_LIST = 'SET_DOCUMENTS_LIST';
export const SET_FILTERS_DOCUMENTS_LIST = 'SET_FILTERS_DOCUMENTS_LIST';
export const SET_STORAGE_IN_DOCUMENT_LIST = 'SET_STORAGE_IN_DOCUMENT_LIST';
export const SET_DOCUMENT = 'SET_DOCUMENT';
export const SET_VERSIONS_LIST = 'SET_VERSIONS_LIST';
export const SET_ORIGINAL_STATE_DOCUMENT = 'SET_ORIGINAL_STATE_DOCUMENT';
export const SET_ORIGINAL_STATE_VERSIONS = 'SET_ORIGINAL_STATE_VERSIONS';
export const SET_LOADING_ON = 'SET_LOADING_ON';
export const SET_LOADING_OFF = 'SET_LOADING_OFF';
export const SET_EVIDENCES = 'SET_EVIDENCES';
export const REMOVE_EVIDENCE = 'REMOVE_EVIDENCE';
export const CLEAR_EVIDENCES = 'CLEAR_EVIDENCES';
