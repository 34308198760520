<template>
    <v-card flat>
        <v-card-text class="pa-0 text-left">
            <v-card-title>{{$t(location.title)}}</v-card-title>
            <v-row class="ml-4" v-if="location.name && location.name !== ''">
                <v-col style="color: #232323; font-weight: bold">
                   {{location.name}}
                </v-col>
            </v-row>
            <v-row class="ml-4" v-if="location.address1 && location.address1 !== ''">
                <v-col style="color: #232323; font-weight: bold">
                    {{location.address1}}
                </v-col>
            </v-row>
            <v-row class="ml-4" v-if="location.city && location.city !== ''">
                <v-col style="color: #232323; font-weight: bold">
                    {{location.city}}
                </v-col>
            </v-row>
            <v-row class="ml-4" v-if="location.state && location.state !== ''">
                <v-col style="color: #232323; font-weight: bold">
                    {{location.state}}
                </v-col>
            </v-row>
            <v-row class="ml-4" v-if="location.country && location.country !== ''">
                <v-col style="color: #232323; font-weight: bold">
                    {{location.country}}
                </v-col>
            </v-row>
        </v-card-text>
    </v-card>
</template>

<script>
    export default {
        name: "MapTracking",
        props: ['location'],
        methods:{
        }
    }
</script>

<style scoped>

</style>
