import * as types from "./mutations-type";

export default {
    [types.SET_INSPECTION](state, data) {
        state.inspection = data;
    },
    [types.SET_SCORE](state, data) {
        if(data && (data['minPunctuation'] !== null && data['maxPunctuation'] !== null)) {
            state.score = [data['minPunctuation'],data['maxPunctuation']];
        } else {
            state.score = [0,100];
        }
    },
    [types.SET_QUESTION](state, data) {
        state.question = data;
    },
    [types.SET_UPLOAD_PROGRESS](state, data) {
        state.uplaodProgress = data;
    },
    [types.SET_CANT_TO_EXPORT](state, data) {
        state.cantToExport = data.number_of_elements_to_show;
    },
    [types.SET_SURVEY_BY_COMPANY_LIST](state, data) {
        state.inspectionList = data.content;
        state.recordsByInspectionsTotalCount = data.totalElements;
    },
    [types.SET_LOADING_ON](state, data) {
        state.loading = data;
    },
    [types.SET_LOADING_OFF](state, data) {
        state.loading = data;
    },
    [types.SET_INSPECTION_TO_EXPORT](state, data) {
        state.inspectionToExports = data;
    },
    [types.SET_ERROR_ON](state, data) {
        state.error = data;
    },
    [types.SET_ERROR_OFF](state, data) {
        state.error = data;
    },

    [types.SET_ORIGINAL_STATE_INSPECTION](state) {
        state.inspection = {
            id: null,
            name: "",
            data: new Date().toJSON(),
            company: null,
            isTemplate: true,
            questionSectionList: [],
            locations: null
        }
    },

    [types.SET_BLOB](state, payload) {
        state.blob = payload
    },
}
