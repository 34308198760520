import {default as http} from './HttpService'
import store from '@/store';

class InspectionsService {
    constructor() {
    }

    survey(filter) {
        return http.client.post(`v1/inspections/survey/light`, filter);
    }

    getInspectionsToExport(filters) {
        return http.client.post(`v1/inspections/survey_not_templates_to_export`, filters);
    }

    cantToExport(filters) {
        return http.client.post(`v1/inspections/inspections-export-count`, filters);
    }

    export(filters, type, isAsync) {
        let url = '';

        switch (type) {
            case 'csv':
                url = '/v1/inspections/survey_not_templates_to_export/excel';
                break;
            case 'pdf':
                url = '/v1/inspections/survey_not_templates_to_export/pdf';
                break;
            default:
                throw new Error('Unsupported type');
        }

        if (isAsync) {
            url += '/async';
        }

        return http.client.post(url, filters, {
            responseType: 'blob',
        });
    }

    inspectionById(id) {
        return http.client.get(`v1/inspections/` + id);
    }

    getRangeScore(company) {
        return http.client.post('v1/inspections/punctuation-max-min', {
            company_id: company
        });
    }

    filterSearch(data) {
        return http.client.post(`/v1/inspections/string_filter`, data);
    }

    questionEdit(item, files) {
        let fd = new FormData();

        fd.append('question_json', JSON.stringify(item));
        if (files && files.length > 0) {
            _.forEach(files, (file) => {
                fd.append('documents_file', file);
            });
        }

        return http.client.put(`v1/question/not_template`, fd, {
            headers: {'Content-Type': 'multipart/form-data'}
        });
    }

    save(item, editOrSave, files, document, signature) {
        let fd = new FormData();
        if (files && files.length > 0) {
            _.forEach(files, (file) => {
                fd.append('documents_file', file);
            });
        }
        if (document)
            fd.append('document_json', JSON.stringify(document));

        if(signature)
            fd.append('signature_files', signature);

        if (editOrSave > -1) {
            fd.append('surveyDTOUpdate_json', JSON.stringify(item));
            return http.client.put('/v1/inspections', fd, {
                headers: {'Content-Type': 'multipart/form-data'}
            });
        } else {
            fd.append('surveyDTOCreate_json', JSON.stringify(item));
            return http.client.post(`v1/inspections`, fd, {
                headers: {'Content-Type': 'multipart/form-data'}
            });
        }
    }


    delete(id) {
        return http.client.delete('/v1/inspections/' + id);
    }


    getEvidencas(questionId) {
        return http.client.get(`v1/question/${questionId}/zip`,{
            responseType: 'blob',
            headers: {
                'Accept': 'application/zip'
            }
        });
    }

    getQuestionsByType(types) {
        return http.client.post('/v1/inspections/all_questions', types);
    }

    getAnswersByQuestions(lang, questions) {
        return http.client.post('/v1/inspections/questions/all_responses', {
            language: lang,
            questions: questions
        });
    }

    import(options, file) {
        let fd = new FormData()
        fd.append('data_json', JSON.stringify(options))
        fd.append('file', file)

        return http.client.post(`v1/import/excel`, fd, { headers: { 'Content-Type': 'multipart/form-data' } })
    }

    fetchTemplate(options) {
        return http.client.post(`v1/import/generic-template`, options, {
            responseType: 'blob'
        })
    }

    fetchCertificate(id) {
        return http.client.get(`v1/reports/file/${id}`, {
            responseType: 'blob'
        })
    }

    downloadPdfFromBlob(blob, filename) {
        if (blob) {
            const a = document.createElement('a');
            a.style.display = 'none';
            a.href = blob;
            a.download = filename;

            document.body.appendChild(a);
            a.click();

            window.URL.revokeObjectURL(blob);
            document.body.removeChild(a);
        } else {
            console.error('No blob found or provided to download file');
        }
    }
}

export default new InspectionsService()
