import i18n from '@/plugins/i18n';

export default {
    isLoggedIn: state => !!state.token,
    roles: state => state.roles,
    exp: state => state.exp,
    id: state => state.id,
    refresh: state => state.refresh,
    email: state => state.email,
    requireRules() {
        const rules = [];
        const required = (v) => !!v || i18n.t('auth.rules.required');
        rules.push(required);
        return rules;
    },
    loading: state => state.loading
}
