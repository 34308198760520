<template>
    <loading
            :show="loadInitData"
            :label="label"
    >
    </loading>
</template>


<script>
    import loading from 'vue-full-loading'
    import {mapGetters} from 'vuex';
    import i18n from '@/plugins/i18n';
    export default {
        name: 'splash',
        components: {
            loading
        },
        data() {
            return {
                label: i18n.t('general.titles.loading')
            }
        },
        computed: {
            ...mapGetters({
                loadInitData: 'general/loading',
            }),

        }
    }
</script>

<style lang="scss" scoped>
    .white-overlay {
        background-color: rgba(0, 0, 0, 0.6);
        z-index: 9999;
        top: 0;
        left: 0;
        height: 100vh;
        width: 100vw;
        position: fixed;
        margin: 0;
    }
</style>
