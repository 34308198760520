export const SET_AUTHORITY_LIST = 'SET_AUTHORITY_LIST';
export const SET_AUTHORITY = 'SET_AUTHORITY';
export const RESTORE_AUTHORITY = 'RESTORE_AUTHORITY';
export const SET_AUTHORITY_PROCESS_LIST = 'SET_AUTHORITY_PROCESS_LIST';
export const SET_AUTHORITY_LOCATIONS_LIST = 'SET_AUTHORITY_LOCATIONS_LIST';
export const SET_AUTHORITY_LOCATIONS = 'SET_AUTHORITY_LOCATIONS';
export const SET_AUTHORITY_STORE_DEVICE = 'SET_AUTHORITY_STORE_DEVICE';
export const SET_AUTHORITY_ROLE_LIST = 'SET_AUTHORITY_ROLE_LIST';
export const SET_LOADING_ON = 'SET_LOADING_ON';
export const SET_LOADING_OFF = 'SET_LOADING_OFF';
export const SET_AUTHORITY_PROCESS_LIST_EDIT = 'SET_AUTHORITY_PROCESS_LIST_EDIT';
