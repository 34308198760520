export const SET_EDITING_MODE = 'SET_EDITING_MODE';
export const SET_WIDGET_DRAWER = 'SET_WIDGET_DRAWER';
export const SET_DASHBOARDS = 'SET_DASHBOARDS';
export const SET_CURRENT_LAYOUT = 'SET_CURRENT_LAYOUT';
export const SET_WIDGETS = 'SET_WIDGETS';
export const SET_LAYOUTS = 'SET_LAYOUTS';
export const SET_LAYOUT = 'SET_LAYOUT';
export const SET_GENERAL_FORMAT = 'SET_GENERAL_FORMAT';
export const SET_FILTERS = 'SET_FILTERS';
export const CLEAR_FILTERS = 'CLEAR_FILTERS';
export const SET_USER_PERMISSIONS = 'SET_USER_PERMISSIONS';