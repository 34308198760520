import toFormatCase from './format-case.filter'
import truncateAddress from './truncate-address.filter'
import truncate from './truncate.filter'
import round from './round.filter'
import toStartCase from './start-case.filter'
import decimal from './decimal.filter'

export default {
    install(Vue) {
        Vue.filter('toFormatCase', toFormatCase)
        Vue.filter('truncateAddress', truncateAddress)
        Vue.filter('truncate', truncate)
        Vue.filter('round', round)
        Vue.filter('toStartCase', toStartCase)
        Vue.filter('decimal', decimal)
    }
}