export default {
    documents: [],
    filterDocuments: [],
    filterDocumentsTotalCount: 0,
    document: {
        document_authority_id: '',
        document_type_id: '',
        document_description_id: '',
        document_date: null,
        expiration_date: null,
        storageDeviceTpe: null,
        primaryLocation: null,
        storage_device_ids: null,
        externalVisibility: true,
        observations: '',
        active: true,
        isPublic: false,
        season: null,
        seasonId: null,
        useSeason: false,
        id: null
    },
    versions: [],
    storages: [],
    loading: true,
    evidences: []
}
